import React from "react"
import EditIcon from "@material-ui/icons/Edit";
import "./style.css"
import Tooltip from "@material-ui/core/Tooltip";

export default function EditButton ({classes, handleClick, hasTooltip}){
  return (
    <>
      {hasTooltip ?
        <Tooltip
          title={<h6 style={{ color: "white" }}>wijzig</h6>}
          placement="top"
        >
        <button className={classes} onClick={() => handleClick()}>
          <EditIcon />
        </ button>
        </Tooltip>
      :
        <button className={classes} onClick={() => handleClick()}>
          <EditIcon />
        </ button>
      }
    </>
  )
}
