import {Group,Text,Rect,Line} from 'react-konva';

export default function Scale(props){
  return(
    <Group>
      <Rect
        width={props.width}
        height={props.height}
        fill='white'
        stroke='white'
        x={0}
        y={0}
      />
      <Group
        pos = {{x:0,y: props.height/2}}>
      
        <Line
          points={[props.height/2, props.height/2,
                   props.width-props.height/2, props.height/2]}
          stroke='grey'
        />
        <Ticks
          height={props.height/2}
          scale={props.scale}
          length ={props.width-props.height}
          number ={0}
          yoffset={props.height/2}
          xoffset={props.height/2}
        />
      </Group>
      
    </Group>
  );
}

function Ticks(props){
  var number = props.length/props.scale;
  var step = 1;
  let label;
  var found = false
  while(!found){
  
    if (number<step){
      step/=10;
    }else if (number<2*step){
      label = step;
      step = step/2;
      found = true;
    }else if (number<5*step){
      label = step;
      found = true;
    }else if (number<10*step){
      label = step*5;
      found = true;
    }else{
      step*=10;
    }
  }
  
  
  var list = []
  for(var i=0;i<=number;i+=step){
    list.push(i);
  }
  return list.map(x=>Tick({
    height:(x%label===0)?props.height:props.height/2, 
    scale:props.scale, 
    number:x*step,
    label:(x%label===0),
    yoffset:props.yoffset,
    xoffset:props.xoffset})
    );
}

function Tick(props){
  const x = props.xoffset+props.number*props.scale
  return(
      <Group>
      <Line
        points={[x,
                 props.height/2+props.yoffset,
                 x,
                 -props.height/2+props.yoffset]}
        stroke='black'
      />
      { props.label &&
        <Text
          text={(+props.number.toFixed(2))+'m'}
          fill='black'
          x={x-10} 
          y = {0}
          align="center"
          width = {20}
        />
      }
      </Group>
      );
}