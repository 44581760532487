import React from "react"
import "./style.css"
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from "@material-ui/core/Tooltip";

export default function DeleteButton({classes, disabled, handleDelete, hasTooltip}){
  return (
    <>
      { hasTooltip ?
        <Tooltip
          title={<h6 style={{ color: "white" }}>verwijder</h6>}
          placement="top"
        >
          <button
            onClick={ () => {
              handleDelete()
            }}
            disabled={disabled}
            className={classes}>
              <DeleteIcon />
          </ button>
        </Tooltip>
      :
        <button
          onClick={ () => {
          handleDelete()
        }}
          disabled={disabled}
          className={classes}>
            <DeleteIcon />
        </ button>
      }
    </>
  )
}
