import React, {Component} from 'react';

import { jsPDF } from "jspdf";
import { svgAsPngUri } from "save-svg-as-png";
import ObstacleList from './components/ObstacleList'
import TableList from './components/TableList'
import ReservationList from './components/ReservationList'
import HelpModal from './components/HelpModal'
import RulesModal from './components/RulesModal'
import ObstacleModal from './components/ObstacleModal'
import TableModal from './components/TableModal'
import ReservationModal from './components/ReservationModal'
import UserFormModal from './components/UserFormModal'
import SaveConfigurationAsModal from './components/SaveConfigurationAsModal'
import { StyledButton } from "./components/styledButton"
import KonvaCanvas from './components/KonvaCanvas'
import {Container,
      Collapse,
      Row,
      Col,
      Label,
      Input,
      Form,
      Modal,
      ModalHeader,
      ModalBody,
      ModalFooter,
      InputGroup,
      InputGroupAddon,
      InputGroupText
    } from "reactstrap";

import Select from 'react-select'
import 'svg2pdf.js' // for when we want to switch to svg2pdf at client side instead of at server side.

import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AllOutIcon from '@material-ui/icons/AllOut';
import { Button, ButtonGroup } from '@material-ui/core';
import { factoryResetRoomDict } from './DefaultRoomInstances'
import AlertWrapper from './components/AlertWrapper';
import CollapseButton from './components/CollapseButton';
import Navigation from './components/Navigation'
import DeleteButton from './components/deleteButton/DeleteButton';
import OrientationButton from "./components/OrientationButton"
import { generateUid } from './utils/utils';
import ResultModal from './components/resultModal';
import BaseModal from "./components/BaseModal";

function convertToIntegerOrFloatIfPossible(value) {
    const integerRe = /^[-+]?[0-9]+$/
    if (value.match(integerRe)) {
        value = parseInt(value)
    } else {
        const floatRe = /^[-+]?[0-9]+\.[0-9]+$/
        if (value.match(floatRe)) {
            value = parseFloat(value)
        }
    }
    return value
}

function decompressUrlKeysIfItsInCompressedFormat(urlDict) {
    const debug = false
    const hasKeyCmpr = 'cmpr' in urlDict
    const cmprValue = urlDict['cmpr']
    const isCompressed = hasKeyCmpr && cmprValue === '1'
    if (debug) {
        console.log(`hasKeyCmpr = ${hasKeyCmpr}`)
        console.log(`cmprValue = ${cmprValue}`)
        console.log(`isCompressed = ${isCompressed}`)
    }

    if (!isCompressed) { // then url is not compressed
        return urlDict
    }
    // else:
    const long_to_short = { // copied from the python compressor in
        // function compressUrlKeys in ObjectPositioningAlgo/viewps.py.
        // It's quicker to do that again in this literal fashion than manually,
        // retyping it in the opposite order (key,value) swapped.
        // We will use this translation mapping in the opposite direction here:
        // i.o replacing keys occurrences by their respective values (as we did for compressing in python),
        // value occurrences in the string get replaced by their respective keys here (to expand).
        // to expand it again to native readable format.
        // this is the first key so we do it with '?' prefix as well
        '?Objects_list_name_': '?Oln_',

        '&Objects_list_name_': '&Oln_',
        '&Objects_x_': '&Ox_',
        '&Objects_y_': '&Oy_',
        '&n_Objects_sides_': '&nOs_',
        '&Objects_odd_length_': '&Ool_',
        '&Objects_even_length_': '&Oel_',
        '&number_of_Objectss_': '&noO_',
        '&seat_Objects_side_': '&sOs_',

        '&AllowedZones_list_name_': '&AZln_',
        '&AllowedZones_x_': '&AZx_',
        '&AllowedZones_y_': '&AZy_',
        '&n_AllowedZones_sides_': '&nAZs_',
        '&AllowedZones_odd_length_': '&AZol_',
        '&AllowedZones_even_length_': '&AZel_',
        '&number_of_AllowedZoness_': '&noAZ_',

        '&ForbiddenZones_list_name_': '&FZln_',
        '&ForbiddenZones_x_': '&FZx_',
        '&ForbiddenZones_y_': '&FZy_',
        '&n_ForbiddenZones_sides_': '&nFZs_',
        '&ForbiddenZones_odd_length_': '&FZol_',
        '&ForbiddenZones_even_length_': '&FZel_',
        '&number_of_ForbiddenZoness_': '&noFZ_',

        '&hundred_minus_mip_gap_percent': '&HmMGP',
        '&n_pixels_per_m': '&nPxpm',
        '&patience1_s': '&p1_s',
        '&patience2_s': '&p2_s',

        '&ViewingDir': '&vd',
        '&ViewingTol': '&vt',
    }

    var urlDictDecompressed = {}
    for (var short_key in urlDict) {
        var doneShortToLongSubstitution = false
        for (var long in long_to_short) {
            const short = long_to_short[long].substring(1)
            // drop the leading '&', since it is not in the keys of the dict.
            long = long.substring(1) // some for long now.

            if (short_key.startsWith(short)) {
                const long_key = short_key.replace(short, long)
                urlDictDecompressed[long_key] = urlDict[short_key]
                //delete urlDict[short_key]; // no need to change input
                doneShortToLongSubstitution = true
                break // Note that the break can only occur if we nest the two loops
                // in this way and not the opposite way. :)
                // Indeed every key can have only one substitution,
                // but some substitutions can apply to multiple keys.
            }
        }
        if (!doneShortToLongSubstitution) {
            urlDictDecompressed[short_key] = urlDict[short_key]
        }
    }
    console.assert(Object.keys(urlDictDecompressed).length === Object.keys(urlDict).length)
    console.assert(urlDict['cmpr'] === '1')
    console.assert(urlDictDecompressed['cmpr'] === '1')
    urlDictDecompressed['cmpr'] = '0'
    console.assert(urlDictDecompressed['cmpr'] === '0')
    return urlDictDecompressed
}

function urlToUrlDict(url) {
    const debug = false
    const selfTest = false
    const parts = url.split('?')
    const nParts = parts.length
    if (debug) {
        console.log(`nParts = ${nParts}`)
    }
    const urlParamStr = (nParts === 2) ? parts[1] : ''
    const urlParts = urlParamStr.split('&')

    var urlDict = {}
    for (var part of urlParts) {
        const kv = part.split('=')
        const k = kv[0]
        const v = kv[1]
        urlDict[k] = v
        if (selfTest) {
            const v = urlDict[k]
            const result = typeof v !== 'undefined'
            console.assert(result)
        }
    }
    return urlDict
}

class App extends Component {

	constructor(props) {
        super(props);
        const verbose = 0

        this.allowDirectLoginViaUrlFields = false
        this.allowDirectUnsubscribeViaUrlFields = false
        this.mentionRequired = false
        this.manyTablesWarning = 'Heyhoo'
        this.allowMoreControlForDevs = false // Put on false for final user version
        this.canDownloadPdfWithoutLogin = true
        this.downloadPdf = this.downloadPdf.bind(this)
        this.handleUserForm = this.handleUserForm.bind(this)
        this.handleUserLogout = this.handleUserLogout.bind(this)
        this.handleIsLogginIn = this.handleIsLogginIn.bind(this)
        this.toggleResultModal = this.toggleResultModal.bind(this)
        this.toggleVerifyDeleteRoomModal = this.toggleVerifyDeleteRoomModal.bind(this)
        this.toggleVerifyDeleteProfileModal = this.toggleVerifyDeleteProfileModal.bind(this)
        this.rulesToggle = this.rulesToggle.bind(this)
        this.handleCollapse = this.handleCollapse.bind(this)
        this.handleOrientation = this.handleOrientation.bind(this)
        this.loggedInConditionalRunTablePositioningAlgoPdf = this.loggedInConditionalRunTablePositioningAlgoPdf.bind(this)
        if (true) {
            this.resetInternalState = this.resetInternalState.bind(this)
            this.handleRoomBySelectedNameChange = this.handleRoomBySelectedNameChange.bind(this)
            this.handleRoomSelectChange = this.handleRoomSelectChange.bind(this)
        }
        //this.setInitialRecognisableDummyUserCredentials()
        if (verbose >= 1) {
            console.log('render: url params are: ' + window.location.search)
        }
        var tempUrl = window.location.search
        const doCleanup = true /// Can (possibly) be put to false if true cause of '%5B' and '%5D' is eliminated.
        // But leaving it to true is defensive programming.
        if (doCleanup) {
            tempUrl = this.removeDirtyCharsFromString(tempUrl)
        }
        const url = tempUrl
        if (verbose >= 2) {
            console.log(`url = ${url}`)
        }
        var urlDict = urlToUrlDict(url)
        if (verbose >= 2) {
            console.log(`urlDict = ${JSON.stringify(urlDict, null, 2)}`)
        }
        var params = new URLSearchParams(url.search);
        if (verbose >= 2) {
            console.log(`params = ${params}`)
        }
        const doDecompress = true  // Should be set to the same value as in
        // ObjectPositioningAlgo/views.py on the server side(***)

        const urlDictDecompressed = doDecompress ?
            decompressUrlKeysIfItsInCompressedFormat(urlDict) : urlDict
        if (verbose >= 2) {
            console.log(`urlDictDecompressed = ${JSON.stringify(urlDictDecompressed, null, 2)}`)
        }
        this.factoryResetRoomDict = factoryResetRoomDict
        this.roomDict = this.factoryResetRoomDict
        this.userRoomDict = {}
        var roomDeltaY = 'AllowedZones_even_length_1' in urlDictDecompressed ?
            urlDictDecompressed['AllowedZones_even_length_1'] : ''
        var roomDeltaX = 'AllowedZones_odd_length_1' in urlDictDecompressed ?
            urlDictDecompressed['AllowedZones_odd_length_1'] : ''
        // We consider that if and only we have room dimensions in the URL,
        // we are going to parse the URL. We could have nothing else in there though.
        // But then the tables and obstacles lists will be just empty, which is a valid case.
        // TODO: Maybe we should add the requirement that we should have a room name as well.
        const urlSuffixSpecifiesProblem = ((roomDeltaY !== '') && (roomDeltaX !== ''))
        if (verbose >= 2) {
            console.log(`urlSuffixSpecifiesProblem = ${urlSuffixSpecifiesProblem}`)
        }
        const firstLoadDefaultProblem = true
        if (verbose >= 2) {
            console.log(`firstLoadDefaultProblem = ${firstLoadDefaultProblem}`)
        }
        if (firstLoadDefaultProblem) {
            this.handleRoomBySelectedNameChange(this.getDefaultRoomName(), false) // here the viewingDir
            // buttons are not yet loaded, so cannot yet force the arrow orange. TODO: Fix.
        }

        if (urlSuffixSpecifiesProblem) { // this overrules the above default room
            this.loadUrlSpecifiedProblem(urlDictDecompressed, roomDeltaX, roomDeltaY)
        } else {
            // TODO: protect better/filter/ignore what we forward here to server.
            // For now just forward what we get in here.
            var back_end_full_href = ''
            const front_end_full_href = window.location.href
            const urlParts = front_end_full_href.split('/')
            const nUrlParts = urlParts.length
            if (verbose >= 3) {
                console.log(`front_end_full_href = ${front_end_full_href}`)
                console.log(`urlParts = `)
                console.log(`${urlParts}`)
                console.log(typeof nUrlParts)
                console.log(`nUrlParts = ${nUrlParts}`)
            }
            if (nUrlParts >= 4) {
                const front_end_base_href = urlParts[0] + '/' + urlParts[1] + '/' + urlParts[2] + '/'
                const infix_subdir = urlParts[3]
                console.assert(['reg', 'login', ''].includes(infix_subdir))
                const back_end_base_href = this.getDjangoBackEndBaseUrl() // back_end_full_href contains trailing '/'
                back_end_full_href = front_end_full_href.replace(front_end_base_href, this.getDjangoBackEndBaseUrl())
                if (verbose >= 3) {
                    console.log(`infix_subdir = ${infix_subdir}`)
                    console.log(`front_end_base_href = ${front_end_base_href}`)
                    console.log(`back_end_base_href = ${back_end_base_href}`)
                    console.log(`back_end_full_href = ${back_end_full_href}`)
                }
                const expectedResponseType = infix_subdir // 'reg' // also for login type!
                if (['reg', 'login', 'help', 'rules'].includes(infix_subdir)) {
                    if (infix_subdir === 'login') { // .../login/
                        if (this.allowDirectLoginViaUrlFields &&
                            ('emailAddress' in urlDictDecompressed) && ('code' in urlDictDecompressed)) {
                            this.sendDataToServer(back_end_full_href, expectedResponseType)
                        } else { // popup the login dialogue [without pre filling anything for security reasons]
                            this.state.loginIoRegister = true
                            this.state.userFormModal = true // We seem to need this first...
                            this.setState({ userFormModal: true}) // ... this one, not so much. / this.userFormToggle()
                            return
                        }
                    } else { //  .../reg/?unsubscribe=sels.peter@gmail.com&code=zDVzAvDSjIvefXuL
                        if (infix_subdir === 'reg') {
                            if (this.allowDirectUnsubscribeViaUrlFields &&
                                ('unsubscribe' in urlDictDecompressed) && ('code' in urlDictDecompressed)) {
                                this.sendDataToServer(back_end_full_href, expectedResponseType)
                            } else {
                                this.state.loginIoRegister = false // since first need to login and then go to unsubscribe
                                this.state.userFormModal = true // We seem to need this first...
                                this.setState({ userFormModal: true}) // ... this one, not so much. / this.userFormToggle()
                                //this.onShowAlert(5, 'success', 'Meld u aan en klik dan registreer en verwijder profiel.')
                                return
                            }
                        } else {
                            if (infix_subdir === 'help') {
                                this.state.helpModal = true
                            } else {
                                console.assert(infix_subdir === 'rules')
                                this.state.rulesModal = true
                            }
                        }
                    }
                }
            }
        }

        if (verbose >= 2) {
            console.log(`this.state = ${JSON.stringify(this.state, null, 2)}`)
        }

        this.setInitialRecognisableDummyUserCredentials()
        if (this.allowDirectLoginViaUrlFields) {
            this.fishCredentialsFromUrlAndIfFoundLoadUserRegistrationData(urlDictDecompressed)
        }
    } // end constructor

    removeDirtyCharsFromString(s) {
        // TODO: new, replacing dirty =%5Beetruimte%5D to =[eetruimte]
        // It occurs in the link present in the pdf, but not in the link present in the svg.
        // Peter: I saw the %5B and %5D started to occur since the react changes Mats applied to some entry fields.
        s = s.replace('%5B', '[')
        s = s.replace('%5D', ']')
        s = s.replace('%20', ' ')
        // in fact, let's do away with the brackets and spaces altogether.
        // We do not want any 'locked' factoryReset room
        // to be overwritten by a url sent one.
        // So replacing: [eetruimte] by eetruimte.
        s = s.replace('[', '')
        s = s.replace(']', '')
        s = s.replace('', '')
        // On Apple I get no issue with this. On Linux we get dirty '%5B chars displayed in the room name
        // in svg and pdf. So I decided to root them out at the source: no '[' ']' nor ' ' are sent to the srever.
        // So the svg nor pdf returned to client can have any of those anywmore.

        // Also regular expressions prohibit any use of space or [ or ]. _ is sometimes still allowed but that is passed
        // unchanged in urls.
        return s
    }


    setInitialRecognisableDummyUserCredentials() {
        // Global storage of user info, always to be changed together,
        // for example when it is changed in UserFormModal.js
        this.userLoggedIn = false // default,
        // when this.userLoggedIn is false, the next two vars whould be default false and ''.
        // when this.userLoggedIn is false, the next two vars should be a valid email and a valid user code
        this.userEmail = 'initial' // default, we are a priori logged out and so do not know its value.
        this.userPassword = 'initial' // default, we are a priori logged out and so do not know its value.
        this.userCode = 'AAAAAAAAaaaaaaaa' // default, we are a priori logged out and so do not know its value.
    }

    fishCredentialsFromUrlAndIfFoundLoadUserRegistrationData(urlDictDecompressed) {
        // for login/?emailAddress=sels.peter@gmail.com&code=dNjUZokLKodsttYj
        // and this can come from clicking a link in a registration email
        // we want to load the user reg data.
        const emailFromUrl = 'emailAddress' in urlDictDecompressed ?
            urlDictDecompressed['emailAddress'] : ''
        const codeFromUrl = 'code' in urlDictDecompressed ?
            urlDictDecompressed['code'] : ''
        if ((emailFromUrl !== '') && (codeFromUrl !== '')) {
            this.userEmail = emailFromUrl
            this.userCode = codeFromUrl
            this.sendLoadUserDataFromServerDatabase()
        }
    }

    componentDidMount() {
        const adaptViewingDirectionIndicator = true
        this.handleRoomBySelectedNameChange(this.state.roomName, adaptViewingDirectionIndicator)
    }

    onDismiss = () => this.setState({visible: false});

    getDjangoBackEndBaseUrl() {
        const debug = false
        // For end release on server:
        var djangoBaseUrl = 'http://optiseats.flandersmake.be:80/'
        // 8080 does not work, 80 extern is mapped on 8080 intern
        // var baseUrl = 'http://20.71.29.159:80/form/'  // is equivalent

        // When running Django server on your own machine during testing,
        // set the following const to true:
        const test_django_server_locally_io_global_website_release = true
        if (test_django_server_locally_io_global_website_release) { // so for debugging:
            djangoBaseUrl = 'http://127.0.0.1:8080/'  // localhost
            // This calls django server on your own machine.
        }
        if (debug) {
            console.log(`process.env = ${JSON.stringify(process.env, null, 2)}`)
            console.log(`process.argv = ${JSON.stringify(process.argv, null, 2)}`)
            console.log(`process.execargv = ${JSON.stringify(process.execargv, null, 2)}`)
        }

        const testOnOwnSite = true
        if (testOnOwnSite) {
            djangoBaseUrl = 'http://139.162.152.118:8080/api/'
            djangoBaseUrl = '/api/'
        }

        return djangoBaseUrl
    }

    getCalculationPreferenceStruct() {
        const struct = this.allowMoreControlForDevs ?
             {
              snel:   { label: 'snel (focus op veel tafels, geen spreiding)',
                        patience1_s: 15*1, patience2_s: 0*1
                        },
              snelSp: { label: 'snel (focus op veel tafels en spreiding)',
                        patience1_s: 15*1, patience2_s: 7*1
                        },
              goed:   { label: 'goed (focus op veel tafels, geen spreiding)',
                        patience1_s: 15*2, patience2_s: 0*2
                        },
              goedSp: { label: 'goed (focus op veel tafels en spreiding)',
                        patience1_s: 15*2, patience2_s: 7*2
                        },
              beter: { label: 'beter (focus op veel tafels, geen spreiding)',
                        patience1_s: 15*3, patience2_s: 0*3
                        },
              beterSp: { label: 'beter (focus op veel tafels en spreiding)',
                        patience1_s: 15*3, patience2_s: 7*3
                        },
              best: { label: 'best (focus op veel tafels, geen spreiding)',
                      patience1_s: 15*4, patience2_s: 0*4
                      },
              bestSp: { label: 'best (focus op veel tafels en spreiding)',
                        patience1_s: 15*4, patience2_s: 7*4
                        }
            }
        :
            {
              snel:   { label: 'snel (+/-15 seconden)',
                        patience1_s: 15-4, patience2_s: 4
                        },
              efficient: { label: 'efficiënt (+/-1 minuut)',
                        patience1_s: 60*1-15, patience2_s: 15
                        },
              uitgebreid: { label: 'uitgebreid (+/-3 minuten)',
                        patience1_s: 60*3-45, patience2_s: 45
                        },
            }
        return struct
    }

    getCalculationPreferenceOptions() {
        const struct = this.getCalculationPreferenceStruct()
        var options = []
        for (const [key, dict] of Object.entries(struct)) {
            options.push({value: key, label:dict.label})
        }
        return options
    }

    getDefaultRoomName() {
        //return '[leslokaal]'
        return '[eetruimte]'
    }

    getEmptyRoomName() {
        return '[lege ruimte]'
    }

    getEmptyUserData() {
        //this.state.userData = { // state does not exist here yet
        const userData = {
            firstName : 'initial',
            lastName : 'initial',
            businessName : 'initial',
            sectorName : {value: 'Andere', label:'Andere'},
            phoneNumber : '+',
            howCanWeHelp : '...',
            sendMeNews : false,
            inviteMe : false
        } // we will 'throw it into state' later.
        return userData
    }

    handleIsLogginIn(isLogginIn){
        this.setState({loginIoRegister: isLogginIn })
    }

    loadUrlSpecifiedProblem(urlDictDecompressed, roomDeltaX, roomDeltaY) {
        var urlRoomAsDict = {}

        const verbose = 0
        // Then, if we have some non empty url
        // extract all info from it and overwrite the above configuration.
        // Some fields will not be present in the url, so they remain dictated by the above 'defaults'.

        // this is the name of the problem, or name of the room if you will.
        //const name = params.get('name') || ''
        const name = 'name' in urlDictDecompressed ?
            urlDictDecompressed['name'] : ''

        // TODO: everything written into this.state should be written in a
        // separate dict  'UrlRoomDict', and then I should do this.state.x = UrlRoomDict.val(x) for all keys x in UrlRoomDict.
        // We then also do this.userRoomDict[name] = UrlRoomDict (copy)

        // (1) delete the above
        urlRoomAsDict.obstacleTypes = []
        //this.setState(urlRoomAsDict.obstacles, [])

        urlRoomAsDict.tableTypes = []
        urlRoomAsDict.reservationTypes = []

        var tmpDict = {}
        tmpDict['obstacleTypes'] = []
        tmpDict['tableTypes'] = []
        tmpDict['reservationTypes'] = []

        // (2) add stuff to urlRoomAsDict:

        // (2.a) Room related:
        //this.state.room = 0  // TODO: is room used at all?... I think not...only roomName
        urlRoomAsDict.roomName = name
        if (verbose >= 1) {
            console.log(`roomDeltaX = ${roomDeltaX}`)
            console.log(`roomDeltaY = ${roomDeltaY}`)
        }
        if ((roomDeltaY !== '') && (roomDeltaX !== '')) {
            if (verbose >= 2) {
                console.log('setting roomsize')
            }
            roomDeltaX = parseFloat(roomDeltaX)
            roomDeltaY = parseFloat(roomDeltaY)
            urlRoomAsDict.roomSize = {'x':roomDeltaX, 'y':roomDeltaY}
        }

        // -1 is equivalent to no direction required, rest of values is now 0 to the right, 90 for up
        // 180 for look left and 270 for look down.
        urlRoomAsDict.viewingDirection = 'ViewingDir' in urlDictDecompressed ?
            urlDictDecompressed['ViewingDir'] : ''
        // max neck strain angle w.r.t. viewingDirection
        // is read, stored and sent to server for algo
        // but has no effect on table positioning algo at all
        urlRoomAsDict.viewingTolerance = 'ViewingTol' in urlDictDecompressed ?
            urlDictDecompressed['ViewingTol'] : ''

        //console.log(`GGG: look for patience1_s in urlDictDecompressed = ${JSON.stringify(urlDictDecompressed, null, 2)}`)

        const patience1_s = 'patience1_s' in urlDictDecompressed ?
            parseInt(urlDictDecompressed['patience1_s']) : -1 // The parseInt is crucial!
        const patience2_s = 'patience2_s' in urlDictDecompressed ?
            parseInt(urlDictDecompressed['patience2_s']) : 0 // The parseInt is crucial!
        urlRoomAsDict.patience1_s = patience1_s
        urlRoomAsDict.patience2_s = patience2_s

        const calcPrefListBoxOption =
            this.convertPatienceCoupleToCalculationPreferenceListBoxOption(
                patience1_s, patience2_s) // WORKS FINE
        urlRoomAsDict.calculationPreference = calcPrefListBoxOption
        if (verbose >= 2) {
            console.log(`calcPrefListBoxOption = ${JSON.stringify(calcPrefListBoxOption, null, 2)}`)
        }
        urlRoomAsDict.userData = this.getEmptyUserData() // NEW
        this.responseFromServer = ''

        //this.state.roomName = params.get('name') || ''

        // (2.b and 2c) 'AllowedZones' and 'Obstacles'
        // seat_Objects_side_2_2: first index is table type nr, second index is side
        // sides number from 0 to 3 and 0 is left, 1 is up, 2 is right, 3 is down.
        // We specify only the url keywords we want to search for per objectType,
        // because, as soon as we do not find it, the record cannot be complete
        // and then we must give up on finding that record (let alone insert it into this.state).
        const objectSuffixes = ['_list_name_', '_x_', '_y_', '_sides_',
        '_odd_length_', '_even_length_', 'number_of_',
        'seat_side_0', 'seat_side_1', 'seat_side_2', 'seat_side_3']
        const forbiddenZoneSuffixes = ['_list_name_', '_x_', '_y_', '_sides_',
        '_odd_length_', '_even_length_', 'number_of_']
        const suffixMap = {'Objects': objectSuffixes, 'ForbiddenZones': forbiddenZoneSuffixes}

        // This translationMap can of course be general for both Objects and ForbiddenZones.
        const translationMap = {
            '_list_name_': 'name',
            '_x_':'x', // but for obstacles only and then under subkey instances
            '_y_':'y', // but for obstacles only and then under subkey instances
            '_sides_':'n_sides',
            '_odd_length_':'width', // side index 1 is odd
            '_even_length_':'height', // side index 0 is even
            'number_of_':'number',
            'seat_side_0': 'seatingSide0',
            'seat_side_1': 'seatingSide1',
            'seat_side_2': 'seatingSide2',
            'seat_side_3': 'seatingSide3',
            'seat_side_4': 'seatingSide4',
            'seat_side_5': 'seatingSide5',
        }
        var nameListDict = { 'tableTypes': {}, 'obstacleTypes': {}}
        // We do not really use the 'Objects' (tables) part yet,
        // but will need it if ever we position those (and so have their coordinates stored in this.state as well)
        // like we do with ForbiddenZones. The 'ForbiddenZones' part is already used.
        const objectTypes = ['Objects', 'ForbiddenZones']
        for (var objType of objectTypes) {
            const mainDictKey = (objType==='Objects') ? 'tableTypes' :
                (objType==='ForbiddenZones' ? 'obstacleTypes': 'room')
            var foundForObjNr = true
            var objNr = 1
            do {
                var coordinates = {'x':null, 'y':null}
                var currentListName = ''
                for (var suffix of suffixMap[objType]) {
                    const newKey = translationMap[suffix]

                    // default objListNameKey value:
                    var objListNameKey = objType + suffix + objNr.toString()

                    // a few exceptions on default objListNameKey value:
                    if (suffix === 'number_of_') {
                        // yes: (1)  suffix is a prefix and
                        // (2) some silly plural s is needed here:
                        objListNameKey = suffix + objType + 's_' + objNr.toString()
                    }
                    if (suffix === '_sides_') {
                        // yes: (1) some silly n prefix is needed here:
                        // should be sth like: n_Objects_sides_1
                        objListNameKey = 'n_' + objType + suffix + objNr.toString()
                    }
                    if (suffix.startsWith('seat_side_')) { // always ends in 0,1,2,3
                        // yes: (1) some silly n prefix is needed here:
                        // should be sth like: n_Objects_sides_1
                        // e.g.: seat_Objects_side_3_0
                        // seats is only for Tables, so 'Objects'
                        const parts = suffix.split('_')
                        const sideNr = parts[parts.length-1]
                        objListNameKey = 'seat_' + objType + '_side_' + objNr.toString() + '_' + sideNr
                    }

                    if (verbose >= 2) {
                        console.log(`searching for objListNameKey: ${objListNameKey}`)
                    }

                    var value = (objListNameKey in urlDictDecompressed) ?
                        urlDictDecompressed[objListNameKey] : ''

                    foundForObjNr = value !== ''

                    value = convertToIntegerOrFloatIfPossible(value)

                    if (foundForObjNr) {
                        if (verbose >= 2) {
                            console.log(`found for oldKey=${objListNameKey}: ${value}`)
                        }
                        if (suffix === '_list_name_') { // Need to remember this for structuring instances arrays
                            currentListName = value // a thing like 'pilaar' or so

                            if (!(currentListName in nameListDict[mainDictKey])) {
                                nameListDict[mainDictKey][currentListName] = {}
                            } // now we can add elements to nameListDict[objType][value]
                        }
                        if (newKey==='x' || newKey==='y') { // special case
                            if (verbose >= 3) {
                                console.log(`objType = ${objType}`)
                            }
                            console.assert(['ForbiddenZones','Objects'].includes(objType))
                            //if (objType==='ForbiddenZones') { // We also can receive this x and y info
                            // for objects (Tables), but we do not store this in these objects.
                            if (true) {
                                // Here we need to do something with lookup of _list_name_ to see if it already exists
                                coordinates[newKey] = value
                                // which is to be assigned to the super dict:helperObjDict only later
                                if (!('instances' in nameListDict[mainDictKey][currentListName])) {
                                    nameListDict[mainDictKey][currentListName]['instances'] = [] // directly list not dict
                                }
                                if (objType!=='Objects') {
                                    var coordsDictList = nameListDict[mainDictKey][currentListName]['instances']
                                    if (newKey==='x') {
                                        const xCoord = {'x': value}
                                        coordsDictList.push(xCoord)
                                    }
                                    if (newKey==='y') {
                                        coordsDictList.[coordsDictList.length-1]['y'] = value
                                    }
                                }
                            }
                        } else { // general case: store directly in helperObjDict
                            if (verbose >= 3) {
                                console.log(`nameListDict[mainDictKey][currentListName][newKey] = value gives: nameListDict[${mainDictKey}][${currentListName}][${newKey}] = ${value}`)
                            }
                            nameListDict[mainDictKey][currentListName][newKey] = value
                        }
                    } else {
                        // Coming here is not a problem, it's just one index objNr too far,
                        // and we will store nothing with this index=objNr. We just have to complete the loop here.
                        if (verbose >= 4) {
                            console.log(`found for objListNameKey=${objListNameKey}: nothing`)
                        }
                    }
                }
                objNr += 1
            } while (foundForObjNr)
        }

        // convert nameListDict to added state
        for (var mainDictKey in nameListDict) { // objOrZoneType is 'tableTypes' or 'obstacleTypes'
            urlRoomAsDict[mainDictKey] = [] // to replace i.o. combine with default startup config
            var d1 = nameListDict[mainDictKey]
            for (var nameListName in d1) { // things like 'vierkant2z2p' or 'pilaar'
                var d2 = d1[nameListName]
                if (mainDictKey==='obstacleTypes') {
                    // correct historically sometimes wrong set to 1 number of objects
                    // so that also the input box in the GUI has the right number of obstacle of this type.
                    d2['number'] = Object.keys(d2['instances']).length
                }
                urlRoomAsDict[mainDictKey].push(d2)
            }
        }
        if (verbose >= 1) {
            console.log(`loadUrlSpecifiedProblem from url: nameListDict = ${JSON.stringify(nameListDict, null, 2)}`)
            console.log(`loadUrlSpecifiedProblem from url: urlRoomAsDict = ${JSON.stringify(urlRoomAsDict, null, 2)}`)
        }

        // Add reservations:
        /* We need to create something as in DefaultRoomInstances.js
        reservationTypes: [
            {  name: 'groep van 2', groupSize : 2, number: 6 },
            {  name: 'groep van 5', groupSize : 5, number: 2 },
            {  name: 'groep van 6', groupSize : 6, number: 1 },
        ]
        */
        var foundForObjNr = true
        var objNr = 1
        var key1 = ''
        var key2 = ''
        do {
            if (verbose >=2) console.log(`objNr = ${objNr}`)
            key1 = `rgs_${objNr}`
            const reservationGroupSize = key1 in urlDictDecompressed ? parseInt(urlDictDecompressed[key1]) : -1
            key2 = `rgn_${objNr}`
            const reservationGroupNumber = key2 in urlDictDecompressed ? parseInt(urlDictDecompressed[key2]) : -1
            foundForObjNr = (reservationGroupSize !== -1) && (reservationGroupNumber !== -1)
            if (foundForObjNr) {
                urlRoomAsDict.reservationTypes.push(
                    { 'name':`groep van ${reservationGroupSize}`,
                      'groupSize': reservationGroupSize,
                      'number': reservationGroupNumber
                    }
                )
            } else {
                if (verbose >=2) console.log(`not found anymore`)
            }
            objNr+= 1
        } while (foundForObjNr)

        if (verbose >= 1) {
            console.log(`urlRoomAsDict = ${JSON.stringify(urlRoomAsDict, null, 2)}`)
        }

        this.userRoomDict = {}
        this.userRoomDict[name] = urlRoomAsDict
        this.state.roomName = name

        for (const [key, item] of Object.entries(urlRoomAsDict)) {
            this.state[key] = item
        }
    }

    resetInternalState() {
        const debug = false
        if (typeof this.state === 'undefined') {
            this.state = {}
        }
        var ts = this.state // TODO: this gets undefined for db stored user rooms, not for any factoryResetRooms
        if (debug) {
            console.log(`resetInternalState: this.roomName = ${this.roomName}`)
        }
        ts.alert = {
            color: "success",
            text:"dit is een test"
        }
        ts.verifyDeleteRoomModal = false
        ts.verifyDeleteProfileModal = false
        ts.isEditTable = false
        ts.isEditObstacle = false
        ts.visible = false
        ts.openResultModal = false
        ts.loading = false
        ts.helpModal = false
        ts.rulesModal = false
        ts.roomOrientation = "naar beneden"
        ts.collapseRoomForm = false
        ts.collapseObstacleForm = true
        ts.collapseTableForm = true
        ts.collapseReservationForm = true
        ts.obstacleModal = false
        ts.tableModal = false
        ts.reservationModal = false
        ts.userFormModal = false
        ts.saveConfigurationAsModal = false
        ts.configSaveAsName = ''
        ts.obstacleActiveItem = -1
        ts.tableActiveItem = -1
        ts.calculatedSvg = null
        //ts.reservationActiveItem = -1
        // We require this is specified in the problem itself, so not internal
        //ts.standardTableType = 'rectangularTable' // 'roundTable' (polygon really) and rowOfSeats being the other 2
        //ts.standardPolygonNSides = '6'
        // ts.standardTable = {..}
        ts.loginIoRegister =  true
        //ts.userLoggedIn =  false // TODO: let this depend on incoming url params, use this.userLoggedIn (i.o. this.state.userLoggedIn)
        ts.windowWidth = 750
        ts.windowHeight = 750
        ts.standardObstacle = {
            name : "obstakel0",
            width:0.5,
            height:0.5,
            n_sides:"4",
            number:1,
            instances : [{x:0, y:0}]
        }
        ts.standardReservation = {
            name: 'groep van 4',
            groupSize : 4,
            number: 1
        }
        ts.obstacleX = 0.5
        ts.obstacleY = 0.5
        ts.seatTableDistance_m = 0.3
        ts.seatSeatDistance_m = 0.6
        ts.n_pixels_per_m =  46  // ends up with same width as of initial room configuration
        ts.calculating =  false
        ts.calculated =  false
        ts.downloading =  false
        ts.downloaded = false
    }

    // The idea here, is that only if we pass the pattern test,
    // do we update the field to the value entered.
    validatePatternAndChangeIfOk = (e, pattern) => {
      const debug = false
      var val = e.target.value
      var name = e.target.name
      if (debug) {
          console.log(`val = ${val}`)
          console.log(`name = ${name}`)
          console.log(`pattern = ${pattern}`)
      }
      const reInteger = /^[0-9]+$/;
      const reInteger3digits = /^[1-9][\d]?[\d]?$/;
      const reThreeDotOneDigits = /^[1-9]\d?\d?(\.[0-9])?$/;
      const reTwoDotOneDigits = /^[1-9]\d?(\.[0-9])?$/;
      var re = null
      if (pattern === 'integer') {
        re = reInteger
      } else if (pattern === 'integerThreeDigits') {
        re = reInteger3digits
      } else if (pattern === 'threeDotOneDigits') {
        re = reThreeDotOneDigits
      } else if (pattern === 'twoDotOneDigits') {
        re = reTwoDotOneDigits
      } else {
        alert(`Pattern ${pattern} is not recognised. Contact developer team.`)
      }
      if (val === '' || re.test(val)) {
        // Note, that unfortunately, we must (temporarily) accept the empty string, because otherwise editing towards
        // something totally different is not possible. This means that we must catch the empty value when and where
        // we use it. That means, we should know the 'default id empty' value there.
        if (name === 'width') {
          this.setState({roomSize: {x:val, y:this.state.roomSize.y}})
        } else if (name === 'height') {
          this.setState({roomSize: {y:val, x:this.state.roomSize.x}})
        } else if (name === 'patience1_s') {
          this.setState({patience1_s: val})
        } else if (name === 'patience2_s') {
          this.setState({patience2_s: val})
        }
        if (debug) {
            console.log(`test OK`)
        }
      } else {
        if (debug) {
            console.log(`test NOK`)
        }
      }
   }

    addObstacle = (i) =>{
        var obstacleTypes = this.state.obstacleTypes;
        obstacleTypes[i].instances.push({x:this.state.obstacleX,y:this.state.obstacleY});
        this.setState(
            {
                obstacleTypes:obstacleTypes,
                obstacleX : this.state.obstacleX+0.1,
                obstacleY :this.state.obstacleY+0.1,
            }
        )
    }

    removeObstacleOfType = (i) => {
        var obstacleTypes = this.state.obstacleTypes;
        obstacleTypes[i].instances.pop();
        this.setState({obstacleTypes:obstacleTypes, });
    }

    userFormToggle = () => {
        this.setState({ userFormModal: !this.state.userFormModal });
        //console.log(`this.state.userFormModal = ${this.state.userFormModal}`)
    };

    tableHandleSubmit = (tableType) => {
        this.tableToggle();  // should switch off
        let tableTypes;

        // We should add the storing of standard values of tableTypes.
        // post-process: some fields we did not esk the user for are hidden
        // and are here derive from other field values.

        // We also correct some fields here to the defaults whenever a user entered something wrong.
        // It's a bit late to do that here,
        // but I lack time to do complete processing of each fields separately,
        // via event handlers in the form itself.
        // TODO: Add event handlers and stop user from submitting the form if something is wrong in any field,
        // a la: the form in App.js.
        if (this.state.activeItem === -1) {
            // creates new table
            const newTableType = { ...tableType, uid: generateUid()};
            tableTypes = this.state.tableTypes.concat(newTableType)
        } else {
            // edit active table
            tableTypes = this.state.tableTypes
            tableTypes[this.state.activeItem] = tableType
        }
        this.setState({ tableTypes:tableTypes, activeItem: -1 })
    }

    obstacleHandleSubmit = (obstacleType) => {
        this.obstacleToggle();
        let obstacleTypes;
        let obstacleX=this.state.obstacleX;
        let obstacleY=this.state.obstacleY;
        if (this.state.activeItem === -1) {
            const newObstacle = { ...obstacleType, uid: generateUid()}
            newObstacle.instances = [{x:obstacleX,y:obstacleY}];
            obstacleTypes=this.state.obstacleTypes.concat(newObstacle);
            obstacleX+=0.1;
            obstacleY+=0.1;
        } else {
            obstacleTypes = this.state.obstacleTypes;
            obstacleTypes[this.state.activeItem] = obstacleType;
        }
        this.setState({
            obstacleTypes: obstacleTypes,
            activeItem: -1,
            obstacleX: obstacleX,
            obstacleY: obstacleY}
        )
    }

    // logon and registration happens with this form and function
    userFormHandleSubmit = (urlSuffix, expectedResponseType,
                            bufferedUserEmail, // stateToGetUrlSuffix has set these 3: 1
                            bufferedUserPassword, // 2
                            bufferedUserCode, // 3
                            bufferedUserData
    ) => {
        const debug = false

        this.userFormToggle() // switch off the user form dialogue, gets us back to main window
        const fullGetUrl = this.getDjangoBackEndBaseUrl() + urlSuffix

        this.userEmail = bufferedUserEmail
        this.userPassword = bufferedUserPassword
        this.userCode = bufferedUserCode
        this.state.userData = bufferedUserData
        if (debug) {
            console.log('In userFormHandleSubmit')
            console.log(`  urlSuffix = ${JSON.stringify(urlSuffix, null, 2)}`)
            console.log(`fullUrl = ${fullGetUrl}`)
            console.log('store buffered values from UserFormModal.js in App.js')
            console.log('userFormHandleSubmit stored:')
            console.log(`  this.userEmail = bufferedUserEmail = ${this.userEmail} `)
            console.log(`  this.userPassword = bufferedUserPassword = ${this.userPassword} `)
            console.log(`  this.userCode = bufferedUserCode = ${this.userCode} `)
            console.log(`  this.state.userData = bufferedUserData = ${this.state.userData} `)
        }

        if (debug) {
           console.log(`userFormHandleSubmit: fullGetUrl = ${fullGetUrl}`)
        }
        this.sendDataToServer(fullGetUrl, expectedResponseType)
        if (expectedResponseType === 'login') {
            this.sendLoadUserDataFromServerDatabase() // to set state ready for first form filling
            this.mentionRequired = false
        }
    }

    handleUserLogout() {
        const expectedResponseType = 'logout'
        const fullGetUrl = this.getDjangoBackEndBaseUrl() + expectedResponseType + '/'
        + '?emailAddress=' + this.userEmail
        + '&code=' + this.userCode
        this.userLoggedIn = false
        this.mentionRequired = false
        this.sendDataToServer(fullGetUrl, expectedResponseType)
    }

    // new name since called from verifyDeleteProfileModal
    handleDeleteProfile() {
        const debug = false
        if (!this.userLoggedIn) {
            this.state.loginIoRegister = true
            //this.handleUserForm()
        } else {
            const expectedResponseType = 'reg'
            var url = ''
            url += `?unsubscribe=${this.userEmail}`
            url += `&code=${this.userCode}`
            const fullGetUrl = this.getDjangoBackEndBaseUrl() + expectedResponseType + '/' + url
            if (debug) console.log(fullGetUrl)
            this.sendDataToServer(fullGetUrl, expectedResponseType)
        }
        // remove user modal window:
        this.userFormToggle() // on -> off

        // logout:
        this.userLoggedIn = false
        this.mentionRequired = false

        this.toggleVerifyDeleteProfileModal() // on -> off
    }

    userFormHandleResetCode = (urlSuffix, expectedResponseType) => {
        const debug = false
        const fullGetUrl = this.getDjangoBackEndBaseUrl() + urlSuffix
        if (debug) {
            console.log('userFormHandleResetCode')
            console.log(`fullUrl = ${fullGetUrl}`)
        }
        this.sendDataToServer(fullGetUrl, expectedResponseType)
    }

    /// rules button stuff ///
    rulesToggle = () => {
        this.setState({ rulesModal: !this.state.rulesModal });
    }

    rulesHandleSubmit = () => {
        this.rulesToggle()
    }

    /// help button stuff ///
    helpToggle = () => {
        this.setState({ helpModal: !this.state.helpModal });
    }

    helpHandleSubmit = () => {
        this.helpToggle()
    }

    /// obstacles methods ///
    obstacleToggle = () => {
        this.setState({ obstacleModal: !this.state.obstacleModal });
    }

    createObstacle = () => {
        this.setState({activeItem:-1, isTable:false, isEditObstacle: false});
        this.obstacleToggle()
    }

    editObstacle = (x) => {
        this.setState({activeItem:x, isTable:false, isEditObstacle: true })
        this.obstacleToggle()
    }

    deleteObstacle = (obstacleType) => {
        const obstacles = this.state.obstacleTypes
        // Since uid-s are sometimes not defined (like when reading back data from the DB, where they are not stored),
        // the next line deletes all obstacles i.o. the selected one only. So probably drop uid altogether.
        // const newObstacleList = obstacles.filter(obstacle => obstacle.uid !== obstacleType.uid)
        // Equally named ones are deleted:
        const newObstacleList = obstacles.filter(obstacle => obstacle.name !== obstacleType.name)
        this.setState({obstacleTypes: newObstacleList})
    }

    generateObstacleInstances = (obstacleTypes) => {
        var obstacles = [];
        for (var obstacleType of obstacleTypes) {
            for (var i=0;i<obstacleType.instances.length;i++) {
                var obstacleInstance ={...obstacleType, x:obstacleType.instances[i].x,y:obstacleType.instances[i].y};
                obstacles.push(obstacleInstance)
            }
        }
        return obstacles;
    }

    updateObstacleNumber = (i, number) => {
        var obstacleTypes = this.state.obstacleTypes;
        obstacleTypes[i].number = number
        this.setState(
            {
              obstacleTypes:obstacleTypes,
              obstacles:this.generateObstacleInstances(obstacleTypes),
            }
        )
    };

    /// table methods ///
    tableToggle = () => {
        this.setState({ tableModal: !this.state.tableModal });
    }

    createTable = () => {
        this.setState({activeItem:-1, isTable:true, isEditTable: false});
        this.tableToggle();   // should switch on
    }

    editTable = (index) => {
        this.setState({activeItem:index, isTable:true, isEditTable: true})
        this.tableToggle();   // should switch on
    }

    deleteTable = (tableRecord) => {
        const tables = this.state.tableTypes
        // Since uid-s are sometimes not defined (like when reading back data from the DB, where they are not stored),
        // the next line deletes all tables i.o. the selected one only.  So probably drop uid altogether.
        // const newTableList = tables.filter(table => table.uid !== tableRecord.uid)
        // Equally named ones are deleted:
        const newTableList = tables.filter(table => table.name !== tableRecord.name)
        this.setState({tableTypes: newTableList})
    }

    updateTableNumber = (index, number) => {
        var tableTypes = this.state.tableTypes;
        tableTypes[index].number = number;
        this.setState({tableTypes:tableTypes})
    }

    /// reservation methods ///
    reservationToggle = () => {
        this.setState({ reservationModal: !this.state.reservationModal });
    }

    createReservation = () => {
        this.setState({activeItem:-1, isTable:false}); // no active item needed since no sub-dialogue
        this.reservationToggle();   // should switch on
    }

    editReservation = (index) => {
        this.setState({activeItem:index, isTable:false})
        this.reservationToggle();   // should switch on
    }

    deleteReservation = (reservationType) => {
        // The logic here is:
        // keep all reservation records with a name
        // different from the one(s) with the name to be deleted
        this.setState({reservationTypes:
            this.state.reservationTypes.filter((value, index, arr) => value.name !== reservationType.name)})
    }

    updateReservationNumber = (index, number) => {
        //console.log(`updateReservationNumber(index = ${index}, number=${number})`)
        var reservationTypes = this.state.reservationTypes;
        reservationTypes[index].number = number;
        this.setState({reservationTypes:reservationTypes})
    }

    reservationHandleSubmit = (reservationType) => {
        this.reservationToggle();
        let reservationTypes;
        if (this.state.activeItem === -1) {
            console.log(`reservationHandleSubmit::: === -1`)
            reservationTypes = this.state.reservationTypes.concat(reservationType)
        } else {
            console.log(`reservationHandleSubmit::: !== -1`)
            reservationTypes = this.state.reservationTypes
            reservationTypes[this.state.activeItem] = reservationType
        }
        reservationTypes = this.compactifyReservations(reservationTypes)
        this.setState({ reservationTypes:reservationTypes, activeItem: -1 })
    }

     // This function adds up numbers of reservations of the same groupSize. This avoids duplicate
     // groupSizes (and groupSize names) at the source.
     compactifyReservations(inReservationTypes) {
         var groupSizeToArrayIndexDict = {}
         var outReservationTypes = []
         for (var i = 0; i < inReservationTypes.length; i++) {
            const inRecord = inReservationTypes[i]
            const groupSize = inRecord.groupSize
            if (groupSize in groupSizeToArrayIndexDict) {
                const index = groupSizeToArrayIndexDict[groupSize]
                var existingOutRecord = outReservationTypes[index]
                const sumInt = parseInt(existingOutRecord.number) + parseInt(inRecord.number)
                const sumStr = sumInt.toString()
                existingOutRecord.number = sumStr
            } else {
                outReservationTypes.push(inRecord)
                groupSizeToArrayIndexDict[groupSize] = outReservationTypes.length-1
            }
         }
         return outReservationTypes
     }

    handleRoomBySelectedNameChange = (roomName, doAdaptViewingDirectionIndication) => {
        //this.roomName = roomName // TODO: check if needed, try first without
        const debug = false
        if (debug) {
            console.log(`handleRoomBySelectedNameChange(roomName:${roomName}, ${doAdaptViewingDirectionIndication})`)
        }
        //this.roomName = roomName
        if (debug) { // to be removed when debugged
            console.log(`roomName = ${roomName}`)
            console.log(`this.isAFactoryResetRoom(roomName) = ${this.isAFactoryResetRoom(roomName)}`)
            //console.log(`this.userRoomDict = ${JSON.stringify(this.userRoomDict, null, 2)}`)
        }
        var roomDictValue = {}
        /*
        if (this.isAFactoryResetRoom(roomName)) {
            //console.log('isAFactoryResetRoom')
            roomDictValue = this.factoryResetRoomDict[roomName]
        } else { // 2 cases here:
            // 1. from an url, we want to have a room defined in this.userRoomDict[roomName] here.
            //console.log(`KKK: roomName = ${roomName}`)
            //console.log(`KKK: this.userRoomDict = ${JSON.stringify(this.userRoomDict, null, 2)}`)
            // 2. for a logged in user we also want it in this.userRoomDict[roomName]

            //console.log('not isAFactoryResetRoom')
            roomDictValue = this.userRoomDict[roomName]
        }
        */
        roomDictValue = this.getRoomByName(roomName)

        if (typeof roomDictValue === 'undefined') {
            // When saving a room as ... a new name, it can be that we do not have loaded this
            // room yet, however, the name is in this.roomName already.
            // We should then use this.roomName after the next load_cfgs.
            // For now we will select the default room here.
            if (debug) {
                // This happens at save_as, the saved_as room is not know yet...
                console.log('room not yet known, need to read it from server...')
            }
            // temporarilySwitchToEmptyRoom
            if (this.userLoggedIn) {
                this.roomName = roomName = this.getEmptyRoomName()
                roomDictValue = this.factoryResetRoomDict[roomName]
                doAdaptViewingDirectionIndication = true
            } else {
                // not logged in, not a problem.
            }
        }

        if (roomDictValue !== {}) {
            this.updateAllStateAfterRoomNameChange(roomName, roomDictValue, doAdaptViewingDirectionIndication)
        } else {
            console.log('room not defined!')
        }
    }

    updateAllStateAfterRoomNameChange(roomName, roomDictValue, doAdaptViewingDirectionIndication) {
        const debug = false
        this.roomName = roomName // TODO: check if needed, try first without

        // TODO: remove temp debugging
        //console.log(`LOOKHERE: this.state = ${JSON.stringify(this.state, null, 2)}`)
        //console.log(`LOOKHERE: roomDictValue = ${JSON.stringify(roomDictValue, null, 2)}`)

        // TODO: Are roomDictValue.patience1_s, roomDictValue.patience1_s copied to this.state?
        // Supposing so, we can directly use: this.state.patience1_s, this.state.patience2_s
        //const adaptCalculationPreferenceListBox = true
        //if (adaptCalculationPreferenceListBox) {
        if (debug) {
            console.assert(typeof roomDictValue !== 'undefined')
        }
        // TODO: This is a patch, but this info should be available here! FIX in a better way!

        if (roomDictValue === null) { // TODO: fix cause
            return // giving up on updating the derived listbox state is not as bad as crashing
        }

        if (!('patience1_s' in roomDictValue)) {
            if (debug) {
                console.log(`Had to set default calculation preferences.`)
            }
            roomDictValue.patience1_s = 60*1-15
            roomDictValue.patience2_s = 15
            // user can anyway set it to any of the three optina manually by the calculationPreference listbox selection.
        }
        if (debug) {
            console.log(`roomDictValue.patience1_s = ${roomDictValue.patience1_s}`)
            console.log(`roomDictValue.patience2_s = ${roomDictValue.patience2_s}`)
        }
        const calcPrefListBoxOption =
            this.convertPatienceCoupleToCalculationPreferenceListBoxOption(
                roomDictValue.patience1_s, roomDictValue.patience2_s)

        // this creates/sets all needed fields:
        this.state = roomDictValue
        // before this.setState can be called:
        this.setState({ // only one call to setState is better
            ...roomDictValue,
            calculationPreference: calcPrefListBoxOption })

        this.resetInternalState() // This does not trigger render,
        // which is ok because it considers internal state not visualised.

        if (doAdaptViewingDirectionIndication) {
            this.adaptViewingDirectionIndication()
        }
    }

    adaptViewingDirectionIndication() {
        const debug = false
        const dict = { '-1': 'None' , '0':'Right', '90':'Up', '180': 'Left', '270': 'Down' }
        const buttonSuffix = dict[this.state.viewingDirection]
        const buttonId = 'viewingDir' + buttonSuffix
        if (debug) {
            console.log(`this.state.viewingDirection = ${this.state.viewingDirection}`)
            console.log(`buttonId = ${buttonId}`)
        }
        this.handleViewingDirectionChange(buttonId) // changes orange indication after render
    }

    handleCalculationPreferenceChange(event) {
        const debug = false
        // event is a dict with a value and a label keys, which in our app are always equal,
        // for example: {value: 'best', label: 'best' }
        if (debug) {
            console.log(`handleCalculationPreferenceChange.event = ${JSON.stringify(event, null, 2)}`)
        }
        // See that the calculationPreference exists.
        // TODO: I seem to need to do that here, but would expect calculationPreference already to exist,
        // since it is defined in this.resetInternalState() which is called in the constructor.
        if (typeof this.state.calculationPreference === 'undefined') {
            const default_value = 'snel'
            this.state.calculationPreference = { value: default_value,  label: default_value}
        }
        // Adapt the corresponding effect on the server algo:
        const patience_dict = this.convertCalculationPreferenceValueToPatienceCouple(event.value)
        if (debug) {
            console.log(`event.value = ${event.value}`)
        }
        // Throw only one render event by adapting both at the same time:
        this.setState({
            calculationPreference: event,
            patience1_s: patience_dict.patience1_s,
            patience2_s: patience_dict.patience2_s
        })
    }

    convertCalculationPreferenceValueToPatienceCouple(calculationPreferenceValue) {
        const struct = this.getCalculationPreferenceStruct()
        const record = struct[calculationPreferenceValue]
        return {
            patience1_s: record.patience1_s,
            patience2_s: record.patience2_s
        }
    }

    handleRoomSelectChange = (event) => {
        const debug = false
        var str = JSON.stringify(event, null, 2)
        if (debug) {
            console.log('handleRoomSelectChange(event)')
            console.log(`event = ${str}`)
        }
        const roomName = event.value
        //this.setState({ room: roomName });  // TODO: this.state.room is used here, but is it not impemented by 2 lines below?
        this.setState({ roomName: roomName });
        this.handleRoomBySelectedNameChange(roomName, true)
    }

    convertPatienceCoupleToCalculationPreferenceListBoxOption(patience1_s, patience2_s) {
        const debug = false
        if (debug) {
            console.log(`convertPatienceCoupleToCalculationPreferenceListBoxOption(
                patience1_s=${patience1_s}, patience2_s=${patience2_s})`)
        }
        var selectedIndex = 0
        const options = this.getCalculationPreferenceOptions()
        const struct = this.getCalculationPreferenceStruct()
        var index = 0
        for (const [key, item] of Object.entries(struct)) {
            if (debug) {
                console.log(`index = ${index}`)
                console.log(`item = ${JSON.stringify(item, null, 2)}`)
            }
            var ok = true
            // WARNING: assuming (I did check) that all patience vars are integers here!
            console.assert(typeof patience1_s === 'number')
            console.assert(typeof patience2_s === 'number')
            console.assert(typeof item.patience1_s === 'number')
            console.assert(typeof item.patience2_s === 'number')
            const notEnoughPatience = this.allowMoreControlForDevs
                ? (patience1_s < item.patience1_s)
                : ((patience1_s + patience2_s) < (item.patience1_s + item.patience2_s ))
            if (notEnoughPatience) { // user has not this patience for more tables
                ok = false
                // early return
                return options[selectedIndex] // previous selected option
                // selectedIndex stays what it was
            } else {
                if (this.allowMoreControlForDevs) {
                    if (patience2_s < item.patience2_s) { // user has not this patience for spreading
                        ok = false
                        // selectedIndex stays what it was
                    } else {
                        // ok stays true
                        selectedIndex = index // update to more time allowed
                    }
                } else {
                    // ok stays true
                    selectedIndex = index // update to more time allowed
                }
            }
            if (debug) {
                console.log(`item.patience1_s = ${item.patience1_s}`)
                console.log(`item.patience2_s = ${item.patience2_s}`)
                console.log(`ok = ${ok}`)
            }
            index++
        }
        // Here, we get the selectedOption[selectedIndex] with the
        // patience1_s and patience2_s params that are acceptable to what the user specified.
        return options[selectedIndex]
    }

    handleRoomEditNameChange(event) {
        this.setState({value: event.target.value});
    }

    updateObstacles = f => this.setState({obstacles:f});
    updateObstacleTypes = f => this.setState({obstacleTypes:f});

    updateRoomSize = size=>this.setState({roomSize:size})

    toRectangle = (width, height, x, y) => {
        if (x===undefined) {
            x=0;
        }
        if (y===undefined) {
            y=0;
        }
        var result = {
            Rectangle:{
                1:{x:x,y:y,z:0},
                2:{x:x,y:y+height,z:0},
                3:{x:x+width,y:y+height,z:0},
                4:{x:x+width,y:y,z:0},
            }
        };
        return result;
    }

    exportTables = () => {
        var tables = {};
        var counter = 0;
        for (var tableType of this.state.tableTypes) {
            var rectangle = this.toRectangle(tableType.width, tableType.height)
            rectangle.Rectangle.seats = {0:"A", 1:"A", 2:"A", 3:"A", 4:0.3, 5:0.6}
            var n = tableType.number
            if (n === '') { // can temporarily be the case
                n = 0  // patch problem here
            }
            for (var i=0; i<n; i++) {
                tables[counter] = rectangle;
                counter++;
            }
        }
        return tables;
    }

    exportSingleObjectToGetUrl =
        (objectTypeName, listName, x, y, nSides, d0, d1, nObjects, objIdx, cumulativeInstanceIdx) => {

        // Overrule index in output name
        objIdx = cumulativeInstanceIdx

        //var objTypeNameForUrl = listName.replaceAll(' ', '+')  // after trying to make things work for IE,
        // replaceAll did not work anymore! So do this instead:
        var objTypeNameForUrl = listName.split(' ').join('+')

        var rectangleStr = `&${objectTypeName}_list_name_${objIdx}=${objTypeNameForUrl}`

        rectangleStr += `&${objectTypeName}_x_${objIdx}=${x}&${objectTypeName}_y_${objIdx}=${y}`
        // These coordinates don't matter for tables actually.

        rectangleStr += `&n_${objectTypeName}_sides_${objIdx}=${nSides}`
        var tableOddLength = d1  // This is the mapping expected by the server.
        // called 'lange zijde' in Modal.js? -> Peter changed it to 'breedte'
        // since there is no guarantee that this is the longest side.
        rectangleStr += `&${objectTypeName}_odd_length_${objIdx}=${tableOddLength}`
        var tableEvenLength =  d0  // This is the mapping expected by the server.
        // called 'Korte zijde' in Model.js? -> Peter changed it to 'hoogte'
        // since there is no guarantee that this is the shortest side.
        rectangleStr += `&${objectTypeName}_even_length_${objIdx}=${tableEvenLength}`
        rectangleStr += `&number_of_${objectTypeName}s_${objIdx}=`
        if (objectTypeName === 'ForbiddenZones') {
            rectangleStr += '1'
        } else {
            rectangleStr += `${nObjects}`
        }
        return rectangleStr
    }

    exportReservationsToGetUrl() {
    /* e.g: reservationTypes:[
        {  name: 'groep van 2', groupSize : 2, number: 6 },
        {  name: 'groep van 5', groupSize : 5, number: 2 },
    ], */
        var str = ''
        let i = 1 // Changed from 0 to 1 so that it is in line with other encodings
        for (var reservationType of this.state.reservationTypes) {
            str += `&rgs_${i}=${reservationType.groupSize}`
            str += `&rgn_${i}=${reservationType.number}`
            i++
        }
        return str
    }

    exportThingsToGetUrl = (objectTypeName) => {
        const debug = false
        console.assert(['Objects', 'AllowedZones', 'ForbiddenZones'].includes(objectTypeName))

        var summedArea = (objectTypeName === 'AllowedZones') ? this.calcRoomRectangleArea() : 0.0

        var str = '';
        var objIdx = 1;  // The server side expects first table type to have index 1.
        var objSrcIndex = (objectTypeName === 'ForbiddenZones') ? objIdx-1 : objIdx
        var objTypes = (objectTypeName === 'Objects') ? this.state.tableTypes :
        ((objectTypeName === 'AllowedZones') ?  null : this.state.obstacleTypes)
        // this.state.roomTypes does not exist in UserGui. What exists is this.roomSize with an x and an y member.
        var cumulativeInstanceIdx = 1  // This starts at 1 for every separate objectTypeName for
        // server purposes. The GET message is to be constructed of keys of
        // type e.g.: ForbiddenZones_{row}_{col}_).
        // where: {row} = cumulativeInstanceIdx is the row nr in the DevGui.
        //        {col} = the name of the col in the DevGui.
        // So consequently, note that cumulativeInstanceIdx keeps incrementing
        // across different types if ForbiddenZones.
        var listName, nSides, x, y, d0, d1, nObjects, instanceIdx, rectangleStr;
        if (objectTypeName === 'AllowedZones') {
            // In the USerGui, only one room is assumed
            // and so this is not (like in the DevGui) done in a similar
            // way to the other  objectTypes, so we need specific code here.
            // We suppose there is only 1 allowed zone.
            listName = objectTypeName
            //CHECKIT: nSides = 4  // for now, non rectangular polygons are not supported.
            nSides = 4  // for now, non rectangular polygons as allowed zones are not supported.
            x = 0.0 // We suppose the zone determines the coordinate system.
            y = 0.0 // idem
            d0 = this.state.roomSize.y  // objType equals this.state.roomSize, this x, y nomenclature is quite confusing here.
            d1 = this.state.roomSize.x
            nObjects = 1
            instanceIdx = 0
            rectangleStr = this.exportSingleObjectToGetUrl( // note swapper order dy, dx
                objectTypeName, listName, x, y, nSides, d0, d1, nObjects, objIdx, cumulativeInstanceIdx)
            str += '&ViewingDir=' + this.state.viewingDirection
            str += '&ViewingTol=' + this.state.viewingTolerance
            str += rectangleStr
        } else {
            for (var objType of objTypes) {
                listName = objType.name
                if (debug) console.log(`listName = ${listName}`)
                nSides = 4  // Is the default value. See below for overwriting.
                // For now, non rectangular polygons are not supported for obstacles. They are for tables.
                var nInstancesOfThisType = 1  // default for any objectTypeName != 'ForbiddenZones'
                if (objectTypeName === 'ForbiddenZones') {
                    nInstancesOfThisType =
                        this.state.obstacleTypes[objSrcIndex].instances.length
                }

                for (instanceIdx=0; instanceIdx<nInstancesOfThisType; instanceIdx++) {
                    var position = (objectTypeName === 'ForbiddenZones') ?
                        this.state.obstacleTypes[objSrcIndex].instances[instanceIdx] : null
                    x = objectTypeName === 'ForbiddenZones' ? position.x : 0.0 //objType.x
                    y = objectTypeName === 'ForbiddenZones' ? position.y : 0.0 //objType.y
                    d0 = objType.height
                    d1 = objType.width
                    if (objectTypeName === 'Objects') {
                        nSides = objType.n_sides // only for Objects, we allow nSides!==4
                        // Note that it is a string, not a number.
                        if (debug) {
                            console.log(`typeof nSides = ${typeof nSides}`)
                            console.log(`nSides = ${nSides}`)
                        }
                        if (nSides !== 4) {
                            if (d0 == 0) {
                                d0 = d1
                            }
                        }
                    }
                    nObjects = (objType.number==='') ? 0 : objType.number // patch problem of '' only here
                    rectangleStr = this.exportSingleObjectToGetUrl(
                        objectTypeName, listName, x, y, nSides, d0, d1, nObjects, objIdx, cumulativeInstanceIdx)
                    // TODO: We'd better put the below code also in exportSingleObjectToGetUrl for logical
                    //   code location expectations.
                    // Add per table side size specification
                    var seatsStr = ''
                    if (objectTypeName === 'Objects') {
                        // meaning positionable object (in ZPO: tables) by the server algorithm
                        // Tables (Objects) are the only ones to have seats. AllowedZones and ForbiddenZones don't.
                        // Add per table seat specification.
                        const isRectangle = (nSides == 4) && (objType.height >= 0.1)
                        const isSeatRow = (nSides == 4) && (objType.height < 0.09) // safe testing for 0, == 0 goes wrong with float!
                        const isPolygon = (nSides != 4)
                        if (debug) {
                            console.log(`isRectangle: ${isRectangle}, isSeatRow: ${isSeatRow}, isPolygon: ${isPolygon}, `)
                            console.assert((isRectangle ? 1 : 0) + (isSeatRow ? 1 : 0) + (isPolygon ? 1 : 0) === 1)
                        }
                        for (var seatIdx=0; seatIdx<6; seatIdx++) {
                            var seatKeyStr = `seat_${objectTypeName}_side_${objIdx}_${seatIdx}`
                            if (debug) console.log(`seatKeyStr = ${seatKeyStr}`)
                            if (seatIdx <= 3) {
                                var sideSeatingIndication = 'N'
                                // since for rowOfSets we have already set the 4 seatsinSide? members to their
                                // right values at TableModal save button press.
                                // Not that we also set the numbers of seats at sides left, right, and below to 0.
                                if (seatIdx == 0) {
                                    sideSeatingIndication = isSeatRow ? 0 : objType.seatingSide0 // (objType.seatingSide0 >= 1) ? 'A' : 'N' // 'till can pass ints
                                } else if (seatIdx === 1) {
                                    sideSeatingIndication = objType.seatingSide1 // (objType.seatingSide1 >= 1) ? 'A' : 'N'
                                    // isSeatRow does not influence this one
                                } else if (seatIdx === 2) {
                                    sideSeatingIndication = isSeatRow ? 0 : objType.seatingSide2 // (objType.seatingSide2 >= 1) ? 'A' : 'N'
                                } else if (seatIdx === 3) {
                                    sideSeatingIndication = isSeatRow ? 0 : objType.seatingSide3 // (objType.seatingSide3 >= 1) ? 'A' : 'N'
                                }
                                if (isPolygon) {
                                    if (debug) console.log(`setting sideSeatingIndication to 1 because table isPolygon`)
                                    sideSeatingIndication = 1
                                }
                                //console.assert(['A', 'E', 'N'].includes(sideSeatingIndication))
                                if (debug) console.log(`adding part to str: ${sideSeatingIndication}`)
                                seatsStr += '&' + seatKeyStr + '=' + sideSeatingIndication.toString()
                            } else {
                                console.assert([4, 5].includes(seatIdx))
                                //var distanceIndication = (4===seatIdx) ? 0.3 : 0.6
                                // note that in this UserGui, we only indicate these two distances globally.
                                // while in the DevGui, we can define them per table type. okay?...
                                var distanceIndication =
                                    (seatIdx == 4) ? 0.3 : 0.6 //this.state.seatTableDistance_m : this.state.seatSeatDistance_m
                                if (seatIdx == 4) {
                                    if (isSeatRow) {
                                        // We want the line that the table is reduced to in the back of the person in the
                                        // seats i.o [in the belly as is the case with a normal table].
                                        // So the trick realising that graphically is just to use a distance from the
                                        // 'table' that is -0.3 i.o the usual 0.3.
                                        distanceIndication = -0.3
                                    }
                                }
                                seatsStr += '&' + seatKeyStr + '=' + distanceIndication
                            }
                        }
                        summedArea += this.calcTableTypeAreaTimesNumberOfOccurrences(nSides, objType, d0, d1, nObjects)
                    } else { // We ignore overlapping of forbidden zones. So the result is an overestimate.
                        console.assert(objectTypeName === 'ForbiddenZones')
                        // Note that we come here just once per ForbiddenZone type. So we do * 1 here (and not * nObjects)
                        const forbiddenZoneArea = (d0 * d1) * 1
                        if (debug) {
                            console.log(`nForbiddenZones = ${nObjects}`)
                            console.log(`outerRectangleWidth = ${d1}`)
                            console.log(`outerRectangleHeight = ${d0}`)
                            console.log(`one forbidden zone area = ${forbiddenZoneArea}`)
                        }
                        summedArea += forbiddenZoneArea
                        if (debug) console.log(`summedArea saldo = ${summedArea}`)
                    }

                    str += rectangleStr + seatsStr
                    // cumulativeInstanceIdx keeps incrementing across different types if ForbiddenZones:
                    cumulativeInstanceIdx += 1
                }
                objIdx++;
                objSrcIndex = (objectTypeName === 'ForbiddenZones') ? objIdx-1 : objIdx
            }
        }
        if (debug) {
            console.log(`exportThingsToGetUrl(${objectTypeName}) returned str = ${str}`)
            console.log(`summedArea.toFixed(0) = ${summedArea.toFixed(0)}`)
        }
        return [str, summedArea.toFixed(0)]
    }

    // Used!
    convertRegularPolygonSideToOuterCircleRadius(sideLength, nSidesInt) {
        const debug = false
        // From https://www.mathopenref.com/polygonradius.html
        // R = side / (2 sin(Pi/n)),   (a)
        // R = r / cos(Pi/ n),         (b)
        // where:
        //      side is the length of each side of the regular polygon
        //      R is the outer circle of the regular polygon
        //      r is the inner circle of the regular polygon
        const halfNthAngle = Math.PI / nSidesInt
        const s = Math.sin(halfNthAngle)
        const c = Math.cos(halfNthAngle)

        // Can sine be 0? Not if nSidesInt is within reason and we limit it in the interface to 16
        if (debug) {
            console.log(`typeof Math.PI = ${typeof Math.PI}`)
            console.log(`typeof nSidesInt = ${typeof nSidesInt}`)
            console.log(`halfNthAngle = ${halfNthAngle}`)
            console.log(`sin = ${s}`)
            console.log(`cos = ${c}`)
            console.assert(nSidesInt <= 16)
            console.assert(nSidesInt >= 3)
        }
        const R = sideLength / s / 2
        return R
    }

    // Not used yet. Do not delete!
    convertRegularPolygonSideToInnerCircleRadius(sideLength, nSidesInt) {
        const debug = false
        // From https://www.mathopenref.com/polygonradius.html,
        // R = side / (2 sin(Pi/n)),   (a)
        // R = r / cos(Pi/ n),         (b)
        // where:
        //      side is the length of each side of the regular polygon
        //      R is the outer circle of the regular polygon
        //      r is the inner circle of the regular polygon
        const halfNthAngle = Math.PI / nSidesInt
        const s = Math.sin(halfNthAngle)
        const c = Math.cos(halfNthAngle)
        // Can sine be 0? Not if nSidesInt is within reason and we limit it in the interface to 16
        if (debug) {
            console.log(`typeof Math.PI = ${typeof Math.PI}`)
            console.log(`typeof nSidesInt = ${typeof nSidesInt}`)
            console.log(`halfNthAngle = ${halfNthAngle}`)
            console.log(`sin = ${s}`)
            console.log(`cos = ${c}`)
            console.assert(nSidesInt <= 16)
            console.assert(nSidesInt >= 3)
        }
        const R = sideLength / s / 2
        // So, for the inner radius, we need to calculate r from side
        // So we equal (a) and (b) : side / (2 sin(Pi/n)) = r / cos(Pi/ n)
        //   =>  r = side * cos(Pi/ n) / (2 sin(Pi/n)) = side * cotan(Pi/ n) / 2
        const r = sideLength * c / s / 2 // this small r is the radius of the polygon's inner circle.
        return r
    }

    calcTableTypeAreaTimesNumberOfOccurrences(nSides, objType, d0, d1, nObjects) {
        const debug = false
        // Ah, for table we need to compose the outer rectangle to calculate the summedArea
        const nSidesInt = parseInt(nSides)
        const [nLeft, nAbove, nRight, nBelow] =
            [parseInt(objType.seatingSide0), parseInt(objType.seatingSide1),
            parseInt(objType.seatingSide2), parseInt(objType.seatingSide3)]

        // The default for rectangles is
        var nSidesAddingSeatWidth = ((nLeft >= 1) ? 1 : 0) + ((nRight >= 1) ? 1 : 0)
        var nSidesAddingSeatHeight = ((nAbove >= 1) ? 1 : 0) + ((nBelow >= 1) ? 1 : 0)
        var outerRectangleWidth  // undefined
        var outerRectangleHeight // undefined

        const seatDiameter = 0.6
        const coronaDiameter = 1.5  // = the distance one should keep form another non-bibble person.

        if (nSidesInt != 4) {
            if (debug) {
                console.log(`polygon: nSidesInt = ${nSidesInt}`)
                console.log(`d0 = ${d0}`)
                console.log(`d1 = ${d1}`)
                console.assert(d0 === d1)
            }
            // d0 and d1 should be equal and (independent of nSidesInt) represent the polygon side length.
            const R = this.convertRegularPolygonSideToOuterCircleRadius(d0, nSidesInt)
            // We will use this outer circle of the polygon Radius instead,
            // since the back end also uses that to position seats and it should be compatible.
            // This is also better, especially for triangle tables which then gives room leg.
            // This is also the reason the back end uses the outer polygon circle radius.
            if (debug) {
                console.log(`calculated outer radius of R = ${R} for a polygon side d0 = ${d0}`)
            }
            // Build a seat edging on each side of the polygons outer circle and take the
            // extreme points of this to construct an outer rectangle from. Then you have the dimensions
            // as min amx of x y of the extreme points of these.
            var [minX, maxX, minY, maxY] = [0, 0, 0, 0]
            var i
            const seatRadius = seatDiameter/2
            const coronaRadius = coronaDiameter/2
            const nthAngle = 2 * Math.PI / nSidesInt
            for (i = 0; i < nSidesInt; i++) {
                const xSeatCenter = (R + seatRadius) * Math.cos(i * nthAngle)
                const ySeatCenter = (R + seatRadius) * Math.sin(i * nthAngle)
                if (debug) {
                    console.log(`i = ${i}`)
                    console.log(`xSeatCenter = ${xSeatCenter}`)
                    console.log(`ySeatCenter = ${ySeatCenter}`)
                }
                minX = Math.min(minX, xSeatCenter - coronaRadius)
                maxX = Math.max(maxX, xSeatCenter + coronaRadius)
                minY = Math.min(minY, ySeatCenter - coronaRadius)
                maxY = Math.max(maxY, ySeatCenter + coronaRadius)
            }
            outerRectangleWidth = maxX - minX
            outerRectangleHeight = maxY - minY

        } else {
            // note that d0 is height, d1 is width
            const extraRoomForASeat = (seatDiameter + coronaDiameter)/2 // sum of both halves is clearer
            outerRectangleWidth = d1 + (nSidesAddingSeatWidth * extraRoomForASeat)
            outerRectangleHeight = d0 + (nSidesAddingSeatHeight * extraRoomForASeat)

            // could or could not be sticking out of table side.
            // We have to count it for the outer rectanlge, in any case:
            const rest = (coronaDiameter - seatDiameter)

            const minCoronaWidthAbove = nAbove * seatDiameter + rest
            const minCoronaWidthBelow = nBelow * seatDiameter + rest
            const coronaWidth = Math.max(minCoronaWidthAbove, minCoronaWidthBelow)

            const minCoronaHeightLeft = nLeft * seatDiameter + rest
            const minCoronaHeightRight = nRight * seatDiameter + rest
            const coronaHeight = Math.max(minCoronaHeightLeft, minCoronaHeightRight)

            // When a side of the table is smaller than the space occipied by peopl @ that side.
            // we need to increase it.
            outerRectangleWidth = Math.max(outerRectangleWidth, coronaWidth)
            outerRectangleHeight = Math.max(outerRectangleHeight, coronaHeight)

            if (debug) {
                console.log(`[nLeft, nAbove, nRight, nBelow] = [${nLeft}, ${nAbove}, ${nRight}, ${nBelow}]`)
                console.log(`nSidesAddingSeatWidth = ${nSidesAddingSeatWidth}`)
                console.log(`nSidesAddingSeatHeight = ${nSidesAddingSeatHeight}`)
            }
        }

        // for any polygon / rectangle
        // Note that we come here just once. So we do * nObjects here:
        const area = (outerRectangleHeight * outerRectangleWidth) * nObjects
        if (debug) {
            console.log(`nObjects = ${nObjects}`)
            console.log(`outerRectangleWidth = ${outerRectangleWidth}`)
            console.log(`outerRectangleHeight = ${outerRectangleHeight}`)
            console.log(`table area = ${area}`)
        }
        return area
    }

    exportObstacleType=(obstacleType) => {
        var obstacles = [];
        for(var position of obstacleType.instances) {
            var rectangle = this.toRectangle(obstacleType.width,obstacleType.height,position.x, position.y);
            obstacles.push(rectangle);
        }
        return obstacles;
    }

    exportObstacles=() => {
        var obstacles = [];
        for (var obstacleType of this.state.obstacleTypes){
            var sublist = this.exportObstacleType(obstacleType);
            obstacles = obstacles.concat(sublist);
        }
        return obstacles;
    }

    exportProblem = () => {
        let subthing = {}
        subthing["2020_NOV_RULES"] = {
                InterBubbleDistance_m:1.5,
                MaxBubbleSite:5,
                MaxPeopleInside:200,
                MaxPeopleOutside:400
        };
        let subthing2 = {}
        subthing2["2020_NOV_RULES"]={
                WidthForPassing_m:1,
                PersonCenterDistanceFromTable_m:1,
                AssumePeopleAtTableHeads:200,
        };
        let subthing3 ={}
        subthing3["Customer:ID=666,Name=FromDevGuiForm"] = {Objects: this.exportTables(),
            AllowedZones : this.toRectangle(this.state.roomSize.x, this.state.roomSize.y),
            ForbiddenZones: this.exportObstacles(),
            ConfirmedBubbleDistribution:{},
            ExpectedBubbleDistribution:{},
            CovidRules:subthing,
            SiteRules:subthing2,
          }
        return {
            PositioningProblem : subthing3
        }
    }

    setTooManyTableMessageIfExceedingThreshold(objectsArea, roomArea, obstaclesArea) {
        const debug = false
        if (debug) {
            console.log(`objectsArea = ${objectsArea}`)
            console.log(`roomArea = ${roomArea}`)
            console.log(`obstaclesArea = ${obstaclesArea}`)
        }

        const tooManyTablesAreaThreshold = 1.0
        const availableAreaForTables = (roomArea - obstaclesArea)
        const ratioOfInputPrecision2 = (objectsArea / availableAreaForTables).toFixed(2)

        if (debug) {
            console.log(`availableAreaForTables = ${availableAreaForTables}`)
            console.log(`ratioOfInputPrecision2 = ${ratioOfInputPrecision2}`)
        }
        const thresholdExceeded = ratioOfInputPrecision2 > tooManyTablesAreaThreshold
        var msg = '' // default = don't panic
        if (thresholdExceeded) {
            const percentExceededBy = ((ratioOfInputPrecision2 - 1) * 100).toFixed(0)
            msg += 'Let op: '
            const developerDebugMode = false
            if (developerDebugMode) {
                msg += 'De totale oppervlaktes in vierkante meter zijn: '
                msg += `[Ruimte: ${roomArea}, Obstakels:${obstaclesArea}, Tafels:${objectsArea}]`
            }
            msg += ` U hebt meer dan ${percentExceededBy}% tafeloppervlakte te veel `
            msg += ' ten opzichte van de vrije ruimte.'
            msg += ' Die extra tafels maken het niet onmogelijk, maar wel lastiger voor het algoritme '
            msg += 'om snel een zo groot mogelijke deelverzameling van tafels te vinden die in de vrije ruimte past.'
            msg += ' Probeer best met een set tafels waarvan u zelf denkt dat die in de ruimte met obstakels past.'
        } else {
            msg += 'Ter informatie: '
            msg += ` U hebt tafels gedefinieerd voor een totaal van ${100 * ratioOfInputPrecision2}% van de vrije ruimte.`
        }
        this.manyTablesWarning = msg
    }

    positioningProblemToGetUrl = (expectedResponseType, newConfigurationName='') => {
        const debug = false
        // For now we try this out with a fixed url, unrelated to the problem constructed in the objects here.
        // TODO: Construct the GetQuery from the actual objects in 'this'.
        // This works as an example of what string to build, but is UserGui-input independent.

        // Plan is to map this simple 'form/' prefix in Apache on FM VM server to 8080 (as exception to port 3000)
        // This works locally from peters laptop, calling local server on localhost 8080:

        // Synonyms:
        // Defined form/ as prefix in Django mapped on ObjPositioningAlgo/input_form/
        // localhost is of course equivalent to 127.0.0.1

        const baseUrl = this.getDjangoBackEndBaseUrl() + 'form/'

	    var s = ''
        // order of string addition should not matter.
        const [sObjects, objectsArea] = this.exportThingsToGetUrl('Objects')
        const [sRoom, roomArea] = this.exportThingsToGetUrl('AllowedZones')
        const [sObstacles, obstaclesArea] = this.exportThingsToGetUrl('ForbiddenZones')

        if (debug) {
            console.log(`sObjects = ${sObjects}`)
            console.log(`sRoom = ${sRoom}`)
            console.log(`sObstacles = ${sObstacles}`)
        }

        this.setTooManyTableMessageIfExceedingThreshold(objectsArea, roomArea, obstaclesArea)

        s += sObjects + sRoom + sObstacles
        s += this.exportReservationsToGetUrl()
        s += '&patience1_s=' + this.state.patience1_s
        s += '&patience2_s=' + this.state.patience2_s
        s += '&hundred_minus_mip_gap_percent=100'
        s += '&create_pdf=0'
        s += '&n_pixels_per_m=' + this.state.n_pixels_per_m
        s += '&gimme=' + expectedResponseType
        s += '&name=' + (newConfigurationName !== '' ? newConfigurationName : this.state.roomName)
        s = s.replace('&','?')  // replaces first occurrence only

        const fullUrl = baseUrl + this.removeDirtyCharsFromString(s) // mainly to replace [rppmname] by roomname
        return fullUrl
    }

    calcRoomRectangleArea() {
        const area = this.state.roomSize.y * this.state.roomSize.x
        return area
    }

    setRoundTableTypeCountsToZero() {
        var s = 'Elke rechthoekige tafel wordt nu omgezet in een stoelenrij'
        s += ' met een aantal stoelen gelijk aan het aantal stoelen aan de bovenkant van de tafel.\n'
        s += 'Ronde tafels zijn niet logisch omzetbaar in een stoelenrij. Daarom wordt hun aantal nu op nul gezet.'
        alert(s)
        for (var tableType of this.state.tableTypes) {
            if (tableType.n_sides != 4) {
                tableType.number = 0
            }
        }
    }

    getErrorMessageForFirstEmptyInputField() {
      var messageString = ''
      if (this.state.roomSize.x === '') {
        messageString = 'De breedte van de ruimte in meter moet een geheel getal tussen 1 en 999 zijn.'
      } else if (this.state.roomSize.y === '') {
        messageString = 'De lengte van de ruimte in meter moet een geheel getal tussen 1 en 999 zijn.'
      } else if (this.state.patience1_s === '') {
        messageString = 'De rekentijd in seconden, te besteden aan '
        messageString += 'het plaatsen van meer tafels, moet een geheel getal tussen 1 en 999 zijn.'
      } else if (this.state.patience2_s === '') {
        messageString = 'De rekentijd in seconden, te besteden aan '
        messageString += 'het realiseren van meer spreading, moet een geheel getal tussen 1 en 999 zijn.'
      }
      return messageString
    }

    // This function is called when a user presses the 'Start Optimisation' button.
    runTablePositioningAlgoSvg = () => {
        this.toggleResultModal(this.state.openResultModal)
      const messageString = this.getErrorMessageForFirstEmptyInputField()
      if (messageString !== '') {
        this.setState( {messageString: messageString } )
        return
      }

      this.setState({calculated:false, calculating:true})
      // somehow force button update

        var outputChoice = 'GetRequest'
        console.assert(['Dictionary', 'GetRequest'].includes(outputChoice))
        if (outputChoice === 'Dictionary') {
            //var obj2 = this.exportProblem() // This function returns the problem as a dictionary.
        } else {
            console.assert(outputChoice === 'GetRequest')
            var expectedResponseType = 'svg'
            console.assert(['svg', 'pdf'].includes(expectedResponseType))
            var getUrl = this.positioningProblemToGetUrl(expectedResponseType) // This function returns the problem as a get request url.
            this.sendDataToServer(getUrl, expectedResponseType) // Actually sends the http request
            this.setState({ loading: true })
            this.setState({ calculatedSvg: null})
            // In case that table placement has never been done,
            // already scroll up to text saying 'Plaatsing van tafels tussen obstakels komt hier.'
            // var svgElem = document.getElementById('svgString')
            // Reset to text 'waiting for svg' status.

            // svgElem.innerHTML = 'Uw berekende tafelplaatsing komt hier enkele seconden nadat u op "Plaats tafels" heeft gedrukt.'

            //svgElem.innerHTML  = '<LoadingMask loading={true} text={"loading..."}>  <div style={{ width: 500, height: 300 }}>Uw berekende tafelplaatsing komt hier enkele seconden nadat u op "Plaats tafels" heeft gedrukt.</div> </LoadingMask>'
            // @Ine: Try to add again as well:  <CircularProgress color="secondary" />
            // svgElem.scrollIntoView();
            // When the svg is shown, it can still be that only its top is visible.
            // So we will scroll down further up to the 'Download pdf' button to show the full svg,
            // and the pdf button (as feed forward that this is possible.

        }
        this.setState({calculated:true, calculating:false})
    }

    loggedInConditionalRunTablePositioningAlgoPdf() {
        if (this.canDownloadPdfWithoutLogin) {
            this.runTablePositioningAlgoPdf()
        } else {
            if (!this.userLoggedIn) {
                this.state.loginIoRegister = true
                this.handleUserForm()
            } else {
                this.runTablePositioningAlgoPdf()
            }
        }
    }

    // For now is a copy of PDF (ran into racing events trying to reuse code... TODO: ask Ine...
    runTablePositioningAlgoPdf = () => {
        this.setState({ downloading: true, downloaded: false})
        const messageString = this.getErrorMessageForFirstEmptyInputField()
        if (messageString !== '') {
            this.setState( {messageString: messageString } )
            return
        }

        // const changedHtml = 'Uw download wordt geconstrueerd ...'
        // this.renderRightNow('downloadString', changedHtml, 'text/html')

        var outputChoice = 'GetRequest'
        console.assert(['Dictionary', 'GetRequest'].includes(outputChoice))
        if (outputChoice === 'Dictionary') {
            //var obj2 = this.exportProblem() // This function returns the problem as a dictionary.
        } else {
            // this.showDownloadStateString(true)
            console.assert(outputChoice === 'GetRequest')
            var expectedResponseType = 'pdf'
            console.assert(['svg', 'pdf'].includes(expectedResponseType))
            var getUrl = this.positioningProblemToGetUrl(expectedResponseType) // This function returns the problem as a get request url.
            this.sendDataToServer(getUrl, expectedResponseType) // Actually sends the http request
        }
    }

    /* TODO: to be deleted when we keep generating PDFs on server side i.o. react side.
        This function gives a pdf with only text and no graphics, consider react version of Ine instead.
    downloadPdf = () => {
        // @Ine: It would be best of the button is not in disabled state
        // when this functionality is not available (i.e. when no optimisation was run yet.)
        // alert('Here, Peter will insert the pdf download functionality.')
        // It would be best of the button is not in disabled state.
        // when this functionality is not available (i.e. when no optimisation was run yet.)')

        // https://github.com/yWorks/svg2pdf.js/ seems easiest...
        //const doc = new jsPDF()
        //var pdf = new jsPDF('p', 'pt', [500, 600])
        var pdf = new jsPDF('p', 'pt', [1000, 1200])

        const element = document.getElementById('svgString').querySelector("svg")
        console.dir(element.innerHTML)  // for debugging only
        pdf.svg(element)
          .then(() => {
            // save the created pdf
            pdf.save('OptiSeats.FlandersMake.be.pdf')
          })
    }
    */

    // this function can be used to update contents of domElemIdString
    // towards newContentString, which is of format contentType in a forced *NOW!* way.
    // This works because we do a appendChild which will trigger the react event system for domElemIdString.
    renderRightNow(domElemIdString, newContentString, contentType) {
        var elem = document.getElementById(domElemIdString)
        var doc = new DOMParser().parseFromString(newContentString, contentType);
        elem.innerHTML = ''  // erase previous svg string put here
        elem.appendChild( // insert new  string here
          elem.ownerDocument.importNode(doc.documentElement, true)
        )
        const downloadElem = document.getElementById(domElemIdString)
        downloadElem.scrollIntoView();
    }

    extractValueByFieldFromUrl(url, wantedKey) {
        const debug = false
        if (debug) {
            console.log(`extractValueByFieldFromUrl(url = ${url}, wantedKey = ${wantedKey})`)
        }
        const list = url.split('?')
        if (list.length !== 2) {
            if (debug) {
                    console.log(`url not having a parameter list, returning NOT_FOUND.`)
                }
                return 'NOT_FOUND'
        } else {
            const paramsString = list[1]
            const paramsList = paramsString.split('&')
            if (debug) {
                console.log('paramsList')
                console.log(paramsList)
            }
            for (const paramCouple of paramsList) {
                const [urlKey, urlValue] = paramCouple.split('=')
                if (urlKey === wantedKey) {
                    if (debug) {
                        console.log(`found urlValue = ${urlValue}`)
                    }
                    return urlValue
                }
            }
            if (debug) {
                console.log(`wantedKey = ${wantedKey} not found in url parameter list, returning NOT_FOUND.`)
            }
            return 'NOT_FOUND'
        }
    }

    // https://malcoded.com/posts/react-http-requests-axios/#:~:text=Sending%20HTTP%20request%20from%20your,URL%20and%20send%20the%20request.
    sendDataToServer(getUrl, expectedResponseType) {
        const debug = false
        if (debug) {
            console.log(`sendDataToServer(expectedResponseType = ${expectedResponseType})`)
        }
        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest()
        if (expectedResponseType === 'pdf') {
            xhr.responseType = 'blob';  // from: https://www.alexhadik.com/writing/xhr-file-download/
        }

        if (expectedResponseType === "svg") xhr.responseType = 'json';

        // Install a callback when the server responds:
        xhr.addEventListener('load', () => {
            // Update the state of the component with the result here.
            if (debug) {
            //if (true // debug) { // overrule as first things to see
                console.log('Received answer from server. This should be of file type: ' + expectedResponseType + '.')
            }

            var responseBase = ''
            var responseExtra = ''
            var alertMessage = expectedResponseType // default, just to also
            // see expectedResponseType for svg and pdf if wanted
            var alertTime = 3
            var alertType = 'success'
            var treatedByNewAlert = true

            if (['svg', 'pdf'].includes(expectedResponseType)) {

                if (!this.state.openResultModal) {
                    // This means that the user does not (anymore) want to see any results (currently)
                    // So we let them go 'lost'.
                    return // NOTE: Early return!
                }

                if (false) {
                    console.log(`xhr.response = ${xhr.response}`)
                    console.log(`xhr.response stringify = ${JSON.stringify(xhr.response, null, 2)}`)
                }

                if (expectedResponseType === 'pdf') {
                    var blob = new Blob([xhr.response], {type: 'image/pdf'}); // i.o "application/pdf"
                    // Create a link element, hide it, direct
                    // it towards the blob, and then 'click' it programmatically:
                    let a = document.createElement("a");
                    a.style = "display: none";
                    document.body.appendChild(a);
                    // Create a DOMString representing the blob
                    // and point the link element towards it:
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = 'OptiSeats.Be.pdf';
                    // Programmatically click the link to trigger the download:
                    a.click();
                    // Release the reference to the file by revoking the Object URL:
                    window.URL.revokeObjectURL(url);
                    this.setState({ downloading: false, downloaded: true })
                    alertMessage = 'Uw ' + alertMessage + ' is gedownload.'
                } // else
                if (expectedResponseType === 'svg') {
                    const {svg, pdf, pdfRoomName} = xhr.response
                    this.setState({ loading: false })
                    const parsedData = new DOMParser().parseFromString(svg[1], 'application/xml').documentElement
                    this.setState({ base64PDF: pdf, pdfRoomName: pdfRoomName})
                    //console.log({svg: svg[1], parsedData})
                    this.setState({ calculatedSvg: parsedData})
                    alertMessage = 'Uw ' + alertMessage + ' is gevisualiseerd verderop in deze webpagina.'
                }
            } else {
                const responseFromServer = xhr.responseText
                if (debug) {
                    console.log(`Received: responseFromServer of type = ${responseFromServer}`)
                }
                alertMessage = responseFromServer
                if (responseFromServer.startsWith('OK:')) alertType = 'success'
                if (responseFromServer.startsWith('FOUT:')) alertType = 'danger'

                if (responseFromServer.includes('REFLECTED:')) {
                    if (debug) {
                        console.log('REFLECTED = true')
                        console.log(`expectedResponseType = ${expectedResponseType}`)
                    }
                    responseBase = responseFromServer.split('REFLECTED:')[0]
                    // if it exists, responseExtra is in json format
                    responseExtra = responseFromServer.split('REFLECTED:')[1]
                    alertMessage = responseBase // default, overruling the one above
                    // This alertMessage is used both by the old and the new alerts,
                    // when empty, no alert is shown to the user.
                    // alertMessage can be tuned per message type branch below if needed.
                    // defaults, each can be overruled below per message type branch below if required.
                }
                console.assert(
                    ['reg', 'login', 'load_reg', 'save_as_cfg', 'load_cfgs', 'del_cfg', 'logout'].includes(expectedResponseType))
                // Put user data in session here?
                if (['reg', 'login', 'logout'].includes(expectedResponseType)) {
                    const received_registered_and_logged_in_ok_response = responseFromServer.startsWith('OK:') &&
                        responseFromServer.includes('bent volledig geregistreerd.')
                    if (debug) {
                        console.log({responseFromServer})
                    }
                    const received_logged_in_ok_response = responseFromServer.startsWith('OK:') &&
                        responseFromServer.includes('bent aangemeld')
                    if (received_registered_and_logged_in_ok_response || received_logged_in_ok_response
                       ) {
                        // NOTE: relying on exact message string could be a bit unstable, but okay for now.
                        this.userLoggedIn = true
                        this.userEmail = this.extractValueByFieldFromUrl(responseFromServer, 'emailAddress')
                        //this.userPassword = this.extractValueByFieldFromUrl(responseFromServer, 'password')
                        this.userPassword = 'notSentAsNotUsed1' // so it passed form format validation checks
                        this.userCode = this.extractValueByFieldFromUrl(responseFromServer, 'code')
                        if (debug) {
                            console.log(`this.userEmail stored from subscribe = ${this.userEmail}`)
                            console.log('Set userLoggedIn to true, so will use buffered versions for credentials.')
                        }
                    } else {
                        const received_already_registered_error_response =
                            responseFromServer.startsWith('FOUT:') &&
                            responseFromServer.includes('is reeds geregistreerd')
                        console.log(`received_already_registered_error_response = ${received_already_registered_error_response}`)
                        if (received_already_registered_error_response) {
                            // alertType = 'danger' // already set above on the basis of 'FOUT:' string start
                        } else {
                            const commandType = this.extractValueByFieldFromUrl(responseFromServer, 'command')
                            console.assert(commandType === 'logout')
                        }
                    }
                } // else {
                if (expectedResponseType === 'save_as_cfg') {
                    // TODO: We also want to change to this new room:
                    const newlyDefinedRoomName = this.extractValueByFieldFromUrl(responseFromServer, 'room_name')
                    if (debug) console.log(`newlyDefinedRoomName = ${newlyDefinedRoomName}`)
                    this.switchRoomListToRoomName(newlyDefinedRoomName) // this does not seem to do anything...
                    this.setState({roomName: newlyDefinedRoomName}) // does this?...

                    if (false) { // did not do anything visible... so disabled
                        // trying trick to get an extra update of romo state in view
                        this.handleRoomSelectChange(this.getRoomOptionByName(newlyDefinedRoomName))
                    }
                    //this.sendLoadUserConfigurationsFromServerDatabase() //TOOBAD

                } // else {
                if (expectedResponseType === 'load_cfgs') {
                    // We catch the load_cfgs sent back result below, as we already do some
                    // preprocessing on the REFLECTED: sub part of responseFromServer we need for that.

                    this.addRoomsToGuiStateFromJsonString(responseExtra) // str is a json string here
                    // Here we do not need nor want to display that we received something to the user.
                    // Luckily so, because showing a messageString does give a render race condition.
                    alertMessage = '' // this happens 'under the hood', no need to send any message to the user
                } // else {
                if (expectedResponseType === 'del_cfg') {
                    // We assume it worked on server side
                    const oldDeletedRoomName = this.extractValueByFieldFromUrl(responseFromServer, 'room_name')

                    if (true) { // always seems to say did not delete, but deletion works,
                        // so it must be via the server delete and read back of load_cfgs
                        if (oldDeletedRoomName in this.roomDict) {
                            delete this.roomDict[oldDeletedRoomName] // also deleted locally (although at a new read we could also do it
                            if (debug) console.log(`deleted ${oldDeletedRoomName}`)
                        } else {
                            if (debug) console.log(`did not delete ${oldDeletedRoomName}`)
                        }
                    }
                    this.switchRoomListToRoomName(this.getEmptyRoomName())

                } // else {
                if (expectedResponseType === 'load_reg') {
                    if (debug) {
                        console.log(`responseExtra = ${JSON.stringify(responseExtra, null, 2)}`)
                    }
                    if (responseExtra !== '') { // This is needed for Safair and Firefox, but Chrome can handle
                        // JSON parsing of an empty string.
                        this.addUserRegistrationDataToGuiStateFromJsonString(responseExtra)
                        alertMessage = '' // this happens 'under the hood',
                        // so many times, so definitely no need/strongly avoid  to mention to user
                    } else {
                        //alertMessage = 'Uw email of code is niet correct.'
                        // The server already sends back the right error message.
                    }
                }
            }

            if (alertMessage !== '') {
                if (treatedByNewAlert) {
                    if (alertMessage.startsWith('OK:')) {
                        alertMessage = alertMessage.split('OK:')[1]
                    }
                    if (alertMessage.startsWith('FOUT:')) {
                        alertMessage = alertMessage.split('FOUT:')[1]
                    }
                    // Should be the only call to onShowAlert:
                    this.onShowAlert(alertTime, alertType, alertMessage)
                } else { // old message popup system
                    // The OK: and FOUT:  parts are processed in the treatment of messageString itself
                    this.setState( { messageString: alertMessage } )
                }
            }
        })

        //const getOrPostMethod = (['reg', 'login'].includes(expectedResponseType)) ? 'POST' : 'GET'
        // figure out the above in the weekend
        const getOrPostMethod = 'GET'

        // (1) For any method containing a password, we use POST i.o. GET, to avoid that passwords
        // are logged as urls of at client side browser history. Logged passwords would make the
        // syatem vunarable against attacks at client side, where Eve can access the client computer.
        // (2) We plan to move from http to https, so that (man in the middle) MITM-attacks along
        // client server communication can intercept plaintext passwords.
        xhr.open(getOrPostMethod, getUrl) // Constructs the request.
        // This initially gave a CORS (Cross-origin resource sharing) error
        // until I configured the server side configured to accept requests from other sites.
        // See:
        // http://www.srikanthtechnologies.com/blog/python/enable_cors_for_django.aspx or
        // https://stackoverflow.com/questions/35760943/how-can-i-enable-cors-on-django-rest-framework
        // on how this was fixed.

        if (debug) {
        // if (true || debug) { // first thing to overrule for debugging
            console.log('Sent request to server. Request type is ' + expectedResponseType + '.')
        }
        xhr.send() // Actually sends the request. We expect to receive the answer on it in the code
        // guarded by the 'xhr.addEventListener('load', () =>' above.
    }

    switchRoomListToRoomName(roomName) {
        const debug = false
        if (debug) {
            console.log(`switchRoomListToRoomName(${roomName})`)
            console.log(`check if room already in state`)
        }
        //this.setState({ room: roomName });
        this.setState({ roomName: roomName });
        this.handleRoomBySelectedNameChange(roomName, true)
    }

    addRoomsToGuiStateFromJsonString(json_str) {
        const debug = false
        if (debug) {
            console.log(`addRoomsToGuiStateFromJsonString(json_str) with rcv:from_server:json_str=`)
            console.log(json_str)
        }

        const dbStoredProblemDict = JSON.parse(json_str)
        this.userRoomDict = {}  // clearing the dict completely to start
        for (const [problemName, flatProblemDict] of Object.entries(dbStoredProblemDict)) {
            var structuredProblemDict = {}
            structuredProblemDict['roomName'] = problemName
            structuredProblemDict['viewingDirection'] = parseInt(flatProblemDict['ViewingDir'])
            structuredProblemDict['viewingTolerance'] = parseInt(flatProblemDict['ViewingTol'])

            if (debug) {
                console.log(`roomName: ${problemName}: read viewingDirection = ${structuredProblemDict['viewingDirection']}`)
                console.log(`roomName: ${problemName}: read viewingTolerance = ${structuredProblemDict['viewingTolerance']}`)
            }

            structuredProblemDict['patience1_s'] = parseInt(flatProblemDict['patience1_s'])
            structuredProblemDict['patience2_s'] = parseInt(flatProblemDict['patience2_s'])
            structuredProblemDict['roomSize'] = {}
            structuredProblemDict['roomSize']['x'] = parseFloat(flatProblemDict['AllowedZones_odd_length_1'])
            // width since counting from index 0 for left side, so 1 for top side size, which is width, is x
            structuredProblemDict['roomSize']['y'] = parseFloat(flatProblemDict['AllowedZones_even_length_1'])
            // height since counting from index 0 (even) clockwise for left side, is height, is y.

            structuredProblemDict['tableTypes'] = []
            structuredProblemDict['obstacleTypes'] = []
            structuredProblemDict['reservationTypes'] = []

            for (const zoneType of ['Object', 'ForbiddenZone', 'rgs']) {
                // rgs stands for reservation group size, but here, also stands for its 'name'
                var zoneArray = (zoneType === 'Object') ?
                    structuredProblemDict['tableTypes']
                    : ((zoneType === 'ForbiddenZone')
                        ? structuredProblemDict['obstacleTypes'] : structuredProblemDict['reservationTypes'])
                if (debug) {
                    console.log(`STARTED new zoneArray`)
                }
                var i = 1
                var zoneListName = (zoneType !== 'rgs') ? `${zoneType}s_list_name_${i}` : `rgs_${i}`
                if (debug) {
                    console.log(`Looking for key listName = ${zoneListName}`)
                }
                var prevTmpDict = {}
                while (zoneListName in flatProblemDict) {
                    if (debug) {
                        console.log(`Found key listName = ${zoneListName}`)
                    }
                    // 2 s-es (for historical reasons):
                    const numberOfInstances = parseInt(flatProblemDict[`number_of_${zoneType}ss_${i}`])
                    const zoneTypeTypeName = flatProblemDict[`${zoneType}s_list_name_${i}`]
                    var tmpDict = (zoneType !== 'rgs') ? { // rgs = reservation group size
                         'name': zoneTypeTypeName,
                         'width': parseFloat(flatProblemDict[`${zoneType}s_odd_length_${i}`]),
                         'height': parseFloat(flatProblemDict[`${zoneType}s_even_length_${i}`]),
                         'n_sides': parseInt(flatProblemDict[`n_${zoneType}s_sides_${i}`]),
                         'number': numberOfInstances,
                         'instances':[]
                    } : {}
                    if (debug) {
                        console.log(`STARTED new tmpDict = ${JSON.stringify(tmpDict, null, 2)}`)
                    }
                    if (zoneType === 'Object') {
                        tmpDict['seatingSide0'] = parseInt(flatProblemDict[`seat_${zoneType}s_side_${i}_0`])
                        tmpDict['seatingSide1'] = parseInt(flatProblemDict[`seat_${zoneType}s_side_${i}_1`])
                        tmpDict['seatingSide2'] = parseInt(flatProblemDict[`seat_${zoneType}s_side_${i}_2`])
                        tmpDict['seatingSide3'] = parseInt(flatProblemDict[`seat_${zoneType}s_side_${i}_3`])
                        tmpDict['seatingSide4'] = parseFloat(flatProblemDict[`seat_${zoneType}s_side_${i}_4`])
                        tmpDict['seatingSide5'] = parseFloat(flatProblemDict[`seat_${zoneType}s_side_${i}_5`])
                        zoneArray.push(tmpDict)
                    }
                    if (zoneType === 'ForbiddenZone') {
                        const x = parseFloat(flatProblemDict[`${zoneType}s_x_${i}`])
                        const y = parseFloat(flatProblemDict[`${zoneType}s_y_${i}`])
                        if (debug) {
                            console.log(`COMPARE: prevTmpDict['name'] = ${prevTmpDict['name']}`)
                            console.log(`COMPARE: zoneTypeTypeName = ${zoneTypeTypeName}`)
                        }
                        if (prevTmpDict['name'] === zoneTypeTypeName) { // unlinke Objects and rgs, we push at 1 level lower
                            // Then we flattened the struct and
                            // want the structure back by collecting the ForbiddenZones with the same type name
                            // in the same sub dict.
                            prevTmpDict.['instances'].push({'x': x, 'y': y}) // The rest of the data members we
                            prevTmpDict.['number'] += 1
                            // assume are the same:
                            if (debug) {
                                console.assert(tmpDict['width'] === prevTmpDict['width'])
                                console.assert(tmpDict['height'] === prevTmpDict['height'])
                                console.assert(tmpDict['n_sides'] === prevTmpDict['n_sides'])
                                // Exception: can indeed not always hold.
                                // console.assert(tmpDict['number'] === prevTmpDict['number'])
                            }
                            if (debug) {
                                console.log(`BRANCH1: Same forbidden obj name: so append to prevTmpDict = ${JSON.stringify(prevTmpDict, null, 2)}`)
                            }
                        } else {
                            tmpDict['instances'].push({'x': x, 'y': y})
                            if (debug) {
                                console.log(`MAIN: ForbiddenZone: pushed x,y pair onto tmpDict: ${JSON.stringify(tmpDict, null, 2)} onto zoneArray`)
                            }
                            zoneArray.push(tmpDict) // lile Objects and rgs
                            if (debug) {
                                console.log(`ForbiddenZone: pushed tmpDict: ${JSON.stringify(tmpDict, null, 2)} onto zoneArray`)
                                console.log(`zoneArray = ${JSON.stringify(zoneArray, null, 2)}`)
                                console.log(`BRANCH2: Different forbidden obj name: so created new tmpDict: ${JSON.stringify(tmpDict, null, 2)}`)
                                console.log(`and appended to zoneArray = ${JSON.stringify(zoneArray, null, 2)}`)
                            }
                            prevTmpDict = tmpDict
                        }
                    }
                    if (zoneType === 'rgs') {
                        const groupSize = parseInt(flatProblemDict[`rgs_${i}`])
                        tmpDict['name'] = `groep van ${groupSize}`
                        tmpDict['groupSize'] = groupSize
                        const groupNumber = parseInt(flatProblemDict[`rgn_${i}`])
                        tmpDict['number'] = groupNumber
                        zoneArray.push(tmpDict)
                    }
                    i += 1
                    zoneListName = (zoneType !== 'rgs') ? `${zoneType}s_list_name_${i}` : `rgs_${i}`
                    //prevTmpDict = tmpDict
                }
                if (debug) {
                    console.log(`Did not find key listName = ${zoneListName} anymore. So it is one beyond the last one.`)
                }
            }

            // TODO: Also store the rhs in DB, so that we can fill that one in here,
            // i.o. setting this to a fixed rhs:
            structuredProblemDict['standardTableType'] =  'rectangularTable'
            structuredProblemDict['standardTable'] =
                // 1 person chair
                {'name':'bubble1p', 'width':0.6, 'height':0, 'n_sides':4, 'number':1, 'instances':[],
                'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 0,
                'seatingSide4': 0.3, 'seatingSide5': 0.6
                }
            this.userRoomDict[problemName] = structuredProblemDict
        }

        if (debug) {
            console.log('Begin: total userRoomDict = ')
            console.log(`userRoomDict = ${JSON.stringify(this.userRoomDict, null, 2)}`)
            console.log('End: total userRoomDict.')
        }
    }

    addUserRegistrationDataToGuiStateFromJsonString(jsonUserRegData) {
        const debug = false

        console.assert(jsonUserRegData !== '')
        if (debug) {
            console.log(`addUserRegistrationDataToGuiStateFromJsonString`)
            console.log(`jsonUserRegData = ${jsonUserRegData}`)
        }
        const dict = JSON.parse(jsonUserRegData)
        this.setState({userData:
            {
                firstName : dict['FirstName'],
                lastName : dict['LastName'],
                businessName : dict['BusinessName'],
                sectorName : { value: dict['SectorName'], label: dict['SectorName']},
                phoneNumber : '+' + dict['PhoneNumber'],
                howCanWeHelp : dict['HowCanWeHelp'],
                sendMeNews : dict['SendMeNews'] === 1,
                inviteMe: dict['InviteMe'] === 1
            }
        })
        if (debug) {
            console.log(`this.state.userData = ${JSON.stringify(this.state.userData, null, 2)}`)
        }
    }

    askServerToSendRegistrationCompletedEmail(userEmail, userCode) {
        const debug = false
        var url = ''
        url += `?email2=${userEmail}`
        url += `&code=${userCode}`

        const fullGetUrl = this.getDjangoBackEndBaseUrl() + 'reg/' + url
        const expectedResponseType = 'reg'

        if (debug) {
            console.log(fullGetUrl)
        }
        this.sendDataToServer(fullGetUrl, expectedResponseType)
    }

    /* TODO: Try to implement the following idea.
    Originally written by Ine, to try and adapt the konvas component to the browser width/height/size.
    Not working yet, but would be useful to have...
    componentDidMount() {
        this.checkSize();
        // here we should add listener for "container" resize
        // take a look here https://developers.google.com/web/updates/2016/10/resizeobserver
        // for simplicity I will just listen window resize
        window.addEventListener("resize", this.checkSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkSize);
    }

    checkSize = () => {
        const width = this.container.offsetWidth;
        const height = this.container.offsetHeight;
        this.setState({
            windowWidth: width,
            windowHeight:height,
        });
    console.log(width)
    };
    */

    /*
    readFakeTables = () => {
        const json = '{"0": {"dx":5,"dy":5,"dz":0,"dzRad":1.57,"Placed":true}}'
        const object = JSON.parse(json)
        this.takeTables(data);
    }

    takeTables = o => {
        var counter = 0;
        var tableTypes = this.state.tableTypes;
        for (var tableType of tableTypes) {
            var instances = [];
            var nObjects = (tableType.number==='') ? 0 : tableType.number // patch problem of '' only here
            for (var i=0; i<nObjects; i++) {
                const coordinates = o[counter];
                counter++;
                if (coordinates.Placed) {
                    instances.push({x:coordinates.dx,y:coordinates.dy,rotation:coordinates.dzRad/Math.PI*180})
                }
            }
            tableType.instances = instances;
        }
        this.setState({tableTypes:tableTypes});
    }
    */

    toggleResultModal(openResultModal) {
        this.setState({openResultModal: !openResultModal});
    }

    handleUserFormAppendRequired() {
        this.mentionRequired = true
        this.handleUserForm()
    }

    handleUserFormWithoutRequired() {
        this.mentionRequired = false
        this.handleUserForm()
    }

    handleUserForm() {
        this.setState({userFormModal:true}); // TODO: Is this needed? or causing the double render() calls?...
    }

    handleSaveConfiguration() {
        const existingConfigurationName = this.state.roomName
        //console.log(`handleSaveConfiguration with current existingConfigurationName = ${existingConfigurationName}`)
        this.handleSaveConfigurationAsSubmit(existingConfigurationName)
    }

    toggleSaveConfigurationAsModalIfLoggedInWarnOtherwise = () => {
        if (!this.userLoggedIn) {
            const s = 'OK: U dient in te loggen voor deze functionaliteit'
            this.setState( {messageString: s})
        } else {
            this.toggleSaveConfigurationAsModal()
        }
    }

    toggleSaveConfigurationAsModalIfLoggedInPopupLoginDialogueOtherwise = () => {
        if (!this.userLoggedIn) {
            //this.mentionRequired = true; // this seems better! :)
            this.state.loginIoRegister = true
            this.handleUserFormAppendRequired()

            const nothingBetterYet = false // We could pass 'aanmelding vereist' ipv 'aandmelding' in the modal
            if (nothingBetterYet) {
                this.onShowAlert(3, 'warning', 'Voor deze functionaliteit moet u zich eerst aanmelden.')
                // Peter -> @Mats: this alert is immediately darkened out and overlayed by modal popup
                // Peter -> @Mats: Attempt scroll up to top, in order to see the alert.
                const domElemIdString = 'root'
                const topElem = document.getElementById(domElemIdString)
            }

        } else {
            this.toggleSaveConfigurationAsModal()
        }
    }

    toggleSaveConfigurationAsModal = () => {
        const debug = false
        if (debug) {
            console.log(`in toggleSaveConfigurationAsModal: this.state.saveConfigurationAsModal = ${this.state.saveConfigurationAsModal}`)
        }
        this.setState( { saveConfigurationAsModal : !this.state.saveConfigurationAsModal } );
    }

    toggleVerifyDeleteRoomModal = () => {
        this.setState( { verifyDeleteRoomModal : !this.state.verifyDeleteRoomModal } );
    }

    toggleVerifyDeleteProfileModal = () => {
        this.setState( { verifyDeleteProfileModal : !this.state.verifyDeleteProfileModal } );
    }
    // We use this method, for both save (to existing) as for save as (to new) configuration.
    // At the server side, we check if the record exists, in which case we leave the CreationDateTime untouched.
    // If the record is new, both CreationDateTime and ModifictionDateTime are set to now.
    handleSaveConfigurationAsSubmit(newConfigurationName) {
        const debug = false
        if (debug) {
            console.log(`handleSaveConfigurationAsSubmit(${newConfigurationName})`)
        }

        // We do nto want to wait for the server acknowledging that saving worked.
        // This seems to take too long, and anyway, there is no harm in already adapting the this.roomDictionary
        // already. We can save (all) later and maybe then it works. So keeping it local is better
        // than losing it.
        // TODO: ...
        const currentRoomName = this.state.roomName // or this.roomName? this.roomName to be deprecated soon.
        if (debug) console.log(`currentRoomName = ${currentRoomName}`)
        if (this.isARoom(newConfigurationName)) { // if the  name does already exist, refuse to handle save as request
            if (debug) console.log('==> isARoom')
            if (false) { // We now support saving to the same name, which just means updating is
                this.onShowAlert(5, 'error', 'Deze naam is reeds in gebruik voor een bestaande ruimte. Kies een andere naam of verwijder deze bestaande ruimte eerst.')
                return
            }
        } else {
            if (debug) console.log('==> isNotARoomYet')
        }

        // save locally
        if (currentRoomName !== newConfigurationName) {
            if (debug) console.log(`copying clientSideCopyOldRoomUnderNewName as currentRoomName = ${currentRoomName} !== newConfigurationName = ${newConfigurationName}`)
            this.clientSideCopyOldRoomUnderNewName(currentRoomName, newConfigurationName)
        } else {
            if (debug) console.log(`skipping clientSideCopyOldRoomUnderNewName as currentRoomName = ${currentRoomName} === newConfigurationName = ${newConfigurationName}`)
        }

        //this.toggleSaveConfigurationAsModal(); // TODO: Clearer if called here i.o. in child, but this somehow fails.
        var expectedResponseType = 'save_as_cfg'
        var getUrl = this.positioningProblemToGetUrl(expectedResponseType, newConfigurationName)


        this.pickUpUrlToServerAndUseItToAlsoLocallySaveInVisibleState(getUrl)

        // This function returns the problem as a get request url.
        getUrl += `&emailAddress=${this.userEmail}`
        getUrl += `&password=${this.userPassword}`
        getUrl += `&code=${this.userCode}`
        if (debug) {
            console.log(`  getUrl = ${getUrl}`)
        }
        this.sendDataToServer(getUrl, expectedResponseType) // Actually sends the http request to the back end server
        window.scrollTo(0, 0)
        // Since for save_as with a different name, we still see the old version of the right room
        if (currentRoomName !== newConfigurationName) { // SO for "save as" and not for "save"

            // doesn't update
            //this.setState({roomName: newConfigurationName}) // this.getRoomByName(newConfigurationName)

            /* doesn't update
            const doAdaptViewingDirectionIndication = true
            const roomDictValue = this.getRoomByName(newConfigurationName)
            this.updateAllStateAfterRoomNameChange(newConfigurationName, roomDictValue,
                doAdaptViewingDirectionIndication)
            */

            // last resort: send msg to server to update all cfgs.
            //this.sendLoadUserConfigurationsFromServerDatabase()

            // giving up on switching to new state
            //this.setState({roomName: currentRoomName})
        }
    }

    pickUpUrlToServerAndUseItToAlsoLocallySaveInVisibleState(getUrl) {
        const debug = false
        // SERIOUSLY: Also save this state already locally i
        // Yes, dirty trick to read present state (via url encording and decoding back) and enforce it
        const urlDict = urlToUrlDict(getUrl) // urlDict is the flattened dict, as stored in DB

        if (debug) {
            console.log(`urlDict = ${JSON.stringify(urlDict, null, 2)}`)
        }

        const roomDeltaY = urlDict.AllowedZones_even_length_1  // height, index 0 is even
        const roomDeltaX = urlDict.AllowedZones_odd_length_1  // width, index 1 is odd

        const doDecompress = true
        const urlDictDecompressed = doDecompress ?
            decompressUrlKeysIfItsInCompressedFormat(urlDict) : urlDict

        if (debug) {
            console.log(`urlDictDecompressed = ${JSON.stringify(urlDictDecompressed, null, 2)}`)
        } // ok reservations are present as:
        /*
          "rgs_1": "2",
          "rgn_1": "2",
          "rgs_2": "4",
          "rgn_2": "2",
          "rgs_3": "6",
          "rgn_3": "1",
        */
        this.loadUrlSpecifiedProblem(urlDictDecompressed, roomDeltaX, roomDeltaY)
    }


    clientSideCopyOldRoomUnderNewName(existingCurrentRoomName, newConfigurationName) {
        const debug = false
        var sourceRoom = this.getRoomByName(existingCurrentRoomName)

        const doReferenceIoCopy = false
        if (doReferenceIoCopy) { // This will make that chsanges in any of the rooms also changes the other room.
            // THis is likely not what the user wants.
            this.userRoomDict[newConfigurationName] = sourceRoom // this.userRoomDict[existingCurrentRoomName]
        } else {
            if (debug) console.log(`sourceRoom to copy = ${JSON.stringify(sourceRoom, null, 2)}`)
            // Make a real copy of the room that can evolve independently. This is likely what the user wants.
            var existingCurrentRoomNameCopy = JSON.parse(JSON.stringify(sourceRoom))
            existingCurrentRoomNameCopy.roomName = newConfigurationName // !!!
            this.userRoomDict[newConfigurationName] = existingCurrentRoomNameCopy
        }
    }

    handleDeleteConfiguration() {
        const debug = false
        if (!this.userLoggedIn) {
            this.state.loginIoRegister = true
            this.handleUserForm()
        } else {
            const expectedResponseType = 'del_cfg'
            var url = ''
            url += `?emailAddress=${this.userEmail}`
            url += `&code=${this.userCode}`
            url += `&name=${this.roomName}`
            const fullGetUrl = this.getDjangoBackEndBaseUrl() + expectedResponseType + '/' + url
            if (debug) console.log(fullGetUrl)
            this.sendDataToServer(fullGetUrl, expectedResponseType)
            this.toggleVerifyDeleteRoomModal()
        }
    }

    handleOrientation = event => {
        // Bloody hell! Now suddenly I need currentTarget i.o. target. Why?
        // https://stackoverflow.com/questions/55811892/event-target-id-returns-an-empty-string
        const debug = false
        if (debug) {
            console.log('event.currentTarget = [')
            console.log(event.currentTarget)
            console.log('event.currentTarget ] ')
        }
        const pressedViewingDirectionButtonName = event.currentTarget.id
        if (debug) console.log(`pressedViewingDirectionButtonName = ${pressedViewingDirectionButtonName}`)
        this.handleViewingDirectionChange(pressedViewingDirectionButtonName)
    }

    handleViewingDirectionChange(pressedViewingDirectionButtonName) {
        const buttonSuffixes = ['None', 'Right', 'Up', 'Left', 'Down']
        const dict = {'None': -1 , 'Right': 0, 'Up': 90, 'Left': 180, 'Down': 270 }
        for (var buttonSuffix of buttonSuffixes) {
            const buttonId = 'viewingDir' + buttonSuffix
            const btn = document.getElementById(buttonId)
            if (buttonId === pressedViewingDirectionButtonName) {
                btn.style.backgroundColor = "#0096A9";
                btn.style.color = "white";
                switch(pressedViewingDirectionButtonName){
                    case "viewingDirUp":
                        this.setState({roomOrientation: "Naar boven"})
                        break;
                    case "viewingDirRight":
                        this.setState({roomOrientation: "Naar rechts"})
                        break;
                    case "viewingDirLeft":
                        this.setState({roomOrientation: "Naar links"})
                        break;
                    case "viewingDirDown":
                        this.setState({roomOrientation: "Naar beneden"})
                        break;
                    case "viewingDirNone":
                        this.setState({roomOrientation: "Kijkrichting vrij"})
                        break;
                    default:
                        this.setState({roomOrientation: "Naar beneden"})
                }
                this.state.viewingDirection = dict[buttonSuffix]
            } else {
                btn.style.backgroundColor = "#E6E5E6";
                btn.style.color = "black";
            }
        }
    }

    updateViewingDirectionButtonColorFromState() {
        const debug = false
        const angle = this.state.viewingDirection
        const dict = {'-1': 'None' , '0': 'Right', '90': 'Up', '180': 'Left', '270': 'Down'}
        this.handleViewingDirectionChange(dict[angle])
        if (debug) {
            console.log('updateViewingDirectionButtonColorFromState done.')
        }
    }

    myOnLoad() {
        const debug = false
        if (debug) {
            console.log('in myOnLoad')
        }
        this.updateViewingDirectionButtonColorFromState()
    }

    getRoomOptionByName(roomName) {
        return { label: roomName, value: roomName }
    }

    calcSelectableRoomLabelValueList()  {
        var roomList = []
        if (this.userLoggedIn) {
            this.sendLoadUserConfigurationsFromServerDatabase()
            for (const [labelName, value] of Object.entries(this.userRoomDict)) {
                roomList.push({label:labelName, value: value.roomName})
            }
        }
        // We want these default rooms to be behind the user's own rooms in the drop down listbox.
        for (const [labelName, value] of Object.entries(factoryResetRoomDict)) {
            roomList.push({label:labelName, value: value.roomName})
        }
        return roomList
    }

    sendLoadUserConfigurationsFromServerDatabase() {
        const debug = false
        var url = ''
        url += `?load_cfgs=${this.userEmail}`
        url += `&code=${this.userCode}`

        const expectedResponseType = 'load_cfgs'
        const fullGetUrl = this.getDjangoBackEndBaseUrl() + expectedResponseType + '/' + url
        if (debug) {
            console.log(fullGetUrl)
        }

        this.sendDataToServer(fullGetUrl, expectedResponseType)
        // inside this.sendDataToServer,
        // the response as json will be caught and by this.addRoomsToGuiStateFromJsonString(json_str)
        // will then be parsed and transformed into this.userRoomDict.
        // this.userRoomDict will then be loaded into the room dropdown list.
    }

    sendLoadUserDataFromServerDatabase() {
        const debug = false
        const expectedResponseType = 'load_reg'
        var url = ''
        url += `?${expectedResponseType}=${this.userEmail}`
        url += `&code=${this.userCode}`
        const fullGetUrl = this.getDjangoBackEndBaseUrl() + 'reg/' + url
        if (debug) {
            console.log(fullGetUrl)
        }
        this.sendDataToServer(fullGetUrl, expectedResponseType)
    }

     isAFactoryResetRoom(roomName) {
        const debug = false
        const factoryResetRoomNames = Object.keys(this.factoryResetRoomDict)
        // TODO: add this pointer, for safety, don't rely on lexical context
        const result = factoryResetRoomNames.includes(roomName)
        if (debug) {
            console.log(`isAFactoryResetRoom(roomName=${roomName})`)
            console.log(`factoryResetRoomNames = ${JSON.stringify(factoryResetRoomNames, null, 2)}`)
            console.log(`result = ${result}`)
        }
        return result
    }

    isAUserRoom(roomName) {
        const debug = false
        const userRoomNames = Object.keys(this.userRoomDict)
        const result =  userRoomNames.includes(roomName)
        if (debug) {
            console.log(`isAUserRoom(roomName=${roomName})`)
            console.log(`userRoomNames = ${JSON.stringify(userRoomNames, null, 2)}`)
            console.log(`result = ${result}`)
        }
        return result
    }

    isARoom(roomName) {
        const debug = false
        if (debug) {
            console.log(`isARoom(roomName=${roomName})`)
        }
        const result = this.isAFactoryResetRoom(roomName) || this.isAUserRoom(roomName)
        if (debug) {
            console.log(`result = ${result}`)
        }
        return result
    }

    getRoomByName(roomName) {
        //console.log(`inside this.getRoomByName(roomName=${roomName})`)
        if (this.isAFactoryResetRoom(roomName)) {
            return this.factoryResetRoomDict[roomName]
        }
        if (this.isAUserRoom(roomName)) {
            return this.userRoomDict[roomName]
        }
        return null
    }

    updatemessageStringLeadingToAlert(messageString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            // triggers render and then a react window lit in bright white
            // that has no "server-name says", which is good
            // on darker table modal dialogue which is good
            // on even darker main app window, which is good
            // but first alert window is compressed inside table modal dialogue,
            // which is okay-ish.
            this.setState({ messageString: messageString} )
        } else {
            // says "server-name says"... which we want to avoid
            // suddenly gives other type of button than the rest of the site,
            // which we want to avoid.
            alert(messageString)
        }
    }

    mustShowWarning() {
        const debug = false
        const messageStringType = typeof this.state.messageString // to avoid undefined cases at startup
        const messageStringEmpty = this.state.messageString === ''
        const show = (messageStringType === 'string') && (!messageStringEmpty)

        if (debug) {
            console.log(`messageStringType = ${messageStringType}`)
            console.log(`messageStringEmpty = ${messageStringEmpty}`)
            console.log(`show = ${show}`)
        }

       return show
    }

    getDownloadButtonMessage() {
        var s = 'Plaats tafels en download het resultaat als pdf. ';
        s += 'In elke gedownloade pdf vindt u ook een klikbare link die u zal terugbrengen ';
        s += 'naar uw online ruimte configuratie. Zo kan u ook uw invoer off-line bijhouden. ';
        const extra = 'U dient zich eerst aan te melden voor gebruik van deze functionaliteit.';
        const msg = !this.userLoggedIn ? (s + extra) : s
        return msg
    }

    getCautionText() {
        var msg = ''
        if (this.state.roomOrientation !== "Kijkrichting vrij") {
            msg += this.getViewingDirectionWarning()
        }
        msg += this.manyTablesWarning
        return msg
    }

    getViewingDirectionWarning() {
        var msg = "Let op. U hebt een specifieke kijkrichting geselecteerd."
        if (false) {
            msg += " Tafels waarvan niet alle stoelen in de kijkrichting kunnen georiënteerd worden"
            msg += " zullen niet worden geplaatst."
        } else {
            msg += " Tafels met stoelen aan meer dan 1 tafelzijde kunnen dan sowieso niet worden geplaatst."
        }
        return msg
    }

    onShowAlert = (timeToClose, color, text)=>{
        // TODO: switching messageString to '' should disable any old alert popping up, but does not yet...
        //this.setState({alert: {color, text}, messageString: "", treatedByNewAlert: true})
        this.setState({alert: {color, text}})
        this.setState({visible:true},()=>{
          window.setTimeout(()=>{
            this.setState({visible:false})
          },(timeToClose * 1000))
        });
      }

    handleCollapse = (collapsedItem, isCollapsed) => {
        this.setState({[collapsedItem]: !isCollapsed})
    }

    downloadPdf = async () => {
        const linkSource = `data:application/pdf;base64,${this.state.base64PDF}`;
        const downloadLink = document.createElement("a");

        // possibly: components to add a time stamp in the pdf file.
        //const nlBEFormatter = new Intl.DateTimeFormat('nl-BE');
        //console.log(nlBEFormatter.format(firstValentineOfTheDecade));

        const fileName = `OptiSeats.be.${this.state.pdfRoomName}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    render() {
        const debug = false
        if (debug) {
            console.log('render() called')
        }
        const {
            calculatedSvg,
            visible,
            alert,
            room,
            openResultModal,
            collapseRoomForm,
            collapseObstacleForm,
            collapseTableForm,
            collapseReservationForm,
            roomOrientation,
            isEditTable,
            isEditObstacle,
            verifyDeleteRoomModal,
            verifyDeleteProfileModal,
            roomName
        } = this.state
        return (
        <>
        <Navigation
            handleLogin={this.handleUserFormWithoutRequired.bind(this)}
            handleLogout={this.handleUserLogout}
            handleRegistration={this.handleUserFormWithoutRequired.bind(this)}
            loggedIn={this.userLoggedIn}
            isLogginIn={this.handleIsLogginIn}
            handleRulesToggle={this.rulesToggle}
            handleHelpToggle={this.helpToggle}
        />
        <Container className="pt-2">
            <AlertWrapper text={alert.text} color={alert.color} visible={visible} />
        </Container>
        <Container
            className="main"
        >
            <ResultModal
                handleDownload={this.downloadPdf}
                toggle={this.toggleResultModal}
                isOpen={openResultModal}
                loadedSvg={calculatedSvg}
                pdf={this.state.base64PDF}
                pdfRoomName={this.state.pdfRoomName}
                selectedRoom={this.state.roomName}
                defaultRoom={this.getDefaultRoomName()}
                cautionText={this.getCautionText()}
            />
          <Row>
            <Col md={6} xl={4} className="left-column">
            <Tooltip
                title={<h6 style={{ color: "white" }}>
                    Definiëer deze ruimte als een rechthoek die aansluit rond uw echte ruimte
                    waar tafels en obstakels in geplaatst worden.
                    Als uw echte ruimte niet rechthoekig is, kan u
                    obstakels aan de randen plaatsen om uw echte ruimte nauwkeuriger te benaderen.
                    </h6>}
                    placement="bottom"
                >
                    <CollapseButton
                        clicked={this.handleCollapse}
                        collapseItem={"collapseRoomForm"}
                        isCollapsed={collapseRoomForm}
                        title={"Ruimte"}
                    />
            </Tooltip>

        <Collapse className="collapse-body" isOpen={!collapseRoomForm}>
            <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>

                <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                      <Label for="name">naam</Label>
                  </Col>
                <Col sm="7" md="7" lg="7">
                  <Select
                      //defaultValue={this.getRoomOptionByName(this.getDefaultRoomName())}
                      value={this.getRoomOptionByName(this.state.roomName)}
                      options={ this.calcSelectableRoomLabelValueList() }
                      isClearable={false}
                      onChange={e => this.handleRoomSelectChange(e)}
                      className="select-field"
                  />
                </Col>
            </Row>
            <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
                  <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                      <Label for="width">breedte</Label>
                  </Col>
                  <Col sm="7" md="7" lg="7">
                      <InputGroup className="input-field">
                        <Input
                        className="border-right-none"
                        type="number"
                        min={1}
                        max={99.9}
                        step="0.1"
                        name="width"
                        value={this.state.roomSize.x}
                        onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText className="input-text-addon">m</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                  </Col>
            </Row>
            <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
              <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                <Label for="height">
                  lengte
                </Label>

              </Col>
              <Col sm="7" md="7" lg="7">
              <InputGroup className="input-field">
                    <Input
                        className="border-right-none"
                        type="number" min={1} max={99.9}
                        step="0.1"
                        name="height"
                        value={this.state.roomSize.y}
                        onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText className="input-text-addon">m</InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>

            <Row className="mb-05"  style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
                <Col sm="5" md="5" lg="5" className="d-flex align-items-center">

                <Tooltip
                    title={<h6 style={{ color: "white" }}>
                        Sommige ruimtes vereisen dat alle personen aan alle tafels
                        in dezelfde richting kijken, bijvoorbeeld richting leerkracht.
                        U kan deze richting hier aangeven of integendeel alle kijkrichtingen toelaten.
                        </h6>}
                        placement="bottom"
                    >

                    <Label for="orientation">
                        kijkrichting
                    </Label>

                </Tooltip>

                </Col>
                <Col sm="7" md="7" lg="7">
                    <div className="select-field d-flex orientation-input justify-content-between align-items-center">
                        <OrientationButton
                            orientation="viewingDirLeft"
                            icon={<ArrowBackIcon />}
                            handleOrientation={this.handleOrientation}
                            text="Mensen in de ruimte moeten naar links kijken."
                        />
                        <OrientationButton
                            orientation="viewingDirUp"
                            icon={<ArrowUpwardIcon />}
                            handleOrientation={this.handleOrientation}
                            text="Mensen in de ruimte moeten naar achter kijken."
                        />
                        <OrientationButton
                            orientation="viewingDirNone"
                            icon={<AllOutIcon />}
                            handleOrientation={this.handleOrientation}
                            text="Mensen in de ruimte kunnen kijken in gelijk welke richting."
                        />
                        <OrientationButton
                            orientation="viewingDirDown"
                            icon={<ArrowDownwardIcon />}
                            handleOrientation={this.handleOrientation}
                            text="Mensen in de ruimte moeten naar voor kijken."
                        />
                        <OrientationButton
                            orientation="viewingDirRight"
                            icon={<ArrowForwardIcon />}
                            handleOrientation={this.handleOrientation}
                            text="Mensen in de ruimte moeten naar rechts kijken."
                        />
                    </div>
                </Col>

            </Row>
            <Row className="mb-0 label-row">
                <Col sm="5" md="5" lg="5" className="d-flex align-items-center" />
                <Col sm="7" md="7" lg="7" className="ml-2 select-field d-flex justify-content-center">
                    <p>{roomOrientation}</p>
                </Col>
            </Row>
        </Collapse>
        <hr/>
        <Tooltip
            title={<h6 style={{ color: "white" }}>
                Definiëer deze ruimte als een rechthoek die aansluit rond uw echte ruimte
                waar tafels en obstakels in geplaatst worden.
                Als uw echte ruimte niet rechthoekig is, kan u
                obstakels aan de randen plaatsen om uw echte ruimte nauwkeuriger te benaderen.
                </h6>}
                placement="bottom"
            >
        <CollapseButton
            clicked={this.handleCollapse}
            collapseItem={"collapseObstacleForm"}
            isCollapsed={collapseObstacleForm}
            title={"Obstakels"}
        />
        </Tooltip>
        <Collapse className="collapse-body" isOpen={!collapseObstacleForm}>
            <ObstacleList
                toggle={this.obstacleToggle}
                tables={this.state.obstacleTypes}
                handleEdit={this.editObstacle}
                handleDelete={this.deleteObstacle}
                handleCreate={this.createObstacle}
                handleAdd={this.addObstacle}
                handleRemove={this.removeObstacleOfType}
                updateNumber={this.updateObstacleNumber}
            />
        </Collapse>

        <hr/>
        <Tooltip
            title={<h6 style={{ color: "white" }}>
                Definiëer deze ruimte als een rechthoek die aansluit rond uw echte ruimte
                waar tafels en obstakels in geplaatst worden.
                Als uw echte ruimte niet rechthoekig is, kan u
                obstakels aan de randen plaatsen om uw echte ruimte nauwkeuriger te benaderen.
                </h6>}
                placement="bottom"
            >
        <CollapseButton
            clicked={this.handleCollapse}
            collapseItem={"collapseTableForm"}
            isCollapsed={collapseTableForm}
            title={"Tafeltypes en stoelenrijen"}
        />
        </Tooltip>
        <Collapse className="collapse-body" isOpen={!collapseTableForm}>

              <TableList
                toggle={this.tableToggle}
                tables={this.state.tableTypes}
                handleEdit={this.editTable}
                handleDelete={this.deleteTable}
                handleCreate={this.createTable}
                updateNumber={this.updateTableNumber}
              />
        </Collapse>

            <hr/>
        <CollapseButton
            clicked={this.handleCollapse}
            collapseItem={"collapseReservationForm"}
            isCollapsed={collapseReservationForm}
            title={"Reservaties"}
        />
        <Collapse className="collapse-body" isOpen={!collapseReservationForm}>

            <ReservationList
                toggle={this.reservationToggle}
                reservations={this.state.reservationTypes}
                handleEdit={this.editReservation}
                handleDelete={this.deleteReservation}
                handleCreate={this.createReservation}
                updateNumber={this.updateReservationNumber}
            />
        </Collapse>

            </Col>

            <Col>
              {/* Peter: note the 0px border, is much nicer without. */}
              <div
              ref={node => {
                this.container = node;
              }}
              >

              {/*  Begin new rekentijd */}
                { false && // Mats has put this more downwards...
                  <Row>
                    <Col sm="4" md="4" lg="4">
                      <Label for="title">berekeningvoorkeur</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      {/* This is the select from react-select */}
                      { <Select
                          value = { (typeof this.state.calculationPreference === 'undefined')
                            ? this.getCalculationPreferenceOptions()[0] // default is as fast as possible
                            : this.state.calculationPreference
                            }
                          options={ this.getCalculationPreferenceOptions() }
                          isClearable={false} // once set to non empty selection, one cannot select empty anymore
                          onChange={this.handleCalculationPreferenceChange.bind(this)}
                        />
                       }
                    </Col>
                  </Row>
              }
              {/*  Einde new rekentijd */}


              <KonvaCanvas
                obstacles = {this.generateObstacleInstances(this.state.obstacleTypes)}
                updateObstacles = {this.updateObstacles}
                width = {this.state.windowWidth*.95}
                height = {this.state.windowHeight}
                obstacleTypes = {this.state.obstacleTypes}
                updateObstacleTypes = {this.updateObstacleTypes}
                roomSize = {this.state.roomSize}
                tableTypes={this.state.tableTypes}
                updateTableTypes={f=>this.setState({tableTypes:f})}
                />

          </div>
        </Col>
            </Row>
            <Row className="footer">
                <Col md={6} xl={4} className="d-flex align-items-center pl-0">
                <StyledButton
                    className="save-button"
                    saveButton="true"
                    backgroundColor="#5F565E"
                    color="white"
                    opacity={this.isAFactoryResetRoom(this.state.roomName) && "0.5"}
                    disabled={this.isAFactoryResetRoom(this.state.roomName)}
                    onClick = {this.handleSaveConfiguration.bind(this)}
                >
                    Bewaar
                </StyledButton>
                <StyledButton
                    backgroundColor="#5F565E"
                    color="white"
                    className="save-as-button"
                    onClick = {
                        this.toggleSaveConfigurationAsModalIfLoggedInPopupLoginDialogueOtherwise.bind(this)
                    }
                >
                    Bewaar als...
                </StyledButton>
                <DeleteButton
                    handleDelete={this.toggleVerifyDeleteRoomModal}
                    classes={"delete-button" + (this.isAFactoryResetRoom(this.state.roomName) ? " delete-disabled" : "")}
                />
                </Col>


                <Col className="d-flex align-items-center justify-content-end pr-0">
                    <div>Rekentijd</div>
                    {/* This is the select from react-select */}
                    <Select
                        className="calculation-input"
                        menuPlacement="top"
                        value = { (typeof this.state.calculationPreference === 'undefined')
                        ? this.getCalculationPreferenceOptions()[0]
                        : this.state.calculationPreference }
                        options={ this.getCalculationPreferenceOptions() }
                        isClearable={false} // once set to non empty selection, one cannot select empty anymore
                        onChange={this.handleCalculationPreferenceChange.bind(this)}
                    />
                    <div>
                        <StyledButton
                            className="calculate-button"
                            backgroundColor="#0096A9"
                            color="white"
                            onClick = {this.runTablePositioningAlgoSvg}
                            disabled = {this.state.calculating}
                        >
                            Bereken plaatsing
                        </StyledButton>
                    </div>
                </Col>
            </Row>

    </Container>


    {this.state.helpModal ? (
        <HelpModal
            toggle={this.helpToggle}
            onSave={this.helpHandleSubmit}
            title= "help"
        />
        ) : null
    }

    {this.state.rulesModal ? (
        <RulesModal
            toggle={this.rulesToggle}
            onSave={this.rulesHandleSubmit}
            title= "maatregelen"
        />
        ) : null
    }

    {this.state.tableModal ? (
        <TableModal
            isEditTable={isEditTable}
            activeItem={ this.state.activeItem === -1 ? this.state.standardTable
                : this.state.tableTypes[this.state.activeItem]
            }
            tableType={this.state.activeItem === -1 ?
                this.state.standardTableType // new: 'rectangularTable', 'roundTable' or rowOfSeats
                : 'existing'} // entire activeItem (tabletype) is passed so derive in TableModal
            toggle={this.tableToggle}
            onSave={this.tableHandleSubmit}
            title= "tafelvorm of stoelenrij"
        />
        ) : null
    }

    {verifyDeleteRoomModal && (
        <BaseModal
            actionText="Verwijder"
            actionButtonColor="#BA4084"
            title={`Verwijder ${roomName}`}
            toggle={this.toggleVerifyDeleteRoomModal}
            handleSave={e => this.handleDeleteConfiguration(e)}
            body={
                <p className="verify-text">
                    Bent u zeker dat u deze configuratie wil verwijderen? Dit kan niet ongedaan worden gemaakt.
                </p>
            }
        />
        )
    }

    {verifyDeleteProfileModal && (
        <BaseModal
            actionText="Verwijder"
            actionButtonColor="#BA4084"
            title={'Verwijder Profiel'}
            toggle={this.toggleVerifyDeleteProfileModal}
            handleSave={e => this.handleDeleteProfile(e)}
            body={
                <p className="verify-text">
                    Bent u zeker dat u uw profiel en alle geassocierde data wil verwijderen? Dit kan niet ongedaan worden gemaakt.
                </p>
            }
        />
        )
    }


    {this.state.obstacleModal ? (
        <ObstacleModal
        isEditObstacle={isEditObstacle}
        activeItem={ this.state.activeItem === -1 ? this.state.standardObstacle
            : this.state.obstacleTypes[this.state.activeItem]
        }
        toggle={this.obstacleToggle}
        onSave={this.obstacleHandleSubmit}
        title= "obstakel"
        />
        ) : null
    }

    {this.state.reservationModal ? (
        <ReservationModal
        activeItem={ this.state.activeItem === -1
            ? JSON.parse(JSON.stringify(this.state.standardReservation))
            // This JSON indirect cloning code, avoids multiple reservation lines to refer to the same record.
            // However, this also means the standard(Reservation)Record does not get updated.
            // However, for reservationsRecord this is ok as behavior.
            : this.state.reservationTypes[this.state.activeItem]
        }
        toggle={this.reservationToggle}
        onSave={this.reservationHandleSubmit}
        title= "reservatie"
        />
        ) : null
    }

    {this.state.userFormModal ? (
        <UserFormModal
            toggle = {this.userFormToggle}
            onSave = {this.userFormHandleSubmit}
            onUnsubscribe = {this.toggleVerifyDeleteProfileModal}
            onResetCode = {this.userFormHandleResetCode}
            title = {this.state.loginIoRegister
                ? (!this.userLoggedIn ? ('aanmelding' + (this.mentionRequired ? ' vereist' : '')) : 'afmelding')
                : (!this.userLoggedIn ? 'registratie' : `aanpassing profiel: ${this.userEmail}`)}
            localLoginIoRegister = {this.state.loginIoRegister}

            bufferedUserLoggedIn = {this.userLoggedIn}
            bufferedUserEmail = {this.userEmail}
            bufferedUserPassword = {this.userPassword}
            bufferedUserCode = {this.userCode}
            bufferedUserData = {this.state.userData}
        />
        ) : null
    }

    {this.state.saveConfigurationAsModal && this.userLoggedIn &&
            <SaveConfigurationAsModal
                toggle = {this.toggleSaveConfigurationAsModalIfLoggedInPopupLoginDialogueOtherwise.bind(this)}
                onSave = {this.handleSaveConfigurationAsSubmit.bind(this)}
                title = 'configuratie opslaan als ...'
            />
    }
     {this.mustShowWarning() &&
        <Modal isOpen={true}>

          <ModalHeader>
              { (this.state.messageString.startsWith('FOUT:') ? 'Waarschuwing' : 'Mededeling')}
          </ModalHeader>

          <ModalBody>
            <Form>
                <Row>
                   {this.state.messageString.replace('OK:', '').replace('FOUT:', '')}
                </Row>
                </Form>
          </ModalBody>

          <ModalFooter>
            <StyledButton
                backgroundColor="grey"
                color="white"
                onClick={() => { this.setState( {messageString: '' } ) }  }
                >
                <SaveIcon /> {'ok'}
            </StyledButton>
          </ModalFooter>

        </Modal>
        }
    </>
  )
  }
}

export default App;
