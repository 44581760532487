import React from "react"
import Tooltip from "@material-ui/core/Tooltip";


export default function OrientationButton ({text, orientation, icon, handleOrientation}){
  return (
    <>
        <div id={orientation} className="orientation-button d-flex align-items-center justify-content-center" onClick={(e)=>(
          handleOrientation(e)
        )}>
            {icon}
        </div>
    </>
  )
}
