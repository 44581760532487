import styled from 'styled-components';

export const StyledButton = styled.button`
  color: ${props => props.color || "white"};
  opacity: ${props => props.opacity || "1"};
  font-weight: ${props => props.weigth || "400"};
  padding: 11px 23px;
  font-size: 16px;
  height: 2.5em;
  border: none;
  border-radius: 42px;
  cursor: pointer;
  line-height: 0rem;
  transition: 0.3s;
  background-color: ${props => props.backgroundColor || "lightgrey"};
  &:hover{
    opacity: ${props => props.opacity || "0.85"};
  }
`;
