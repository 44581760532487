import React, { Component } from "react";
import BaseModal from "./BaseModal"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';

// Looks good but there is no corresponding Width!:
import HeightIcon from '@material-ui/icons/Height';
// Never mind, this is enough. Width is just 'the other direction'. Should be clear.

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;

// Note that activeItem in this component in the TableModal component is an object,
// while in the App.j parent app that instantiates this TableModal in a TableList,
// activeItem refers to an index in that list.
// TODO: Naming should be changed to reflect and differentiate that better.

    export default class ObstacleModal extends Component {
      constructor(props) {
        super(props);

        const debug = true
        this.state = {
          activeItem: this.props.isEditObstacle ? this.props.activeItem : { ...this.props.activeItem, name: ""},
          errorString: '',
        };
        if (debug) {
            console.log(`ObstacleModal: props.activeItem = ${props.activeItem}`)
        }
      }

    validatePatternAndChangeIfOk = (e, pattern) => {
      const debug = false
      var val = e.target.value
      var name = e.target.name
      if (debug) {
          console.log(`val = ${val}`)
          console.log(`name = ${name}`)
          console.log(`pattern = ${pattern}`)
      }

      var re = null
      if (pattern === 'twoDotOneDigits') {
        re = /^[1-9]?\d(\.\d)?$/  // starting with 0 is not allowed
      } else if (pattern === 'identifier') {
        re = /^[_a-zA-Z-][_a-zA-Z0-9-]*$/   // numbers are forbidden,
        // since javascript then converts it to a number and we then cannot apply split function on
        // it for example, leading to errors. The philosophy is here to restrict data to sensible
        // range at the source,
        // rather then correcting things 'somewhere one cannot remember along the route, on the fly.
      } else {
        const errorString = `Pattern ${pattern} is not recognised. Contact developer team.`
        this.updateErrorStringLeadingToAlert(errorString)

      }
      if (debug) {
          console.log('re = ')
          console.log(re)
      }
      if (val === '' || re.test(val)) {
        // Note, that unfortunately, we must (temporarily) accept the empty string, because otherwise editing towards
        // something totally different is not possible. This means that we must catch the empty value when and where
        // we use it. That means, we should know the 'default id empty' value there.

        //var activeItem = this.state.activeItem // This gave overwrite problems,
        // leading to the same record being copied twice in the tableList and their name
        // being changed  together, as a consequence also being deleted together.

        if (['name', 'width', 'height'].includes(name)) {
            var activeItem = { ...this.state.activeItem, [name]: val };
            this.setState({activeItem})
        } else {
          if (debug) {
              console.log(`field name = ${name} is not recognised. Fix it.`)
          }
        }
        if (debug) {
            console.log(`test OK`)
        }
      } else {
        if (debug) {
            console.log(`test NOK`)
        }
      }
   }

    validateName() {
        var errorString = ''
        const ok = this.state.activeItem.name !== ''
        if (!ok) {
            errorString = 'De naam moet ingevuld worden.\nVerbeter dit eerst aub. Kies een unieke naam per obstakeltype.'
        }
        return errorString
    }

    validateWidth() {
        var errorString = ''
        const ok = this.state.activeItem.width !== ''
        if (!ok) {
            errorString = 'De breedte moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateHeight() {
        var errorString = ''
        const ok = this.state.activeItem.height !== ''
        if (!ok) {
            errorString = 'De lengte moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

  validateForm() { // at form submit, it is still possible tht some fields have a value ''
    var errorString = ''

    errorString = this.validateName()
    if (errorString !== '') {
        this.updateErrorStringLeadingToAlert(errorString)
    } else {
        errorString = this.validateWidth()
        if (errorString !== '') {
            this.updateErrorStringLeadingToAlert(errorString)
        } else {
            errorString = this.validateHeight()
            if (errorString !== '') {
                this.updateErrorStringLeadingToAlert(errorString)
            }
        }
    }

    if (errorString === '') {
      this.props.onSave(this.state.activeItem)
   }
  }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            // triggers render and then a react window lit in bright white
            // that has no "server-name says", which is good
            // on darker table modal dialogue which is good
            // on even darker main app window, which is good
            // but first alert window is compressed inside table modal dialogue,
            // which is okay-ish.
            this.setState({ errorString: errorString} )
        } else {
            // says "server-name says"... which we want to avoid
            // suddenly gives other type of button than the rest of the site,
            // which we want to avoid.
            alert(errorString)
        }
    }

    mustShowWarning() {
       const debug = false
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       if (debug) {
           console.log(`errorStringType = ${errorStringType}`)
           console.log(`errorStringEmpty = ${errorStringEmpty}`)
           console.log(`show = ${show}`)
       }
       return show
    }

      render() {
        const { toggle } = this.props;
        return (
          <>
        <BaseModal
          actionText="Bewaar"
          actionButtonColor="#0096A9"
          title={"Obstakel"}
          toggle={toggle}
          handleSave={() => this.validateForm()}
          body={
            <Form className="modal-form">
            <Row className="modal-input-row">
              <Col sm="4" md="4" lg="4" className="p-0">
                <Label for="title">Naam</Label>
              </Col>
              <Col sm="8" md="8" lg="8">
                <Input
                  type="text"
                  name="name"
                  className="select-field"
                  value={this.state.activeItem.name}
                  onChange={e => this.validatePatternAndChangeIfOk(e, 'identifier')}
                  placeholder="Bijv. Toog"
                />
              </Col>
            </Row>

            <Row className="modal-input-row">
              <Col sm="4" md="4" lg="4" className="p-0">
                <Label for="width">Breedte</Label>
              </Col>
              <Col sm="8" md="8" lg="8">
                <InputGroup className="input-field">
                  <Input
                    name="width"
                    type="number"
                    min="0.1"
                    step="0.1"
                    max="50.0"
                    className="input-field border-right-none"
                    value={this.state.activeItem.width}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                  />
                  <InputGroupAddon addonType="append">
                      <InputGroupText className="input-text-addon">m</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4" md="4" lg="4" className="p-0">
                <Label for="height">Lengte</Label>
              </Col>
              <Col sm="8" md="8" lg="8">
                <InputGroup className="input-field">
                  <Input
                    name="height"
                    type="number"
                    min="0.1"
                    step="0.1"
                    max="50.0"
                    className="input-field border-right-none"
                    value={this.state.activeItem.height}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                  />
                  <InputGroupAddon addonType="append">
                      <InputGroupText className="input-text-addon">m</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
        </Form>
          }
        />

        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}> waarschuwing </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString}
                    </Row>
                    </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    <SaveIcon /> {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

      </>
        );
      }
    }
