import React from "react"
import { Alert } from "reactstrap"

export default function AlertWrapper ({color, style, classes, text, visible}){
  return (
    <>
      <Alert className={classes} color={color} style={style} isOpen={visible}>
        { text }
      </ Alert>
    </>
  )
}
