import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import Select from 'react-select'

import BaseModal from "./BaseModal"
import HeightIcon from '@material-ui/icons/Height'; // Looks good but there is no corresponding Width!...
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';

import { Button, ButtonGroup } from '@material-ui/core';

import Crop169Icon from '@material-ui/icons/Crop169';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LinearScaleIcon from '@material-ui/icons/LinearScale';

import Tooltip from "@material-ui/core/Tooltip";
    const TableButton = styled.button`
      background-color: #99d3e0; // DeepBlueSky;
      color: black;
      font-size: 16px;
      padding: 5px 5px;
      border-radius: 20px;
      margin: 5px 0px;
      cursor: pointer;
    `;

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;


// Note that activeItem in this component in the TableModal component is an object,
// while in the App.j parent app that instantiates this TableModal in a TableList,
// activeItem refers to an index in that list.
// TODO: Naming should be changed to reflect and differentiate that better.

// There is a bit of a tricky encoding of three types of tables as they appearing in the
// modal dialogue: rectangularTable, roundTable, rowOfSeats.
// The records activeItem, also as they appear in the RoomInstances (easy to see examples there),
// contain the fields n_sides and height.
// For rectangularTable: n_sides = 4, and height>=0.1.
// For rowOfSeats: n_sides = 4, and height=0.
// For roundTable: n_sides != 4. (So n_sides ==3 or an integer >= 5.)
// As such they are disjunct classes of tables, and from the two fields n_sides and height
// they can be classified easily. So we do not need to store an extra
// tableType rectangularTable, roundTable, rowOfSeats in each instance.

export default class TableModal extends Component {
  constructor(props) {
    super(props);
    const debug = false
    if (debug) {
        console.log(`TableModal.props = ${JSON.stringify(props, null, 2)}`)
        console.log(`props.activeItem = ${props.activeItem}`)
        console.log(`props.tableType = ${props.tableType}`)
    }
    this.state = {
      selectOptions:[
        {value: 'rectangularTable', label: 'Rechthoekige tafel'},
        {value: 'roundTable', label: 'Ronde tafel'},
        {value: 'rowOfSeats', label: 'Rij van stoelen'},
      ],
      defaultTableType: this.getDefaultTableType(this.props.activeItem, this.props.isEditTable),
      activeItem: this.props.isEditTable ? this.props.activeItem : { ...this.props.activeItem, name: ""},
      tableType: (this.props.tableType === 'existing') ?
        this.deriveTableTypeNameFromActiveItem(this.props.activeItem) : this.props.tableType,
      //standardPolygonNSides: this.props.standardPolygonNSides
      errorString: ''
    };
    if (debug) {
      //console.log(this.props.activeItem)
      console.log(`this.state.activeItem = ${this.state.activeItem}`)
      console.log(`this.state.tableType = ${this.state.tableType}`)
      //console.log(`this.state.standardPolygonNSides = ${this.standardPolygonNSides}`)
      console.log(`this.state.tableType = ${this.state.tableType}`)
    }
  }

    getDefaultTableType(activeItem, isEdit) {
    const debug = false
      if (debug) {
        if(isEdit) console.log(activeItem)
      }
      if(activeItem.n_sides != 4) return {value: 'roundTable', label: 'Ronde tafel'}
      if(activeItem.height == 0) return {value: 'rowOfSeats', label: 'Rij van stoelen'}
      return {value: 'rectangularTable', label: 'Rechthoekige tafel'}
    }

    getErrorStringSeatsToSideSizeInfluence(
        zijdeNaam, sideLength, newDesiredNSeats, maxNSeats) {

        const isOne = (newDesiredNSeats===1) || (newDesiredNSeats==='1')
        var s = `WAARSCHUWING: De huidige object ${zijdeNaam} van ${sideLength}m biedt `
        s += `niet genoeg plaats voor `
        s += !isOne ? `${newDesiredNSeats} 60cm brede stoelen.` : `1 60 cm brede stoel.`
        s += ` We beperken daarom het aantal stoelen tot ${maxNSeats}. `
        s += `Indien u toch meer stoelen wil plaatsen, vergroot dan zelf eerst de object ${zijdeNaam}.`
        return s
    }

    getErrorStringSideSizeToSeatsInfluence(
        zijdeNaam, currentSideLength, newDesiredSideLength, currentNSeatingsOnASide, maxNSeats) {

        const isOne = (currentNSeatingsOnASide===1) || (currentNSeatingsOnASide==='1')
        var s = `WAARSCHUWING: De gewenste object ${zijdeNaam} van ${newDesiredSideLength}m biedt`
        s += ` niet genoeg plaats voor `
        s += !isOne ? `${currentNSeatingsOnASide} 60 cm brede stoelen.` : `1 60 cm brede stoel.`
        s += ` We behouden daarom de object ${zijdeNaam} van ${currentSideLength}m. `
        s += `Indien u deze toch wil reduceren, verklein dan eerst het aantal stoelen `
        s += `in elke ${zijdeNaam} richting naar een aantal kleiner dan ${currentNSeatingsOnASide}.`
        return s
    }

    // The idea here, is that only if we pass the pattern test,
    // do we update the field to the value entered.
    validatePatternAndChangeIfOk = (e, pattern) => {
      const debug = false
      var val = e.target.value
      var name = e.target.name
      if (debug) {
          console.log(`val = ${val}`)
          console.log(`name = ${name}`)
          console.log(`pattern = ${pattern}`)
      }
      var re = null
      if (pattern === 'integerTwoDigits') {
        re = /^[1-9]?\d$/
      } else if (pattern === 'twoDotOneDigits') {
        re = /^[1-9]?\d(\.\d)?$/  // starting with 0 is not allowed
      } else if (pattern === 'identifier') {
        re = /^[_a-zA-Z-][_a-zA-Z0-9-]*$/   // numbers are forbidden,
        // since javascript then converts it to a number and we then cannot apply split function on
        // it for example, leading to errors. The philosophy is here to restrict data to sensible
        // range at the source,
        // rather then correcting things 'somewhere one cannot remember along the route, on the fly.
      } else {
        const errorString = `Pattern ${pattern} is not recognised. Contact developer team.`
        this.updateErrorStringLeadingToAlert(errorString)
      }
      if (debug) {
          console.log('re = ')
          console.log(re)
      }
      if (val === '' || re.test(val)) {
        // Note, that unfortunately, we must (temporarily) accept the empty string, because otherwise editing towards
        // something totally different is not possible. This means that we must catch the empty value when and where
        // we use it. That means, we should know the 'default id empty' value there.

        //var activeItem = this.state.activeItem // This gave overwrite problems,
        // leading to the same record being copied twice in the tableList and their name
        // being changed  together, as a consequence also being deleted together.

        var zijdeNaam = ''
        var sideLength = -1
        if (['name', 'n_sides', 'width', 'height',
            'seatingSide0', 'seatingSide1', 'seatingSide2', 'seatingSide3'].includes(name)) {
            if (['seatingSide0', 'seatingSide1', 'seatingSide2', 'seatingSide3'].includes(name)) {
                if (['seatingSide0', 'seatingSide2'].includes(name)) {
                    sideLength = this.state.activeItem.height
                    zijdeNaam = 'lengte'
                } else if (['seatingSide1', 'seatingSide3'].includes(name)) {
                    sideLength = this.state.activeItem.width
                    zijdeNaam = 'breedte'
                }
                const minSeatWidthNeeded = 0.6
                const maxNSeats = Math.trunc(sideLength / minSeatWidthNeeded)
                if (sideLength !== -1) {
                    if (val > maxNSeats) { // clip value
                        //const errorString = `WAARSCHUWING: Het aantal stoelen wordt beperkt van het gewenste aantal ${val} naar ${maxNSeats} om stoeloverlap te vermijden.`
                        const es = this.getErrorStringSeatsToSideSizeInfluence(
                            zijdeNaam, sideLength, val, maxNSeats)
                        this.updateErrorStringLeadingToAlert(es)
                        val = maxNSeats // TODO: check & test NEW
                        // NEW: return // do not adapt anything, make user responsible for consistency
                    } // else allow change
                }
            } else if (name === 'n_sides') {
                const polygonMaxNSides = 16
                if (val > polygonMaxNSides) { // clip value
                    const errorString = `WAARSCHUWING: Het aantal zijden van de polygoonvormige tafel wordt beperkt van het gewenste ${val} naar ${polygonMaxNSides}.`
                    this.updateErrorStringLeadingToAlert(errorString)
                    val = polygonMaxNSides
                }
            } else if (name === 'width') {
                zijdeNaam = 'breedte'
                sideLength = this.state.activeItem.width

                if (this.state.activeItem.n_sides !== 4) { // so we are talking about a polygon here
                    this.state.activeItem.height = val // also set the height to the same value as width
                } else { // possible preventing change:
                    const maxSeatsAlongWidth = Math.trunc(val / 0.6)
                    if (this.state.activeItem.seatingSide1 > maxSeatsAlongWidth) {
                        const es = this.getErrorStringSideSizeToSeatsInfluence(
                            zijdeNaam, sideLength, val, this.state.activeItem.seatingSide1, maxSeatsAlongWidth)
                        this.updateErrorStringLeadingToAlert(es)
                        return // do not adapt anything, make user responsible for consistency
                    } // else allow change
                    if (this.state.activeItem.seatingSide3 > maxSeatsAlongWidth) {
                        const es = this.getErrorStringSideSizeToSeatsInfluence(
                            zijdeNaam, sideLength, val, this.state.activeItem.seatingSide3, maxSeatsAlongWidth)
                        this.updateErrorStringLeadingToAlert(es)
                        return // do not adapt anything, make user responsible for consistency
                    } // else allow change
                }
            } else if (name === 'height') {
                zijdeNaam = 'lengte'
                sideLength = this.state.activeItem.height

                if (this.state.activeItem.n_sides == 4) { // clipping other field
                    const maxSeatsAlongHeight = Math.trunc(val / 0.6)
                    if (this.state.activeItem.seatingSide0 > maxSeatsAlongHeight) {
                        const es = this.getErrorStringSideSizeToSeatsInfluence(
                            zijdeNaam, sideLength, val, this.state.activeItem.seatingSide0, maxSeatsAlongHeight)
                        this.updateErrorStringLeadingToAlert(es)
                        return // do not adapt anything, make user responsible for consistency
                    } // else allow change
                    if (this.state.activeItem.seatingSide2 > maxSeatsAlongHeight) {
                        const es = this.getErrorStringSideSizeToSeatsInfluence(
                            zijdeNaam, sideLength, val, this.state.activeItem.seatingSide2, maxSeatsAlongHeight)
                        this.updateErrorStringLeadingToAlert(es)
                        return // do not adapt anything, make user responsible for consistency
                    } // else sallow change
                }
            }
            var activeItem = { ...this.state.activeItem, [name]: val };
            this.setState({activeItem})
        } else {
          if (debug) {
              console.log(`field name = ${name} is not recognised. Fix it.`)
          }
        }
        if (debug) {
            console.log(`test OK`)
        }
      } else {
        if (debug) {
            console.log(`test NOK`)
        }
      }
   }

    handleTableTypeChange = event => {
        // Bloody hell! Now suddenly I need currentTarget i.o. target. Why?
        // https://stackoverflow.com/questions/55811892/event-target-id-returns-an-empty-string
        const debug = false
        if (debug) {
            console.log('event.currentTarget = [')
            console.log(event.currentTarget)
            console.log('event.currentTarget ] ')
        }
        const pressedTableTypeButtonName = event.value
        if (debug) {
            console.log(`pressedTableTypeButtonName = ${pressedTableTypeButtonName}`)
        }
        this.handleTableTypeChangeByButtonName(pressedTableTypeButtonName)
    }


    deriveTableTypeNameFromActiveItem(activeItem) {
        return this.deriveTableTypeNameFromFields(activeItem.n_sides, activeItem.height)
    }

    deriveTableTypeNameFromFields(n_sides, height) {
        const debug = false
        if (debug) {
            console.log(`n_sides, height = ${n_sides}, ${height}`)
        }
        var tableTypeName = 'roundTable'
        if (n_sides == 4) {
            if (height == 0) {
                tableTypeName = 'rowOfSeats'
            } else {
                tableTypeName = 'rectangularTable'
            }
        }
        return tableTypeName
    }

    handleTableTypeChangeByButtonName(pressedTableTypeButtonName) {
        const debug = false
        const buttonIds = ['rectangularTable', 'roundTable', 'rowOfSeats']
        for (var buttonId of buttonIds) {
            const btn = document.getElementById(buttonId)
            if (buttonId === pressedTableTypeButtonName) {
                this.setState({tableType: buttonId}) // we want a dynamic adaptation of the dialogue now
                if (debug) {
                    console.log(`set tableType to buttonId = ${buttonId}`)
                }
                var activeItem = this.state.activeItem
                //ai.objType = buttonId // We store the field objType, so that only at save of this dialogue
                // we know what postprocessing to do.
                if (['rectangularTable', 'rowOfSeats'].includes(buttonId)) {
                    // We misuse the 4 also for rowOfSeeds,
                    // to avoid to have too many (or too late) cases too handle towards the algo.
                    activeItem.n_sides = 4
                    if (buttonId === 'rectangularTable') {
                        //activeItem.height = '0.8' // TODO: This must be replaced with last selected user value,
                        // so still have to store that value globally. Height >= 0.1 here.
                        activeItem.height = 0.6 // to avoid 0 value from row of seats.
                        const maxSeatsAlongHeight = Math.max(activeItem.seatingSide0, activeItem.seatingSide0)
                        if (activeItem.seatingSide0 >= 1) {
                            activeItem.height = maxSeatsAlongHeight * 0.6
                        }
                    } else {
                        activeItem.height = 0 // This by itself indicates a rowOfSeats.
                    }
                } else {
                    console.assert(buttonId === 'roundTable')
                    // In this case the user has to specify the number of sides still.
                    // set no default for this.state.n_sides since we want to keep the last one specified.
                    activeItem.n_sides = 6 // for everyone //this.state.standardPolygonNSides
                }
                this.setState({activeItem}) // adapts state
                // and triggers render for rest of modal dialogue, which should adapt to changed state.

            }
        }
    }

    validateName() {
        var errorString = ''
        const ok = this.state.activeItem.name !== ''
        if (!ok) {
            errorString = 'De naam moet ingevuld worden.\nVerbeter dit eerst aub. Kies een unieke naam per tafeltype.'
        }
        return errorString
    }

    validateWidth() {
        var errorString = ''
        const ok = this.state.activeItem.width !== ''
        if (!ok) {
            errorString = 'De breedte moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateHeight() {
        var errorString = ''
        const ok = this.state.activeItem.height !== ''
        if (!ok) {
            errorString = 'De lengte moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateSeatingSide0() {
        var errorString = ''
        const ok = this.state.activeItem.seatingSide0 !== ''
        if (!ok) {
            errorString = 'Het aantal stoelen links van de tafel moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateSeatingSide1() {
        var errorString = ''
        const ok = this.state.activeItem.seatingSide1 !== ''
        if (!ok) {
            errorString = 'Het aantal stoelen achter de tafel moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateSeatingSide2() {
        var errorString = ''
        const ok = this.state.activeItem.seatingSide2 !== ''
        if (!ok) {
            errorString = 'Het aantal stoelen rechts van de tafel moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateSeatingSide3() {
        var errorString = ''
        const ok = this.state.activeItem.seatingSide3 !== ''
        if (!ok) {
            errorString = 'Het aantal stoelen voor de tafel moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

  validateForm() {
    var errorString = ''

    errorString = this.validateName()
    if (errorString !== '') {
        this.updateErrorStringLeadingToAlert(errorString)
    } else {
        errorString = this.validateWidth()
        if (errorString !== '') {
            this.updateErrorStringLeadingToAlert(errorString)
        } else {
            errorString = this.validateHeight()
            if (errorString !== '') {
                this.updateErrorStringLeadingToAlert(errorString)
            } else {
                errorString = this.validateSeatingSide0()
                if (errorString !== '') {
                    this.updateErrorStringLeadingToAlert(errorString)
                } else {
                    errorString = this.validateSeatingSide1()
                    if (errorString !== '') {
                        this.updateErrorStringLeadingToAlert(errorString)
                    } else {
                        errorString = this.validateSeatingSide2()
                        if (errorString !== '') {
                            this.updateErrorStringLeadingToAlert(errorString)
                        } else {
                            errorString = this.validateSeatingSide3()
                            if (errorString !== '') {
                                this.updateErrorStringLeadingToAlert(errorString)
                            }
                        }
                    }
                }
            }
        }
    }
      if (errorString === '') {
        this.props.onSave(this.state.activeItem)
      }
  }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            // triggers render and then a react window lit in bright white
            // that has no "server-name says", which is good
            // on darker table modal dialogue which is good
            // on even darker main app window, which is good
            // but first alert window is compressed inside table modal dialogue,
            // which is okay-ish.
            this.setState({ errorString: errorString} )
        } else {
            // says "server-name says"... which we want to avoid
            // suddenly gives other type of button than the rest of the site,
            // which we want to avoid.
            alert(errorString)
        }
    }

    mustShowWarning() {
       const debug = false
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       if (debug) {
           console.log(`errorStringType = ${errorStringType}`)
           console.log(`errorStringEmpty = ${errorStringEmpty}`)
           console.log(`show = ${show}`)
       }
       return show
    }


  render() {
    const { toggle } = this.props;
    const { activeItem } = this.state;
    return (
      <>
        <BaseModal
          actionText="Bewaar"
          actionButtonColor="#0096A9"
          title={"Tafeltype of stoelenrij"}
          toggle = {toggle}
          handleSave={() => this.validateForm()}
          body={
            <Form>
            <FormGroup>
              <Row>
                <Col sm="4" md="4" lg="4" className="d-flex align-self-center">
                  <Label for="title">naam</Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Input
                    type="text"
                    name="name"
                    value={activeItem.name}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'identifier')}
                    placeholder="Bijv. Grote tafel"
                  />
                </Col>
              </Row>
            </FormGroup>
            <Row className="mb-15" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
              <Col sm="4" md="4" lg="4" className="d-flex align-self-center">
                <Label for="tableType">
                  Type
                </Label>
              </Col>
              <Col sm="8" md="8" lg="8">
                <Select
                  defaultValue={this.state.defaultTableType}
                  options={ this.state.selectOptions }
                  isClearable={false}
                  onChange={e => this.handleTableTypeChange(e)}
                />
              </Col>
            </Row>
        <FormGroup>
        <Row>
            <Col sm="4" md="4" lg="4">
            </Col>
            {(this.state.tableType === 'rectangularTable') ?
            <>
              <Col sm="4" md="4" lg="4" className="d-flex justify-content-center">
                <h4 className="measure-label">Breedte</h4>
              </Col>
              <Col sm="4" md="4" lg="4" className="d-flex justify-content-center">
                <h4 className="measure-label">Lengte</h4>
              </Col>
            </>
            :
              <Col sm="4" md="4" lg="4" className="d-flex justify-content-center">
                <h4 className="measure-label">{['roundTable'].includes(this.state.tableType) ? "Lengte zijde" : "Breedte"}</h4>
              </Col>
            }

          </Row>
        <Row className="mb-15">
            <Col sm="4" md="4" lg="4" className="d-flex align-self-center">
              <Label for="width">
                Afmetingen
              </Label>
            </Col>
            <Col sm="4" md="4" lg="4">
              <InputGroup className="input-field">
                <Input
                  name="width"
                  className="input-field border-right-none"
                  type="number"
                  min="0.1"
                  step="0.1"
                  max="50.0"
                  value={activeItem.width}
                  onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText className="input-text-addon">m</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm="4" md="4" lg="4">
              <InputGroup className={"input-field" + ((this.state.tableType !== 'rectangularTable') && " hidden")}>
                <Input
                  name="height"
                  className="input-field border-right-none"
                  type={(this.state.tableType === 'rectangularTable') ? "number" : "hidden"}
                  min="0.1"
                  step="0.1"
                  max="50.0"
                  value={this.state.activeItem.n_sides==4 ?
                      this.state.activeItem.height:
                      this.state.activeItem.width}
                  onChange={e => this.validatePatternAndChangeIfOk(e, 'twoDotOneDigits')}
                  disabled = {(this.state.activeItem.n_sides !=4)}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText className="input-text-addon">m</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          {['roundTable'].includes(this.state.tableType) &&
        <FormGroup>
            <Row style={{flex:2,flexDirection:"row",justifyContent:'space-between', marginBottom: 18}}>
              <Col sm="4" md="4" lg="4">
                  <Label for="n_sides">aantal personen</Label>
              </Col>
              <Col sm="4" md="4" lg="4">
                  <Input
                        name="n_sides"
                        type="number"
                        min={3}
                        step={1}
                        max={16}
                        value={activeItem.n_sides}
                        onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
              </Col>
              <Col sm="4" md="4" lg="4">
              </Col>
              </Row>
        </FormGroup>
        }
          {(this.state.tableType === 'rowOfSeats') &&
          <Row>
            <Col sm="4" md="4" lg="4">
            aantal stoelen
            </Col>
            <Col sm="4" md="4" lg="4">
              <Input name="seatingSide1" // We take side 1, because that's the top (table) side,
              // so corresponding to width
                    type="number"
                    min={0}
                    step={1}
                    max={99}
                    value={this.state.activeItem.seatingSide1}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
              />
            </Col>
            <Col sm="4" md="4" lg="4">
              </Col>
          </Row>
          }

          {(this.state.tableType === 'rectangularTable') &&
          <>
            <Row>
            <Input
                    name="seatingSide1"
                    type="number"
                    className="input-field seat-input"
                    min={0}
                    step={1}
                    max={99}
                    value={this.state.activeItem.seatingSide1}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
            </Row>
            <Row style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
              <Col sm="4" md="4" lg="4" className="d-flex align-self-center">
                  <Label for="seatingSide0">stoelen</Label>
              </Col>
              <Col sm="8" md="8" lg="8">
              <Row className="m-0">
                  <Input
                    name="seatingSide0"
                    type="number"
                    className="seat-inputs-middle"
                    min={0}
                    step={1}
                    max={99}
                    value={this.state.activeItem.seatingSide0}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
                  <div className="rectangle"/>
                  <Input
                    name="seatingSide2"
                    type="number"
                    className="seat-inputs-middle"
                    min={0}
                    step={1}
                    max={99}
                    value={this.state.activeItem.seatingSide2}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
            <Input
                    name="seatingSide3"
                    type="number"
                    className="input-field seat-input"
                    min={0}
                    step={1}
                    max={99}
                    value={this.state.activeItem.seatingSide3}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
            </Row>
          </>
        }
       </FormGroup>
      </Form>
          }
        />


        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}> waarschuwing </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString}
                    </Row>
                    </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    <SaveIcon /> {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

      </>
    );
  }
}

