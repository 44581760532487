import React from 'react';
import {Input, Row, Col, Label} from 'reactstrap';
import DeleteButton from './deleteButton/DeleteButton';
import EditButton from "./editButton/Editbutton"
import Tooltip from "@material-ui/core/Tooltip";

import styled from 'styled-components';

export default function ReservationList(props) {

    const ReservationButton = styled.p`
      color: #0096A9;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 700
    `;

    const reservationLines = props.reservations.map( (reservationType, index) =>
    <>
      <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
        <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                <Label for="name">{reservationType.name}</Label>
          </Col>
          <Col sm="7" md="7" lg="7" className="d-flex">
          <Input
            className="input-field obstacle-field"
            name="number" min={0} max={50}
            type="number"
            value={reservationType.number}
            onChange = {
              e => {
                  const targetValue = e.target.value
                  props.updateNumber(index, targetValue);
              }
            }
          />
              <EditButton
                hasTooltip={true}
                classes={"edit-button"}
                handleClick={() => props.handleEdit(index)}
              />
              <DeleteButton
                hasTooltip={true}
                handleDelete={()=> props.handleDelete(reservationType)}
                classes={"delete-button"}
              />
          </Col>
        </Row>
    </>
    )

    return (
        <div className='mb-5'>

          <Tooltip
            title={<h6 style={{ color: "white" }}>
                  Definiëer hier uw reservaties waarvoor tafels moeten geplaatst worden.
            </h6>}
              placement="bottom"
            >
                 <h4>{props.name}</h4>
          </Tooltip>
                {reservationLines}
              <ReservationButton
                onClick={props.handleCreate}>
                Voeg toe
              </ReservationButton>

        </div>
    )
}
