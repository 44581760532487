import React from "react"
import { Navbar, NavbarBrand, NavItem, Nav, Tooltip } from "reactstrap"
import { StyledButton } from "./styledButton"
import MainLogo from "../FM_logo_no baseline.png"

export default function Navigation ({loggedIn, handleLogin, handleLogout, isLogginIn, handleRulesToggle, handleHelpToggle}){
  return (
    <>
      <Navbar className="main-navbar" color="white" light expand="md">
        <NavbarBrand href="/" className="brand-title">OptiSeats</NavbarBrand>
        <Nav className="mr-auto pl-0" navbar>
            <NavItem>
              <img className="main-logo" src={MainLogo} alt="flandersMake"></img>
            </NavItem>
        </ Nav>
        <Nav className="ml-auto" navbar>

        <NavItem>
        <StyledButton
              backgroundColor="white"
              color="black"
              weigth="500"
              onClick={ () => {
                handleHelpToggle()
              }}
            >
                Help
            </StyledButton>
        </NavItem>

        <NavItem>
        <StyledButton
              backgroundColor="white"
              color="black"
              weigth="500"
              onClick={ () => {
                handleRulesToggle()
              }}
            >
                Maatregelen
            </StyledButton>
        </NavItem>

        <NavItem>
          { loggedIn ?
            <StyledButton
              backgroundColor="#5F565E"
              color="white"
              onClick={ () => {
                  isLogginIn(false)
                  handleLogin()
                }}
              >
                Profiel
            </StyledButton>
          :
            <StyledButton
              backgroundColor="#5F565E"
              color="white"
              onClick={ () => {
                  isLogginIn(false)
                  handleLogin()
                }}
              >
                Registreer
            </StyledButton>
          }
          </NavItem>
          <NavItem>
            { loggedIn ?
              <StyledButton
                className="login-button"
                backgroundColor="#0096A9"
                color="white"
                onClick={ () => {
                    isLogginIn(true)
                    handleLogout()
                  }}
                >
                Meld af
              </StyledButton>
            :
            <StyledButton
              className="login-button"
              backgroundColor="#0096A9"
              color="white"
              onClick={ () => {
                  isLogginIn(true)
                  handleLogin()
                }}
              >
              Meld aan
            </StyledButton>
          }
          </NavItem>
        </Nav>
      </ Navbar>
    </>
  )
}
