import React, { Component } from "react";
import BaseModal from "./BaseModal"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

import styled from 'styled-components';

const ReservationButton = styled.p`
  color: #0096A9;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  margin-right: 1.5rem;
`;

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;


export default class ReservationModal extends Component {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this)
    const debug = false
    if (debug) {
        console.log(`ReservationModal.props = ${JSON.stringify(props, null, 2)}`)
        console.log(`props.activeItem = ${props.activeItem}`)
    }
    this.state = {
        activeItem: this.props.activeItem,
        errorString: ''
    }
    if (debug) {
      console.log(`this.state.activeItem = ${this.state.activeItem}`)
      console.log(`this.state.activeItem  = ${JSON.stringify(this.state.activeItem, null, 2)}`)
    }
  }

    // The idea here, is that only if we pass the pattern test,
    // do we update the field to the value entered.
    validatePatternAndChangeIfOk = (e, pattern) => {
      const debug = true
      var val = e.target.value
      var name = e.target.name
      if (debug) {
          console.log(`val = ${val}`)
          console.log(`name = ${name}`)
          console.log(`pattern = ${pattern}`)
      }
      var re = null
      if (pattern === 'integerTwoDigits') {
        re = /^[1-9]?\d$/
      } else if (pattern === 'identifier') {
        re = /^[_a-zA-Z-][_a-zA-Z0-9-]*$/   // numbers are forbidden,
      } else {
        const errorString = `Pattern ${pattern} is not recognised. Contact developer team.`
        this.updateErrorStringLeadingToAlert(errorString)
      }
      if (debug) {
          console.log('re = ')
          console.log(re)
      }
      if (val === '' || re.test(val)) {
        // Note, that unfortunately, we must (temporarily) accept the empty string, because otherwise editing towards
        // something totally different is not possible. This means that we must catch the empty value when and where
        // we use it. That means, we should know the 'default id empty' value there.

        //var activeItem = this.state.activeItem // This gave overwrite problems,
        // leading to the same record being copied twice in the reservationList and their name
        // being changed  together, as a consequence also being deleted together.

        if (['name', 'groupSize', 'number'].includes(name)) {
            var activeItem = this.state.activeItem
            activeItem[name] = val
            if (name === 'groupSize') {
                activeItem['name'] = `groep van ${val}` // shows up correctly in main window
            }
            this.setState({activeItem})
        } else {
          if (debug) {
              console.log(`field name = ${name} is not recognised. Fix it.`)
          }
        }
        if (debug) {
            console.log(`test OK`)
        }
      } else {
        if (debug) {
            console.log(`test NOK`)
        }
      }
   }

    validateGroupSize() {
        var errorString = ''
        const ok = this.state.activeItem.groupSize !== ''
        if (!ok) {
            errorString = 'De group (bubble) grootte moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateGroupNumber() {
        var errorString = ''
        const ok = this.state.activeItem.number !== ''
        if (!ok) {
            errorString = 'Het aantal keren dat de groep gereserveerd is moet ingevuld worden.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

  validateForm() {
    var errorString = ''

    errorString = this.validateGroupSize()
    if (errorString !== '') {
        this.updateErrorStringLeadingToAlert(errorString)
    } else {
        errorString = this.validateGroupNumber()
        if (errorString !== '') {
            this.updateErrorStringLeadingToAlert(errorString)
        }
    }
    if (errorString === '') {
      this.props.onSave(this.state.activeItem)
   }
  }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            // triggers render and then a react window lit in bright white
            // that has no "server-name says", which is good
            // on darker reservation modal dialogue which is good
            // on even darker main app window, which is good
            // but first alert window is compressed inside reservation modal dialogue,
            // which is okay-ish.
            this.setState({ errorString: errorString} )
        } else {
            // says "server-name says"... which we want to avoid
            // suddenly gives other type of button than the rest of the site,
            // which we want to avoid.
            alert(errorString)
        }
    }

    mustShowWarning() {
       const debug = false
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       if (debug) {
           console.log(`errorStringType = ${errorStringType}`)
           console.log(`errorStringEmpty = ${errorStringEmpty}`)
           console.log(`show = ${show}`)
       }
       return show
    }

  render() {
    const { toggle } = this.props;
    return (
      <>
        <BaseModal
          actionText="Bewaar"
          actionButtonColor="#0096A9"
          title={"Reservatie"}
          toggle = {toggle}
          handleSave={() => this.validateForm()}
          body={
            <Form className="modal-form">
              <Row className="modal-input-row">
                <Col sm="4" md="4" lg="4" className="p-0">
                  <Label for="groupSize">
                    Aantal personen
                  </Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Input
                    name="groupSize"
                    type="number"
                    min="1"
                    step="1"
                    max="99"
                    className="input-field"
                    value={this.state.activeItem.groupSize}
                    onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4" className="p-0">
                  <Label for="number">
                    Aantal reservaties
                  </Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Input
                      name="number"
                      type="number"
                      min="1"
                      step="1"
                      max="99"
                      className="input-field"
                      value={this.state.activeItem.number}
                      onChange={e => this.validatePatternAndChangeIfOk(e, 'integerTwoDigits')}
                  />
                </Col>
              </Row>
            </Form>
          }
        />
        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}> waarschuwing </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString}
                    </Row>
                </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

      </>
    )
  }
}
