import React, { useRef, useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap"
import SpinnerWrapper from "./SpinnerWrapper"
import { StyledButton } from "./styledButton"

export default function ResultModal ({ selectedRoom, defaultRoom, loadedSvg, isOpen, toggle, handleDownload, cautionText, pdf }){
    const svg = useRef(null);
    useEffect(()=>{
    if (svg.current) {
        const replaceIoAppendSolutions = true
        // When false, we get appending solutions and that looks ugly, since the resultModal
        // was not designed to hold multiple solutions.

        if (replaceIoAppendSolutions) {
            // Delete all existing solutions (in practice only one, the previous one).
            while (svg.current.firstChild) {
                svg.current.removeChild(svg.current.firstChild);
            }
        }
        // Add the latest server-generated, incoming solution.
        svg.current.appendChild(loadedSvg)
    }
  }, [loadedSvg]);

  return (
    <>
      <Modal className="result-modal" isOpen={isOpen} toggle={()=>toggle(isOpen)}>
        <ModalHeader toggle={()=>toggle(isOpen)}>
          Plaatsing voor {selectedRoom ? (
            selectedRoom.replace("[", '').replace("]", '')
          ) : (
            defaultRoom.replace("[", '').replace("]", '')
          )}
        </ModalHeader>
        <ModalBody className="result-modal-body">
          { !loadedSvg ?
            <Col className="flex-column d-flex justify-content-center">
              <Row className="d-flex justify-content-center">
                <h2>Plaatsing berekenen</h2>
              </Row>
              <Row className="d-flex justify-content-center">
                <p>Dit kan eventjes duren</p>
              </Row>
              <Row className="d-flex justify-content-center spinner-container">
                <SpinnerWrapper classes="mt-5 loading-spinner" type="border" style={{ width: '5rem', height: '5rem' }}/>
              </Row>
            </Col>
            : (
               <div className="svg-container" ref={svg}/>
            )
          }
          <p className="caution-text">{cautionText}</p>
        </ModalBody>
        <ModalFooter>
          <p className="try-again-text mr-auto" onClick={()=>toggle(isOpen)}>
            <span className="try-again-span">Probeer opnieuw</span>
            &nbsp; met meer rekentijd als u denkt dat er meer tafels geplaatst kunnen worden.
          </p>
                <StyledButton
                  saveButton="true"
                  backgroundColor="#0096A9"
                  color="white"
                  opacity={!loadedSvg && "0.5"}
                  disabled={!loadedSvg}
                  onClick={() => (
                    handleDownload()
                  )}
                >
                    Download PDF
                </StyledButton>
        </ModalFooter>
      </Modal>
    </>
  )
}
