import React from "react"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function CollapseButton ({title, clicked, collapseItem, isCollapsed}){
  return (
    <>
      <div
        onClick={()=>(
          clicked(collapseItem, isCollapsed)
        )}
        className="d-flex collapse-button align-items-center"
      >
        <ArrowForwardIosIcon className={isCollapsed ? "collapse-icon" : ("collapse-icon rotate") }/>
        <h3 className="collapse-title mb-0" >{title}</h3>
      </div>
    </>
  )
}
