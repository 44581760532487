import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import { StyledButton } from "./styledButton"
import styled from 'styled-components';

export default function BaseModal ({body, handleSave, toggle, title, actionText, actionButtonColor}){
  const ReservationButton = styled.p`
  color: #0096A9;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  margin-right: 1.5rem;
`;

  return (
    <>
      <Modal className="base-modal" isOpen={toggle}>
        <ModalHeader> <h3 className="modal-title">{title}</h3> </ModalHeader>
        <ModalBody>
            {body}
        </ModalBody>

        <ModalFooter>
          <ReservationButton
            onClick={() => toggle()}
          >
            Annuleer
          </ReservationButton>
          <StyledButton
            saveButton="true"
            backgroundColor={actionButtonColor}
            color="white"
            onClick={() => (
              handleSave()
            )}
          >
            {actionText}
          </StyledButton>
        </ModalFooter>
      </Modal>
    </>
  )
}
