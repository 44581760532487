
import React from 'react'
import {
      Row,
      Col,
    } from "reactstrap";
import {Stage, Layer, Rect, Line, Group, Circle, Text} from 'react-konva';
import Scale from './Scale'

import Tooltip from "@material-ui/core/Tooltip";
//import HelpIcon from '@material-ui/icons/Help';

const roomColors = {stroke:"lightGreen",fill:"HoneyDew"}
const obstacleColors = {stroke:"pink", fill:"MistyRose", opacity:0.7}
const chairColors = {stroke:"",fill:"DeepSkyBlue"}
const tableColors = {stroke:"",fill:"DeepSkyBlue"}
const distanceColors={stroke:"yellow",fill:""}
const zoneColors={stroke:"gray",fill:"gray"}

const showUnusedSymbolsInLegend = false
const nLegendLines = showUnusedSymbolsInLegend ? 5 : 2

function Legend(props){
  return (
    <Group
      draggable={false}
    >
      <Rect
        height = {2 * props.margin +
        ((nLegendLines-1) * props.distance) // not sure why -1 is needed here
        + props.iconsize}
        width = {180}
        fill = "White"
        stroke = "Black"
      />
      <Rect
        height = {props.iconsize}
        width = {props.iconsize}
        fill={roomColors.fill}
        stroke={roomColors.stroke}
        x={props.margin}
        y={props.margin}
      />
      <Text text="ruimte"
        x={2*props.margin+props.iconsize}
        y={props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>
      <Rect
        height = {props.iconsize}
        width = {props.iconsize}
        fill={obstacleColors.fill}
        stroke={obstacleColors.stroke}
        opacity={obstacleColors.opacity}
        x={props.margin}
        y={props.distance*1+props.margin}
      />
      <Text text="obstakel"
        x={2*props.margin+props.iconsize}
        y={props.distance*1+props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>

      {/* For now, we do not show the things below (yet), so no need for a legende in react for those parts.
      // This also saves space (and avoids duplication).
      // The svg displayed below in the webpage has a complete legende.
      */}
      {showUnusedSymbolsInLegend &&
      <Group>
      <Rect
        height = {props.iconsize}
        width = {props.iconsize}
        fill={tableColors.fill}
        stroke={tableColors.stroke}
        x={props.margin}
        y={props.distance*2+props.margin}
      />
      <Text text="tafel"
        x={2*props.margin+props.iconsize}
        y={props.distance*2+props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>
      <Circle
        radius = {props.iconsize/2}
        fill={chairColors.fill}
        stroke={chairColors.stroke}
        x={props.margin+props.iconsize/2}
        y={props.distance*3+props.margin+props.iconsize/2}
      />
      <Text text="stoel"
        x={2*props.margin+props.iconsize}
        y={props.distance*3+props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>
      <Circle
        radius = {props.iconsize/2}
        fill={distanceColors.fill}
        stroke={distanceColors.stroke}
        x={props.margin+props.iconsize/2}
        y={props.distance*4+props.margin+props.iconsize/2}
      />
      <Text text="corona-perimeter"
        x={2*props.margin+props.iconsize}
        y={props.distance*4+props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>
      <Rect
        height = {props.iconsize}
        width = {props.iconsize}
        fill={zoneColors.fill}
        stroke={zoneColors.stroke}
        x={props.margin}
        y={props.distance*5+props.margin}
      />
      <Text text="veiligheidsoppervlak"
        x={2*props.margin+props.iconsize}
        y={props.distance*5+props.margin+props.iconsize/2-props.fontsize/2}
        fontSize={props.fontSize}/>
      </Group>
      }
    </Group>)
}

function calculateScale(canvaswidth, canvasheight, width, height) {

   const room_width_over_white_bg_width = 0.90
   const room_depth_over_white_bg_height = 0.90
   const scale_width = canvaswidth/width * room_width_over_white_bg_width;
   const scale_height = canvasheight/height * room_depth_over_white_bg_height;
   const scale = Math.min(scale_height,scale_width);

   const xmargin = (canvaswidth-width*scale)/2;
   const ymargin = (canvasheight-height*scale)/2;

   return {
     scale:scale,
     xmargin:xmargin,
     ymargin:ymargin
   }
}

function Grid(props){
  var lines = [];
  for (var x = 0; x <= props.roomSize.x; x++){
    lines.push(
      <Line
        points={[x*props.scale+props.xmargin, props.ymargin,
                 x*props.scale+props.xmargin, props.height - props.ymargin]}
        stroke = "gray"
        opacity = {0.5}
        strokeWidth = {0.5}
        />
    )
  }
  for (var y = 0; y <= props.roomSize.y; y++){
    lines.push(
      <Line
        points={[props.xmargin, y*props.scale+props.ymargin,
                 props.width-props.xmargin, y*props.scale + props.ymargin,]}
        stroke = "gray"
        opacity = {0.5}
        strokeWidth = {0.5}
        />
    )
  }
  return lines;
}


export default function KonvaCanvas(props) {

  const mapObstacleType=(object,index)=>{

    const endDragHandler = (dragged, typeIndex, instanceIndex) => {
      const debug = false
      if (debug) {
          console.log(`dragged.target.attrs = ${dragged.target.attrs}`)
          console.log(JSON.stringify(dragged.target.attrs))
      }
      var obstacleTypes = props.obstacleTypes;
      var x_canvas = dragged.target.attrs.x;
      var y_canvas = dragged.target.attrs.y;

      var x_real = (x_canvas-xmargin)/scale;
      var y_real = props.roomSize.y - (y_canvas-ymargin)/scale;

      if (true) {
          // clipping:
          var tmp_x = x_canvas/scale
          var tmp_y = y_canvas/scale
          if (debug) {
            console.log(`tmp_x_m=${tmp_x}`)
            console.log(`tmp_y_m=${tmp_y}`)
            console.log(`xmargin=${xmargin}`)
            console.log(`ymargin=${ymargin}`)
          }
          const w = Math.abs(dragged.target.attrs.width/scale) // obstacleTypes[typeIndex].instances[instanceIndex].width
          const h = Math.abs(dragged.target.attrs.height/scale) // obstacleTypes[typeIndex].instances[instanceIndex].height
          if (debug) {
            console.log(`w_m=${w}`)
            console.log(`h_m=${h}`)
          }
          const smallDelta = 0.0
          if (x_real > props.roomSize.x) {
            if (debug) {
              console.log('x too large')
            }
            x_real = props.roomSize.x-smallDelta
          }
          if (y_real > props.roomSize.y) {
            if (debug) {
              console.log('y too large')
            }
            y_real = props.roomSize.y-smallDelta
          }
          if (x_real < -w) {
            if (debug) {
              console.log('x too small')
            }
            x_real = -w+smallDelta
          }
          if (y_real < -h) {
            if (debug) {
              console.log('y too small')
            }
            y_real = -h+smallDelta
          }
      }

      obstacleTypes[typeIndex].instances[instanceIndex].x = x_real;
      obstacleTypes[typeIndex].instances[instanceIndex].y = y_real;

      props.updateObstacleTypes(obstacleTypes);
    }

    const mapObstacleIndex = (o, i, obstacleType, obstacleTypeIndex) => {
      return(<Rect
        x={o.x*scale+xmargin}
        y={(props.roomSize.y-o.y)*scale+ymargin}
        width={obstacleType.width*scale}
        height={-obstacleType.height*scale}
        fill={obstacleColors.fill}
        stroke={obstacleColors.stroke}
        opacity={obstacleColors.opacity}
        draggable
        onDragEnd={e=>endDragHandler(e,obstacleTypeIndex,i)}
     />)
    }
    return object.instances.map((o,i)=>mapObstacleIndex(o,i,object,index));
  }
  const mapTableType = (object,index) => {

    const endDragHandler=(dragged, typeIndex,instanceIndex)=>{
      var tableTypes = props.tableTypes;
      const x_canvas = dragged.target.attrs.x;
      const y_canvas = dragged.target.attrs.y;

      const x_real = (x_canvas-xmargin)/scale;
      const y_real = props.roomSize.y - (y_canvas-ymargin)/scale;

      tableTypes[typeIndex].instances[instanceIndex].x = x_real;
      tableTypes[typeIndex].instances[instanceIndex].y = y_real;

      props.updateTableTypes(tableTypes);
    }

    const chairPositions = (l, d) => {
      var positions = [];
      const n = Math.trunc(l/d);
      if(n%2 === 1){
        for(var i=-(n-1)/2; i<=(n-1)/2; i++){
          positions.push(i*d);
        }
      } else {
        for(i=-n/4; i<=n/4; i++){
          positions.push(i*d)
        }
      }
      return positions;
    };

    const Chair = props=>{
    return(
    <Group
        x={props.x*scale}
        y={props.y*scale}>
      <Circle
        radius = {0.29*scale}
        fill={chairColors.fill}
        stroke={chairColors.stroke}
      />
      <Circle
        radius = {.75*scale}
        fill={distanceColors.fill}
        stroke={distanceColors.stroke}
      />
    </Group>)}

    const Chairs=props=>{
      var chairs = [];
      const chairsX = chairPositions(props.width, 0.6)
      for (var chairX of chairsX){
        chairs.push(
          <Chair
            x={chairX}
            y={(-props.height/2-0.3)}
          />
        );
        chairs.push(
          <Chair
            x={chairX}
            y={(props.height/2+0.3)}
          />
        );
      }
      const chairsY = chairPositions(props.height, 0.6)
      for (var chairY of chairsY){
        chairs.push(
          <Chair
            y={chairY}
            x={(-props.width/2-0.3)}
          />
        );
        chairs.push(
          <Chair
            y={chairY}
            x={(props.width/2+0.3)}
          />
        );
      }
      return chairs;
    };

    const mapTableIndex=(o, i, tableType, tableTypeIndex)=>{
      return(
        <Group
          x={(o.x)*scale+xmargin}
          y={(props.roomSize.y-o.y)*scale+ymargin}
          rotation={o.rotation}
          draggable
          onDragEnd={e=>endDragHandler(e,tableTypeIndex,i)}
        >
          <Rect
            width={(tableType.width+1.5+0.6)*scale}
            height={(tableType.height+1.5+0.6)*scale}
            fill={zoneColors.fill}
            stroke={zoneColors.stroke}
            offsetX={(0.5*tableType.width+.75+.3)*scale}
            offsetY={(0.5*tableType.height+.75+.3)*scale}
            opacity = {0.25}
          />
          <Rect
            width={tableType.width*scale}
            height={tableType.height*scale}
            fill={tableColors.fill}
            stroke={tableColors.stroke}
            offsetX={0.5*tableType.width*scale}
            offsetY={0.5*tableType.height*scale}
           />
           <Chairs
            width={tableType.width}
            height = {tableType.height}
            scale={scale}
           />

        </Group>
     )
    }
    return object.instances.map((o,i)=>mapTableIndex(o,i,object,index));
  }

  const {scale, xmargin, ymargin} =
      calculateScale(props.width, props.height, props.roomSize.x, props.roomSize.y);
    return(

    <div>
      <Row>
      <Col>
            <Tooltip
              title={<h6 style={{ color: "white" }}>
                    Hieronder kan u de obstakels (in rood aangegeven),
                    verslepen in de ruimte (in groen aangegeven).
                    Zet ze best niet buiten de ruimte.
                </h6>}
                  placement="bottom"
                >
                     <h4>verplaats de obstakels      </h4>

           </Tooltip>

              {/*
      		  <Tooltip
              title={<h6 style={{ color: "white" }}>
              Met de muis kan u de hieronder obstakels (in rood aangegeven),
              verslepen in de ruimte (in groen aangegeven).</h6>}
                placement="right"
              >
              <HelpIcon/>
              </Tooltip>
              */}
      <Stage
        width={props.width}
        height={props.height}
      >
        <Layer>
          <Rect
            x={0}
            y={0}
        width={props.width}
        height={props.height}
            fill="white"
            stroke="white"
            strokeWidth={2}
           />
        </Layer>

        <Layer >
         <Scale
        width = {200}
        height = {50}
        scale = {scale}
        x={xmargin}
        y={10}
      />
          <Rect
            x={xmargin}
            y={ymargin}
            width={props.roomSize.x*scale}
            height={props.roomSize.y*scale}
            fill="HoneyDew"
            stroke = "lightgreen"
            strokeWidth={2}
           />
        </Layer>
        <Layer>
          {props.obstacleTypes.map(mapObstacleType)}
        </Layer>
        <Layer>
          {props.tableTypes.map(mapTableType)}
        </Layer>
        <Layer>
          <Grid
            roomSize={props.roomSize}
            scale ={scale}
            xmargin={xmargin}
            ymargin={ymargin}
            height={props.height}
            width = {props.width}
            />
        </Layer>
      </Stage>
      </Col>
      <Col>
      <h4>legende</h4>
      <Stage
        width={180}
        height={60 * nLegendLines}
      >
      <Layer>
          <Legend
            iconsize={20}
            distance={30}
            margin = {20}
            fontsize={16}
          />
      </Layer>
      </Stage>
      </Col>
      </Row>
    </div>
    )
  }
