import React, { Component } from "react";
import {
  Form,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

import BaseModal from "./BaseModal"
import styled from 'styled-components';

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;

export default class SaveConfigurationAsModal extends Component {
    constructor(props) {
        super(props);

        const debug = false
        if (debug) {
            console.log(`SaveConfigurationAsModal.props = ${JSON.stringify(props, null, 2)}`)
        }
        this.state = {
            newConfigurationName: '',
            errorString: ''
        }
    }

    validatePatternAndChangeIfOk = (e, pattern) => {
      const debug = false

      var val = e.target.value
      var name = e.target.name
      if (debug) {
          console.log(`val = ${val}`)
          console.log(`name = ${name}`)
          console.log(`pattern = ${pattern}`)
      }
      var re = null
      if (pattern === 'identifier') {
        re = /^[_a-zA-Z-][_a-zA-Z0-9-]*$/
      } else {
        const errorString = `Pattern ${pattern} is not recognised. Contact developer team.`
        this.updateErrorStringLeadingToAlert(errorString)
      }
      if (debug) {
          console.log('re = ')
          console.log(re)
      }
      if (val === '' || re.test(val)) {
        this.setState({newConfigurationName: val})
        if (debug) {
            console.log(`test OK`)
        }
      } else {
        if (debug) {
            console.log(`test NOK`)
        }
      }
   }

    render() {

    const { toggle, onSave } = this.props;

    return (
      <BaseModal
        actionText="Bewaar"
        actionButtonColor="#0096A9"
        title={"Bewaar als"}
        toggle = {toggle}
        handleSave={() => onSave(this.state.newConfigurationName)}
        scrollToTop={true}
        body={
          <Form>
          <Row>
            <Col sm="4" md="4" lg="4">
                <Label for="title">Naam</Label>
            </Col>
            <Col sm="8" md="8" lg="8">
              <Input
                className="input-text"
                type="text"
                name="newConfigurationName"
                value={this.state.newConfigurationName}
                //onChange={this.validatePatternAndChangeIfOk(this.state.newConfigurationName, 'identifier')}
                onChange={e => this.validatePatternAndChangeIfOk(e, 'identifier')}
                placeholder={"Bijv. Indeling 8 mei"}
              />
            </Col>
          </Row>
          </Form>
        }
      />
      )
  }
}
