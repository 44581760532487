import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input, // This Input with attribute type=Select also does a Select,
  // with an arrau of options i.o. with an array of {value".., label"..} dictionaries
  // but the V shaped dropdown list arrow looks too big-and-ugly,
  // so in the end we use the Select from react-select.
  Label,
  Row,
  Col
} from "reactstrap";

import { Checkbox } from '@material-ui/core';
import Select from 'react-select' // with this select, one need to set the current value
// in the render function as: value={value".., label"..} which is not really intuitive, but it works.
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import { StyledButton } from "./styledButton"

const ResendButton = styled.p`
  color: #0096A9;
  font-size: 1.1875rem;
  margin: 10px 0px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
`

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;

const CancelButton = styled.p`
  color: #0096A9;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  margin-right: 1.5rem;
`;

export default class UserFormModal extends Component {
    constructor(props) {
        super(props);

        const debug = false
        if (debug) {
            console.log(`UserFormModal.props = ${JSON.stringify(props, null, 2)}`)
        }
        this.state = {
            emailAddress: '',

            password: '',
            password2: '',

            code: (typeof props.localCode === 'undefined') ? '' : props.localCode,

            userData: this.getDefaultUserData(),

            localLoginIoRegister: props.localLoginIoRegister,
            errorString: '',
        };

        this.bufferUserValuesFromParent(props)
        if (debug) {
            console.log(`this.state.localLoginIoRegister = ${this.state.localLoginIoRegister}`)
            console.log(`props.bufferedUserLoggedIn = ${props.bufferedUserLoggedIn}`)
            console.log(`props.localCode = ${props.localCode}`)
            console.log(`this.state.code = ${this.state.code}`)
        }
        this.havePasswordFields = false // if false, work with server generated codes instead
        // so exposing them in http is no disaster, since not user-invented.
        this.prefillEditProfileFormWithDbStoredValuesWhenLoggedIn()
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        if (this.havePasswordFields) {
            this.handlePasswordChange = this.handlePasswordChange.bind(this);
            this.handlePassword2Change = this.handlePassword2Change.bind(this);
        } else {
            this.handleCodeChange = this.handleCodeChange.bind(this);
        }
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleBusinessNameChange = this.handleBusinessNameChange.bind(this);
        //this.handleSectorNameChange = this.handleSectorNameChange.bind(this); // not needed
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleHowCanWeHelpChange = this.handleHowCanWeHelpChange.bind(this);
        //this.sendMeNewsChange = this.handleSendMeNewsChange.bind(this); // not needed
        //this.inviteMeChange = this.handleInviteMeChange.bind(this); // not needed
    } // end constructor


    getDefaultUserData() {
        return {
            firstName: '',
            lastName: '',
            businessName: '',
            sectorName: {value:'', label:''},
            phoneNumber: '+',
            howCanWeHelp: '',
            sendMeNews: false,
            inviteMe: false
        }
    }

    componentDidMount() {
        this.prefillEditProfileFormWithDbStoredValuesWhenLoggedIn()
    }

    // NOTE: Register form is profile edit form when already registered.
    prefillEditProfileFormWithDbStoredValuesWhenLoggedIn() {
        const debug = false
        var condition = this.bufferedUserLoggedIn
        if (condition) {
            if (debug) console.log(`this.bufferedUserData = ${JSON.stringify(this.bufferedUserData, null, 2)}`)
            if (typeof this.bufferedUserData !== 'undefined') {
                if (debug) console.log('typeof bufferedUserData !== undefined')
                this.setState({userData : this.bufferedUserData})
            } else {
                if (debug) console.log('typeof bufferedUserData === undefined')
            }
        } else {
            if (debug) console.log('not logged in')
        }
    }

    handleEmailAddressChange(event) {
        var val = event.target.value
        // Note that this pattern must allow every *temporary* text towards a valid email address.
        // So for example, something without a @ or two . must be allowed.
        const reTowardsEmailAddress = /^\S*$/  // We just forbid spaces for now.
        // TODO: We can be a bit stricter here already though, giving earlier feedback.
        if (val === '' || reTowardsEmailAddress.test(val)) {
            this.setState({ emailAddress: val });
        }
    }

    handlePasswordChange(event) {
      var val = event.target.value
      this.setState({ password: val });
    }

    handlePassword2Change(event) {
      var val = event.target.value
      this.setState({ password2: val });
    }

    handleCodeChange(event) {
      var val = event.target.value
      this.setState({ code: val });
    }

    handleFirstNameChange(event) {
      var val = event.target.value
      this.setState({ userData: {...this.state.userData, ['firstName']: val }});
    }

    handleLastNameChange(event) {
        var val = event.target.value
        this.setState({ userData: {...this.state.userData, ['lastName']: val }});
    }

    handleBusinessNameChange(event) {
        var val = event.target.value
        this.setState({ userData: {...this.state.userData, ['businessName']: val }});
    }

    handleSectorNameChange(event) {
        const debug = false
        // event is a dict with a value and a label keys, which in our app are always equal,
        // for example: {value: 'Onderwijs', label: 'Onderwijs' }
        if (debug) {
            console.log(`event = ${JSON.stringify(event, null, 2)}`)
        }
        this.setState({ userData: {...this.state.userData, ['sectorName']: event }});
    }

    handlePhoneNumberChange(event) {
      const debug = true
      var val = event.target.value
      const rePhone = /^\+[0-9 ]*$/;
      if (val === '+' || rePhone.test(val)) {
        // Note, that unfortunately, we must (temporarily) accept the empty string, because otherwise editing towards
        // something totally different is not possible. This means that we must catch the empty value when and where
        // we use it. That means, we should know the 'default id empty' value there.
        if (debug) {
            console.log(`handlePhoneNumberChange: test OK`)
        }
        this.setState({ userData: {...this.state.userData, ['phoneNumber']: val }});

      } else {
        if (debug) {
            console.log(`handlePhoneNumberChange: test NOK`)
        }
      }
    }

    handleHowCanWeHelpChange(event) {
      var val = event.target.value
      this.setState({ userData: {...this.state.userData, ['howCanWeHelp']: val }});
    }

    handleSendMeNewsChange(event) {
      var val = event.target.checked
      this.setState({ userData: {...this.state.userData, ['sendMeNews']: val }});
    }

    handleInviteMeChange(event) {
      var val = event.target.checked
      this.setState({ userData: {...this.state.userData, ['inviteMe']: val }});
    }

    // After the real time editing field checking functions above,
    // when a user presses the save, we will still have to do some validation.
    // These are given below, as the functions starting with validate.

    // From: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    validateEmailAddress() {
        var errorString = ''
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const ok = re.test(String(this.state.emailAddress).toLowerCase());
        if (!ok) {
            errorString = 'Het email adres is niet correct gestructureerd.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validatePassword() {
        var errorString = ''
        const pw = this.state.password
        const pwName = (this.state.localLoginIoRegister) ? 'paswoord' : 'eerste paswoord'

        var ok = pw.length >= 8
        if (!ok) {
            errorString = 'Het ' + pwName + ' is korter dan 8 karakters.\nVerbeter dit eerst aub.'
        } else {
            var re = /^(?=.*[a-z]).+$/; // test for at least one lowercase letter
            ok = re.test(pw)
            if (!ok) {
                errorString = 'Het ' + pwName + ' bevat geen kleine letter.\nVerbeter dit eerst aub.'
            } else {
                re = /^(?=.*[A-Z]).+$/;  // test for at least one uppercase letter
                ok = re.test(pw)
                if (!ok) {
                    errorString = 'Het ' + pwName + '  bevat geen hoofdletter.\nVerbeter dit eerst aub.'
                } else {
                    re = /^(?=.*[0-9_\W]).+$/;  // test for at least one digit or special letter letter
                    ok = re.test(pw)
                    if (!ok) {
                        errorString = 'Het ' + pwName + ' moet een cijfer of speciaal karakter bevatten.\nVerbeter dit eerst aub.'
                    }
                }
            }
        }
        return errorString
    }

    validatePassword2() {
        var errorString = ''
        if (this.state.password2 !== this.state.password) {
            errorString = 'Het herhaalde paswoord is niet gelijk aan het eerste paswoord.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateCode() {
        var errorString = ''
        const re = /^[a-zA-Z]{16}$/;
        const ok = re.test(this.state.code);
        if (!ok) {
            errorString = 'De code moet bestaan uit 16 letters. Elke letter kan een hoofd- of kleiner letter zijn. Uw persoonlijke code werd u per email toegezonden. \nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateFirstName() {
        var errorString = ''
        const ok = this.state.userData.firstName.length >= 2
        if (!ok) {
            errorString = 'De voornaam is korter dan 2 karakters.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateLastName() {
        var errorString = ''
        const ok = this.state.userData.lastName.length >= 2
        if (!ok) {
            errorString = 'De familienaam is korter dan 2 karakters.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateBusinessName() {
        var errorString = ''
        const ok = this.state.userData.businessName.length >= 0  // optional => 0 is ok
        if (!ok) {
            errorString = 'De bedrijfsnaam is niet ingevuld.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validateSectorName() {
        var errorString = ''
        const ok = this.state.userData.sectorName.value.length >= 1  // optional => 0 is not ok
        if (!ok) {
            errorString = 'De sectornaam is niet ingevuld.\nVerbeter dit eerst aub.'
        }
        return errorString
    }

    validatePhoneNumber() {
        var errorString = ''
        // The pattern is + and then any series of digits and spaces.
        if (this.state.userData.phoneNumber == '+0') {
            this.state.userData.phoneNumber = '+' // new patch apparently needed
        }
        const tel = this.state.userData.phoneNumber
        const spaceCount = tel.split(' ').length - 1;
        const plusCount = tel.split('+').length - 1
        const charCount = tel.length
        const digitCount = charCount - plusCount - spaceCount
        // Here we just count the digits an require that you have more than 6 of them.
        // https://www.quora.com/Which-countries-have-the-shortest-and-longest-phone-numbers-country-code-included
        if (digitCount === 0) {
            errorString = ''  // We accept this, as the field is not mandatory
        } else {
            if (digitCount < 7) {
                errorString = 'Een geldig telefoonnummer bevat ten minste 7 cijfers, wat hier niet het geval is.\nVerbeter dit eerst aub.'
            } else {
                if (digitCount > 15) {
                    errorString = 'Een geldig telefoonnummer bevat minder dan 15 cijfers, wat hier niet get geval is.\nVerbeter dit eerst aub.'
                }
            }
        }
        return errorString
    }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            // triggers render and then a react window lit in bright white
            // that has no "server-name says", which is good
            // on darker table modal dialogue which is good
            // on even darker main app window, which is good
            // but first alert window is compressed inside table modal dialogue,
            // which is okay-ish.
            this.setState({ errorString: errorString} )
        } else {
            // says "server-name says"... which we want to avoid
            // suddenly gives other type of button than the rest of the site,
            // which we want to avoid.
            alert(errorString)
        }
    }

    // And here we call all the validate function for all fields we have to check when save is pressed.
    validateForm() {
        const debug = false
        if (debug) {
            console.log('before validation, check if logged in, and check what are the buffered values.')
            console.log(`this.bufferedUserLoggedIn = ${this.bufferedUserLoggedIn}`)
            console.log(`this.bufferedUserEmail = ${this.bufferedUserEmail}`) // stateToGetUrlSuffix has set these 3, 1
            console.log(`this.bufferedUserPassword = ${this.bufferedUserPassword}`) // 2
            console.log(`this.bufferedUserCode = ${this.bufferedUserCode}`) // 3
            console.log('after buffered check')
        }
        if (this.bufferedUserLoggedIn) {
            // auto-form fill in for the the form fields in the lhs below
            // since user has not been presented
            if (debug) {
                console.log('this.bufferedUserLoggedIn is true so assigning to credential fields in form')
            }
            // Prefill, so that validateForm succeeds:
            this.state.emailAddress = this.bufferedUserEmail
            this.state.password = this.bufferedUserPassword
            this.state.password2 = this.bufferedUserPassword
            this.state.code = this.bufferedUserCode
            if (debug) {
                console.log('after buffered check')
            }
        } else {
            // nothing to do, user has been presented with credential fields and
            // filled them so the above 3 left hand sides already provided.
        }

        var errorString = ''

        if (this.state.localLoginIoRegister) {
            errorString = this.validateEmailAddress()
            if (errorString !== '') {
                this.updateErrorStringLeadingToAlert(errorString)
            } else {
                 if (this.havePasswordFields) {
                    errorString = this.validatePassword()
                    if (errorString !== '') {
                        this.updateErrorStringLeadingToAlert(errorString)
                    }
                 } else { // guarding access by code i.o. access by password
                     errorString = this.validateCode()
                     if (errorString !== '') {
                         this.updateErrorStringLeadingToAlert(errorString)
                     }
                 }
                // Note that there is no second password here, nor other fields to check.
            }
        } else {
            errorString = this.validateEmailAddress()
            if (errorString !== '') {
                this.updateErrorStringLeadingToAlert(errorString)
            } else {
                if (this.havePasswordFields) {
                    errorString = this.validatePassword()
                    if (errorString !== '') {
                        this.updateErrorStringLeadingToAlert(errorString)
                    } else {
                        errorString = this.validatePassword2()
                        if (errorString !== '') {
                            this.updateErrorStringLeadingToAlert(errorString)
                        }
                    }
                } else {
                    if (this.mustEnterAndSendCode()) {
                        errorString = this.validateCode()
                        if (errorString !== '') {
                            this.updateErrorStringLeadingToAlert(errorString)
                        }
                    }
                }
                if (errorString === '') {
                    errorString = this.validateFirstName()
                    if (errorString !== '') {
                        this.updateErrorStringLeadingToAlert(errorString)
                    } else {
                        errorString = this.validateLastName()
                        if (errorString !== '') {
                            this.updateErrorStringLeadingToAlert(errorString)
                        } else {
                            errorString = this.validateBusinessName()
                            if (errorString !== '') {
                                this.updateErrorStringLeadingToAlert(errorString)
                            } else {
                                errorString = this.validateSectorName()
                                if (errorString !== '') {
                                    this.updateErrorStringLeadingToAlert(errorString)
                                } else {
                                    errorString = this.validatePhoneNumber()
                                    if (errorString !== '') {
                                        this.updateErrorStringLeadingToAlert(errorString)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        const ok = (errorString === '')

        return ok
    }

    validateEmailOnlyFromForm() {
        const errorString = this.validateEmailAddress()
        if (errorString !== '') {
            this.updateErrorStringLeadingToAlert(errorString)
        }
        const ok = (errorString === '')
        return ok
    }

    bufferUserValuesFromParent(props) {
        this.bufferedUserLoggedIn = props.bufferedUserLoggedIn
        this.bufferedUserPassword = props.bufferedUserPassword
        this.bufferedUserCode = props.bufferedUserCode
        this.bufferedUserEmail = props.bufferedUserEmail
        this.bufferedUserData = props.bufferedUserData
    }

    // We can get this lhs info also from a form i.o. from a parent
    // Done in form send at stateToGetUrlSuffix right below.
    stateToGetUrlSuffix(addCodeField=true) {
        // convert that state of this component to a url suffix
        // (that is to be concatenated to a base url. This is done in the App.js).
        const debug = false
        var s = ''
        const messageType = this.state.localLoginIoRegister ? 'login' : 'reg'
        s += messageType + '/'
        s += '?emailAddress=' + this.state.emailAddress
        this.bufferedUserEmail = this.state.emailAddress // buffer it so that it can be reused when logged in,
        // and must not be asked to user anymore
        if (this.havePasswordFields) {
            s += '&password=' + this.state.password
            this.bufferedUserPassword = this.state.password
        } else {
            if (addCodeField) {
                s += '&code=' + this.state.code //this.props.localCode // (this.props.bufferedUserLoggedIn ? this.props.localCode : '')
            }
            this.bufferedUserCode = this.state.code // buffer it so that it can be reused when logged in,
            // and must not be asked to user anymore
        }
        if (!this.state.localLoginIoRegister) {
            s += this.serializeUserData(this.state.userData)
            this.bufferedUserData = this.state.userData
        }
        if (debug) {
            console.log(`url = ${s}`)
        }
        const values = [s, messageType]
        return values
    }

    serializeUserData(userData) {
        var s = '&firstName=' + userData.firstName
        s += '&lastName=' + userData.lastName
        s += '&businessName=' + userData.businessName
        s += '&sectorName=' + userData.sectorName.value
        s += '&phoneNumber=' + (userData.phoneNumber.length <= 7 ? '+0' : userData.phoneNumber)
        s += '&howCanWeHelp=' + userData.howCanWeHelp
        s += '&sendMeNews=' + (userData.sendMeNews ? '1' : '0')
        s += '&inviteMe=' + (userData.inviteMe ? '1' : '0')
        return s
    }

    mustShowWarning() {
       const debug = false
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       if (debug) {
           console.log(`errorStringType = ${errorStringType}`)
           console.log(`errorStringEmpty = ${errorStringEmpty}`)
           console.log(`show = ${show}`)
       }
       return show
    }

    mustEnterAndSendCode() {
        const result = this.state.localLoginIoRegister && !this.havePasswordFields && !this.props.bufferedUserLoggedIn
        return result
    }

    render() {
        const { toggle, onSave, onUnsubscribe, onResetCode } = this.props;

        return (
        <>
          <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}> {this.props.title}</ModalHeader>
            <ModalBody>
              <Form className="login-modal">

                  {this.state.localLoginIoRegister && this.props.bufferedUserLoggedIn &&
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      meld af.
                    </Col>
                  </Row>
                  }

                  {!this.props.bufferedUserLoggedIn &&
                    <>
                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label className="mb-0" for="title">E-mail*</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Input
                        type="text"
                        name="email"
                        value={this.state.emailAddress}
                        onChange={this.handleEmailAddressChange}
                      />
                    </Col>
                  </Row>
                    {!this.state.localLoginIoRegister &&
                        <Row>
                        <Col sm="4" md="4" lg="4"></Col>
                        <Col sm="8" md="8" lg="8">
                        <p className="registration-text mb-15">
                          We sturen een code om aan te melden naar dit e-mailadres.
                        </p>
                        </Col>
                      </Row>
                      }
                    </>
                  }

                  {this.havePasswordFields &&

                  <Row>
                    <Col sm="6" md="6" lg="6">
                      <Label for="title">paswoord*</Label>
                     <Tooltip
                          title={<h6 style={{ color: "white" }}>
                          Het paswoord moet minstens 8 karakters lang zijn en
                             een kleine letter en een grote letter bevatten, alsook een cijfer of een speciaal karakter.
                          </h6>}
                            placement="right"
                          >
                          <HelpIcon/>
                      </Tooltip>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                      <Input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        placeholder={""}
                      />
                    </Col>
                  </Row>
                  }

                  {this.mustEnterAndSendCode() &&
                    <Row className="mb-05">
                      <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                        <Label className="mb-0" for="title">Code*</Label>
                      </Col>
                      <Col sm="8" md="8" lg="8">
                          <Tooltip
                                title={<h6 style={{ color: "white" }}>
                                Wanneer u zich registreert genereert onze server een persoonlijke code voor u.
                                Met die code kan u zich aanmelden.
                                </h6>}
                                  placement="bottom"
                                >

                            <Input
                              type="password"
                              name="code"
                              value={this.state.code}
                              onChange={this.handleCodeChange}
                              placeholder={""}
                            />
                          </Tooltip>
                      </Col>
                    </Row>
                  }
                    {this.state.localLoginIoRegister && !this.props.bufferedUserLoggedIn &&
                    <Row className="mb-05 login-modal">
                    <Col sm="4" md="4" lg="4" className="p-0">
                    </Col>
                    <Col sm="8" md="8" lg="8">
                    <ResendButton
                      onClick={
                        () => {
                         // TODO: Check: It's true that anyone not logged in, but knowing your email adres,
                         // Can reset your code! That's true for other sites as well.
                         // Could be protected from bots by a CAPTCHA.
                         // Cannot be protected from humans.
                         // But not a big issue since only the email owner gets emailed the new code.
                         const ok = this.validateEmailOnlyFromForm();
                         if (ok) {
                             var values = this.stateToGetUrlSuffix(false);
                             var urlSuffix = values[0]
                             const messageType = values[1]
                             urlSuffix = urlSuffix.replace('emailAddress=', 'resetCode=')
                             urlSuffix = urlSuffix.replace('login/', 'reg/')
                             onResetCode(urlSuffix, messageType)
                             toggle() // modal should go away here, ok that works. :)
                         }
                       }
                     }
                      >
                        Verzend nieuwe code
                      </ResendButton>
                    </Col>
                    </Row>
                    }

                  {!this.state.localLoginIoRegister &&
                  <FormGroup>

                  {this.havePasswordFields &&
                  <Row>
                    <Col sm="6" md="6" lg="6">
                      <Label for="title">herhaal paswoord*</Label>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                      <Input
                        type="password"
                        name="password2"
                        value={this.state.password2}
                        onChange={this.handlePassword2Change}
                        placeholder={""}
                      />
                    </Col>
                  </Row>
                  }

                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label className="mb-0" for="title">Voornaam*</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Input
                        type="text"
                        name="first_name"
                        value={this.state.userData.firstName}
                        onChange={this.handleFirstNameChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label for="title">Naam*</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Input
                        type="text"
                        name="last_name"
                        value={this.state.userData.lastName}
                        onChange={this.handleLastNameChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label for="title">Sector*</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      {/* This is the select from react-select */}
                      { <Select
                          value = {(typeof this.state.userData.sectorName === 'undefined')
                            ? {value: 'Andere', label:'Andere'} : this.state.userData.sectorName}
                          options={ [
                              { value: 'Horeca', label: 'Horeca' },
                              { value: 'Zorgsector', label: 'Zorgsector' },
                              { value: 'Onderwijs', label: 'Onderwijs' },
                              { value: 'Evenementen - sport', label: 'Evenementen - sport' },
                              { value: 'Evenementen - cultuur', label: 'Evenementen - cultuur' },
                              { value: 'Andere', label: 'Andere' }
                            ] }
                            isClearable={false} // once set to non empty selection, one cannot select empty anymore
                            onChange={this.handleSectorNameChange.bind(this)}
                          />
                       }
                    </Col>
                  </Row>

                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label for="title">Onderneming</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Input
                        type="text"
                        name="businessName"
                        value={this.state.userData.businessName}
                        onChange={this.handleBusinessNameChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0 d-flex align-self-center">
                      <Label for="title">Telefoon</Label>
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Input
                        type="text"
                        name="phoneNumber"
                        value={this.state.userData.phoneNumber}
                        onChange={this.handlePhoneNumberChange}
                      />
                    </Col>

                  </Row>


                  {!this.state.localLoginIoRegister &&
                    <>
                      <Row>
                        <Col sm="4" md="4" lg="4"></Col>
                        <Col sm="8" md="8" lg="8" className="d-flex">
                          <Col sm="1" md="1" lg="1" className="p-0">
                          <Input
                            type="checkbox"
                            className="p-0 registration-checkbox"
                            name="sendMeNews"
                            label={'sendMeNews'}
                            checked={this.state.userData.sendMeNews}
                            onChange={this.handleSendMeNewsChange.bind(this)}
                          />
                          </Col>
                          <Col sm="11" md="11" lg="11" className="p-0">
                            <Label for="title" className="registration-text">Ik ontvang graag updates en nieuws over innovaties in de maakindustrie en Flanders Make.</Label>
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                      <Col sm="4" md="4" lg="4"></Col>
                        <Col sm="8" md="8" lg="8" className="d-flex">
                        <Col sm="1" md="1" lg="1" className="p-0">
                        <Input
                          type="checkbox"
                          className="p-0 registration-checkbox"
                          name="inviteMe"
                          label={'inviteMe'}
                          checked={this.state.userData.inviteMe}
                          onChange={this.handleInviteMeChange.bind(this)}
                        />
                        </Col>

                        <Col sm="10" md="10" lg="10" className="p-0">
                          <Label for="title" className="registration-text">Ik ontvang graag uitnodigingen voor events van Flanders Make.</Label>
                        </Col>
                        </Col>
                      </Row>
                    </>
                  }


                  <Row className="mb-05">
                    <Col sm="4" md="4" lg="4" className="p-0">
                      Privacy
                    </Col>
                    <Col sm="8" md="8" lg="8">
                      <Label className="registration-text">
                        Flanders Make doet er alles aan om je gegevens te beschermen en je privacy te respecteren.
                        Door op 'verzenden' of 'pas aan' te klikken, ga je ermee akkoord dat we de gegevens die je hierboven
                        invulde opslaan en verwerken om je de gevraagde informatie te bezorgen.
                      </Label>
                      <Label className="registration-text">
                        Voor meer informatie, kan je onze
                        &nbsp;
                        <a className="privacy-link" target="_blank" rel="noopener noreferrer"
                        href="https://www.flandersmake.be/nl/privacy-policy">
                          privacyverklaring
                        </a>
                        &nbsp;
                        raadplegen.
                       </Label>
                    </Col>

                  </Row>
                  {/* This part seems unnecessary */}
                  {/* <Row>
                    <Col sm="12" md="12" lg="12">
                      <Label for="title">Hoe kunnen wij jou helpen?</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <Input
                        type="textarea"
                        name="howCanWeHelp"
                        value={this.state.userData.howCanWeHelp}
                        onChange={this.handleHowCanWeHelpChange}
                      />
                    </Col>
                  </Row> */}
                  </FormGroup>
                  }

              </Form>
            </ModalBody>

            <ModalFooter>

              {!this.state.localLoginIoRegister && this.props.bufferedUserLoggedIn &&

                <Tooltip
                  title={<h6 style={{ color: "white" }}>
                  Uw profiel verwijderen betekent dat al uw profiel-gegevens,
                  alsook al uw gedefinieerde ruimte-configuraties irreversibel gewist worden.
                  Bent u zeker?
                  </h6>}
                    placement="bottom"
                      >

                  <StyledButton
                    className="mr-auto"
                    backgroundColor="#BA4084"
                    color="white"
                    onClick={
                      () => {
                          const ok = this.props.bufferedUserLoggedIn
                          if (ok) {
                              var values = this.stateToGetUrlSuffix();
                              const messageType = values[1]
                              var urlSuffix = `${messageType}/`
                              urlSuffix += `?unsubscribe=${this.props.bufferedUserEmail}`
                              urlSuffix += `&code=${this.props.bufferedUserCode}`
                              // just have to send:
                              // http://localhost:3000/reg/?unsubscribe=sels.peter@gmail.com&code=SsACpVlRIlBGLebx.
                              onUnsubscribe(urlSuffix, messageType)
                          }
                      }
                    }
                  >
                      Verwijder profiel
                  </StyledButton>

                </Tooltip>
              }

               {/* Adding a link to register button (in the login mode dialogue)
                   for when a user is trying to logon but realises he has not registered yet. */}
               {/* { this.state.localLoginIoRegister && !this.props.bufferedUserLoggedIn &&

                  <Tooltip
                      title={<h6 style={{ color: "white" }}>
                      Registreer vooraleer u zich kan aanmelden.
                      </h6>}
                        placement="bottom"
                      >
                  <GreyButton
                  onClick={() => { this.setState( { localLoginIoRegister: false }   )
                                 }
                           }
                  >
                      <EditIcon />
                      registreer
                  </GreyButton>

                  </Tooltip>
              } */}

               {/* {this.state.localLoginIoRegister && !this.props.bufferedUserLoggedIn &&
                  <Tooltip
                      title={<h6 style={{ color: "white" }}>
                      Klik hier indien u reeds geregistreerd bent maar uw code vergeten bent
                      of die niet kan terugvinden in uw email.
                      </h6>}
                        placement="bottom"
                      >

                      <GreyButton
                      onClick={
                        () => {
                         // TODO: Check: It's true that anyone not logged in, but knowing your email adres,
                         // Can reset your code! That's true for other sites as well.
                         // Could be protected from bots by a CAPTCHA.
                         // Cannot be protected from humans.
                         // But not a big issue since only the email owner gets emailed the new code.
                         const ok = this.validateEmailOnlyFromForm();
                         if (ok) {
                             var values = this.stateToGetUrlSuffix(false);
                             var urlSuffix = values[0]
                             const messageType = values[1]
                             urlSuffix = urlSuffix.replace('emailAddress=', 'resetCode=')
                             urlSuffix = urlSuffix.replace('login/', 'reg/')
                             onResetCode(urlSuffix, messageType)
                         }
                       }
                     }
                      >
                          <MailOutlineIcon />
                          email nieuwe code
                      </GreyButton>
                  </Tooltip>
              } */}
              <CancelButton
                onClick={() => toggle()}
              >
                Annuleer
              </CancelButton>
              <StyledButton
                backgroundColor="#0096A9"
                color="white"
                onClick={() => {
                  const ok = this.validateForm();
                  if (ok) {
                      //this.bufferUserValuesFromForm() // done in stateToGetUrlSuffix instead,
                      var values = this.stateToGetUrlSuffix();
                      const urlSuffix = values[0]
                      const messageType = values[1]
                      onSave(
                          urlSuffix, messageType,
                          this.bufferedUserEmail, // stateToGetUrlSuffix has set these 3, 1
                          this.bufferedUserPassword, // 2
                          this.bufferedUserCode, // 3
                          this.bufferedUserData
                      )
                      window.scrollTo(500, 0);
                  }
                }}
              >
                  {this.state.localLoginIoRegister ?
                      (this.props.bufferedUserLoggedIn ? 'Meld af' : 'Meld aan') :
                      (this.props.bufferedUserLoggedIn ? 'Pas aan' : 'Registreer')}

              </StyledButton>
            </ModalFooter>

          </Modal>

        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}>
                {'UserReg: ' + (this.state.errorString.startsWith('FOUT:') ? 'Waarschuwing' : 'Mededeling')}
              </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString.replace('OK:', '').replace('FOUT:', '')}
                    </Row>
                    </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    <SaveIcon /> {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

        </>
        )
    }
}
