import { generateUid } from "./utils/utils"

export const emptyRoom = {
    roomName:'[lege-ruimte]',
    roomSize:{x:14,y:12},
    viewingDirection: -1, // ~ None or so: no viewingDirection required
    viewingTolerance: 0,

    tableTypes:[
    ],
    standardTable:{name:'Laatste', width:1, height:1, n_sides:6,  number:1, instances:[],
    'seatingSide0': 1, 'seatingSide1': 1, 'seatingSide2': 1, 'seatingSide3': 1,
    },

    obstacleTypes:[
    ],
    reservationTypes:[],
    standardReservation: {  name: 'groep van 4', groupSize : 4, number: 1 },
    patience1_s: 3,
    patience2_s: 3,

    standardTableType: 'roundTable',
    //standardPolygonNSides: 8,
}

export const restaurant = {
    roomName:'[eetruimte]',
    roomSize:{x:14,y:12},
    viewingDirection: -1, // ~ None or so: no viewingDirection required
    viewingTolerance: 0,

    tableTypes:[
        {uid: generateUid(), name:'vierkant2z2p',width:0.6, height:0.6, n_sides:4, number:15, instances:[],
        'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 1,
        },
        {uid: generateUid(), name:'lang2z5p', width:1.8, height:0.8, n_sides:4,  number:3, instances:[],
        'seatingSide0': 0, 'seatingSide1': 2, 'seatingSide2': 0, 'seatingSide3': 3,
        },
        {uid: generateUid(), name:'rond6p', width:0.6, height:0.6, n_sides:6,  number:2, instances:[],
        'seatingSide0': 1, 'seatingSide1': 1, 'seatingSide2': 1, 'seatingSide3': 1,
        },

    ],
    standardTable:{
        name:'Laatste', width:1, height:1, n_sides:4,  number:1, instances:[],
        'seatingSide0': 1, 'seatingSide1': 0, 'seatingSide2': 1, 'seatingSide3': 0,
    },

    obstacleTypes:[
        {uid: generateUid(), name:'in-uit-gang', width:2.3,height:1,n_sides:4, number:1,instances :
        [{x:0,y:11},]},
        {uid: generateUid(), name:'bar1', width:1.5,height:4, n_sides:4, number:1,instances :
        [{x:0,y:4},]},
        {uid: generateUid(), name:'bar2', width:1.5,height:3.5, n_sides:4, number:1,instances :
        [{x:14-1.5,y:4},]},
        {uid: generateUid(), name:'pilaar', width:0.5,height:0.5, n_sides:4,
        number:2, instances :
        [{x:3.75,y:7.75},{x:10.75,y:7.75},]},
        {uid: generateUid(), name:'doorgang', width:11,height:1, n_sides:4, number:1,instances :
        [{x:1.5,y:5.5},]},
        {uid: generateUid(), name:'schuine-buitenmuur', width:5,height:0.5, n_sides:4, number:3,instances :
        [{x:4,y:11.5},{x:9,y:11.5},{x:9,y:11},]},
    ],
    reservationTypes:[
        {  name: 'groep van 2', groupSize : 2, number: 6 },
        {  name: 'groep van 5', groupSize : 5, number: 2 },
        {  name: 'groep van 6', groupSize : 6, number: 1 },
    ],
    standardReservation: {  name: 'groep van 4', groupSize : 4, number: 1 },

    patience1_s: 45,
    patience2_s: 15,

    standardTableType: 'rectangularTable',
}

export const classroom = {
    roomName: '[leslokaal]',
    roomSize:{x:14,y:12},
    viewingDirection: 270, // viewingDirection to the right
    viewingTolerance: 0,

    tableTypes:[
        {uid: generateUid(), name:'lessenaar', width:0.8, height:0.6, n_sides:4, number:12, instances:[],
        'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 0,
        },
    ],
    standardTable:
        {name:'lessenaar', width:0.8, height:0.6, n_sides:4, number:1, instances:[],
        'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 0,
        },
    obstacleTypes:[
        {uid: generateUid(), name:'in-uit-gang', width:2.3,height:1,n_sides:4, number:1,instances :
        [{x:0,y:11},]},
        {uid: generateUid(), name:'doorgang', width:1,height:9, n_sides:4, number:1,instances :
        [{x:0,y:2},]},
        {uid: generateUid(), name:'leerkracht', width:14,height:2, n_sides:4, number:1,instances :
        [{x:0,y:0},]},
    ],
    reservationTypes:[],
    standardReservation: {  name: 'groep van 4', groupSize : 4, number: 1 },
    patience1_s: 15,
    patience2_s: 7,

    standardTableType: 'rectangularTable',
}

export const theater1 = {
    roomName: '[openluchttheater]',
    roomSize:{x:22  ,y:25},
    viewingDirection: 0, // to the right
    viewingTolerance: 0, // strict

    tableTypes:[
        // 5 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax5p', width:0.6*6, height:0, n_sides:4,  number:20, instances:[],
        'seatingSide0': 0, 'seatingSide1': 6, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },

        // 3 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax3p', width:0.6*3, height:0, n_sides:4,  number:8, instances:[],
        'seatingSide0': 0, 'seatingSide1': 3, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },

        // 2 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax2p', width:0.6*2, height:0, n_sides:4,  number:6, instances:[],
        'seatingSide0': 0, 'seatingSide1': 2, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },
    ],
    standardTable:
        { name:'lessenaar', width:1.5, height:0, n_sides:4,  number:1, instances:[],
        'seatingSide0': 0, 'seatingSide1': 4, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },
    obstacleTypes:[
        {uid: generateUid(), name:'gangpadH', width:15, height:1, n_sides:4, number:1,instances :
        [{x:2,y:24},{x:2,y:18}          ,{x:2,y:6},{x:2,y:0},]},

        {uid: generateUid(), name:'gangpadHM', width:13, height:1, n_sides:4, number:1,instances :
        [{x:4,y:12},]},

        {uid: generateUid(), name:'gangpadVL', width:1.8,height:25, n_sides:4, number:1,instances :
        [{x:0,y:0},]},

        {uid: generateUid(), name:'gangpadVM', width:1.6,height:5, n_sides:4, number:1,instances :
        [{x:9,y:1},{x:10,y:7},{x:10,y:13},{x:9,y:19},]},

        {uid: generateUid(), name:'projectieruimte', width:1.8,height:5, n_sides:4, number:1,instances :
        [{x:2,y:9.5},]},

        {uid: generateUid(), name:'podium', width:5, height:25, n_sides:4, number:1,instances :
        [{x:17,y:0},]},
    ],
    reservationTypes:[
        {  name: 'groep van 6', groupSize : 6, number: 10 },
        {  name: 'groep van 5', groupSize : 5, number: 6 },  // demand 16 for together of 20 6-row-seats available
        {  name: 'groep van 3', groupSize : 3, number: 12 }, //
        {  name: 'groep van 2', groupSize : 2, number: 10 },
    ],
    standardReservation: { name: 'groep van 4', groupSize : 4, number: 1 },
    patience1_s: 55,
    patience2_s: 15,

    standardTableType: 'rowOfSeats',
}

export const theater2 = {
    roomName: '[theaterKleineGroepen]',
    roomSize:{x:22  ,y:25},
    viewingDirection: 0, // to the right
    viewingTolerance: 0, // strict

    tableTypes:[
        /*
        // 5 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax5p', width:0.6*6, height:0, n_sides:4,  number:10, instances:[],
        'seatingSide0': 0, 'seatingSide1': 6, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },
        */

        // 3 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax3p', width:0.6*3, height:0, n_sides:4,  number:50, instances:[],
        'seatingSide0': 0, 'seatingSide1': 3, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },

        // 2 people are supposedly bubbles and can sit closer together.
        { name:'bubbleMax2p', width:0.6*2, height:0, n_sides:4,  number:50, instances:[],
        'seatingSide0': 0, 'seatingSide1': 2, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },
    ],
    standardTable:
        { name:'lessenaar', width:1.5, height:0, n_sides:4,  number:1, instances:[],
        'seatingSide0': 0, 'seatingSide1': 4, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.6, 'seatingSide5': 0.6,
        },
    obstacleTypes:[
        {uid: generateUid(), name:'gangpadH', width:15, height:1, n_sides:4, number:1,instances :
        [{x:2,y:24},{x:2,y:18}          ,{x:2,y:6},{x:2,y:0},]},

        {uid: generateUid(), name:'gangpadHM', width:13, height:1, n_sides:4, number:1,instances :
        [{x:4,y:12},]},

        {uid: generateUid(), name:'gangpadVL', width:1.8,height:25, n_sides:4, number:1,instances :
        [{x:0,y:0},]},

        {uid: generateUid(), name:'gangpadVM', width:1.6,height:5, n_sides:4, number:1,instances :
        [{x:9,y:1},{x:10,y:7},{x:10,y:13},{x:9,y:19},]},

        {uid: generateUid(), name:'projectieruimte', width:1.8,height:5, n_sides:4, number:1,instances :
        [{x:2,y:9.5},]},

        {uid: generateUid(), name:'podium', width:5, height:25, n_sides:4, number:1,instances :
        [{x:17,y:0},]},
    ],
    reservationTypes:[
    /*
        //{  name: 'groep van 6', groupSize : 6, number: 0 },
        //{  name: 'groep van 5', groupSize : 5, number: 6 },  // demand 16 for together of 20 6-row-seats available
        {  name: 'groep van 3', groupSize : 3, number: 22 }, //
        {  name: 'groep van 2', groupSize : 2, number: 20 },
    */
    ],
    standardReservation: { name: 'groep van 4', groupSize : 4, number: 1 },
    patience1_s: 55,
    patience2_s: 15,

    standardTableType: 'rowOfSeats',
}


export const waitingRoom = {
    roomName: '[wachtzaal]',
    roomSize: {x:7, y:5.5},
    viewingDirection: -1, // ~ None or so: no viewingDirection required
    viewingTolerance: 0,

    tableTypes:[
        // 1 person chair
        {uid: generateUid(), name:'bubble_1p', width:0.6, height:0, n_sides:4, number:6, instances:[],
        'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },

        // 2 people benches are supposedly bubbles and can sit closer together.
        {uid: generateUid(), name:'bubble_2p', width:1.2, height:0, n_sides:4,  number:3, instances:[],
        'seatingSide0': 0, 'seatingSide1': 2, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },

        // 3 people besnches are supposedly bubbles and can sit closer together.
        {name:'bubble_3p', width:1.8, height:0, n_sides:4,  number:3, instances:[],
        'seatingSide0': 0, 'seatingSide1': 3, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },
    ],
    standardTable:
        // 1 person chair
        { name:'bubble_2p', width:3.2, height:0, n_sides:4, number:1, instances:[],
        'seatingSide0': 0, 'seatingSide1': 1, 'seatingSide2': 0, 'seatingSide3': 0,
        'seatingSide4': -0.3, 'seatingSide5': 0.6,
        },

    obstacleTypes:[
        {uid: generateUid(), name:'lectuur', width:3,height:1, n_sides:4, number:1,instances : [{x:1.8,y:1.7},]},
        {uid: generateUid(), name:'waterkraantje', width:0.6,height:0.6, n_sides:4, number:1,instances : [{x:6.3,y:1.1},]},
        {uid: generateUid(), name:'automaat', width:0.8,height:0.9, n_sides:4, number:1,instances : [{x:6,y:0.1},]},
        {uid: generateUid(), name:'doorgang', width:5.5,height:1, n_sides:4, number:1,instances : [{x:0,y:4.5},]},
    ],
    reservationTypes:[],
    patience1_s: 10,
    patience2_s: 10,

    standardTableType: 'rectangularTable',
}

// Factory reset rooms are adaptable by
// even non-logged in users for experimentation and they are saved at the client in RAM
// as long as the user keeps the TAB open (and does not refresh the browser tab.
// However, they cannot be saved in the server DB.
// When a user is logged in, these rooms can even not be changed in the DB.
// They are not in the server DB. But just in code right here.
// Non factory reset rooms can be saved by server registered that are also this.UserLoggedIn
// at the client side.
export const factoryResetRoomDict = {
            '[lege-ruimte]': emptyRoom,
            '[eetruimte]': restaurant,
            '[leslokaal]': classroom,
            '[openluchttheater]': theater1,
            //'[theaterKleineGroepen]': theater2, // result not good enough yet... -> many tables test
            '[wachtzaal]': waitingRoom,
}
