import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col
} from "reactstrap";

import styled from 'styled-components';

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;


export default class ReservationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        errorString: ''
    }
  }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            this.setState({ errorString: errorString} )
        } else {
            alert(errorString)
        }
    }

    mustShowWarning() {
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       return show
    }

  render() {
    const { toggle } = this.props;
    return (
      <FormGroup>

      <Modal isOpen={true} toggle={toggle}>

        <ModalHeader toggle={toggle}>Doel en werking van OptiSeats</ModalHeader>
        <ModalBody>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <p>
                    OptiSeats.be plaatst automatisch zo veel mogelijk tafels met stoelen of stoelenrijen in een ruimte
                    rond de obstakels (bvb: plaats voor bar of doorgang voor bediening),
                    rekening houdend met de corona-afstanden die tussen personen van
                    verschillende 'bubbles' moeten worden aangehouden.
                  </p>
                  <p>
                    Indien het een ruimte betreft, zoals een leslokaal of theater, waarin alle gezetenen in dezelfde
                    richting moeten kijken, dan kan u die kijkrichting van de ruimte aangeven.
                    De tool zorgt er dan voor dat tafels zo georiënteerd worden dat de aanzittenden
                    allen in die richting kijken. Tafels met aanzittenden aan meerdere tafelzijden
                    zullen dan sowieso niet geplaatst kunnen worden.
                  </p>
                  <p>
                      Indien u specifieke reservaties heeft kan u die per groep(bubble)-grootte ingeven.
                      De tool probeert dan ook de gekozen tafelgroottes daarop af te stemmen.
                      Zo er meer tafels kunnen geplaatst worden dan er voor
                      de reservaties nodig zijn, zal de tool dat ook proberen.
                  </p>
                  <p>
                      Als u meer rekentijd geeft aan de tool wordt het resultaat gewoonlijk beter.
                      U kan een pdf van het resultaat downloaden en delen met anderen. De pdf bevat een link die u naar
                      deze OptiSeats.be site terugbrengt en de ruimte in de pdf terug online visualiseert.
                  </p>
                  <p>
                      Indien u ruimtes met tafels en obstakels wil opslaan voor verder gebruik, vragen we te registreren.
                      Registratie en gebruik van deze tool zijn gratis.
                  </p>
                  <p>
                      Mail gerust uw vragen of opmerkingen naar OptiSeats@flandersmake.be.
                  </p>
                </Col>
              </Row>
        </ModalBody>


      </Modal>

        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}> mededeling </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString}
                    </Row>
                </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

      </FormGroup>
    )
  }
}
