import React from 'react';
import {Input, Row, Col, Label} from 'reactstrap';
import DeleteButton from './deleteButton/DeleteButton';
import Tooltip from "@material-ui/core/Tooltip";
import EditButton from "./editButton/Editbutton"
import styled from 'styled-components';

export default function ObstacleList(props) {
      const ForbiddenZoneButton = styled.p`
      color: #0096A9;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 700
    `;

	  const tableLines = props.tables.map( (obstacleType, index) =>
      <>
        <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
          <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                <Label for="name">{obstacleType.name}</Label>
          </Col>
          <Col sm="7" md="7" lg="7" className="d-flex">
              <Input className="input-field obstacle-field" name="number" min={0} max={20}
              type="number"
              value={obstacleType.number}
              onChange={e=>{
                  var targetValue = e.target.value
                  while (targetValue > obstacleType.instances.length) {
                      props.handleAdd(index)
                  }
                  while (targetValue < obstacleType.instances.length) {
                      props.handleRemove(index)
                  }
                  props.updateNumber(index, targetValue);
              }}
              />
              <EditButton
                hasTooltip={true}
                classes={"edit-button"}
                handleClick={() => props.handleEdit(index)}
              />
              <DeleteButton
                hasTooltip={true}
                handleDelete={()=>props.handleDelete(obstacleType)}
                classes={"delete-button"}
              />
          </Col>
        </Row>
      </>

	  )
	  return (
        <>

            <Tooltip
              title={<h6 style={{ color: "white" }}>
                  Definiëer hier uw obstakels. Dit zijn rechthoekige zones waar geen tafels mogen geplaatst worden.
                  Dit kunnen fysieke obstakels zijn zoals kasten of trappen, maar ook gereserveerde
                  ruimte zoals een in of uit-gang of een doorgang voor klanten of personeel.
                </h6>}
                  placement="bottom"
                >
    		  <h4>{props.name}</h4>
            </Tooltip>

		        {tableLines}

              <ForbiddenZoneButton
                onClick={props.handleCreate}>
                Voeg toe
              </ForbiddenZoneButton>

		</>
	  )
	}
