import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col
} from "reactstrap";

import styled from 'styled-components';

const GreyButton = styled.button`
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 20px;
  margin: 10px 0px;
  cursor: pointer;
`;


export default class ReservationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        errorString: ''
    }
  }

    updateErrorStringLeadingToAlert(errorString) {
        const reactStyleAlertIoJavascriptAlert = true
        if (reactStyleAlertIoJavascriptAlert) {
            this.setState({ errorString: errorString} )
        } else {
            alert(errorString)
        }
    }

    mustShowWarning() {
       const errorStringType = typeof this.state.errorString // to avoid undefined cases at startup
       const errorStringEmpty = this.state.errorString === ''
       const show = (errorStringType === 'string') && (!errorStringEmpty)
       return show
    }

  render() {
    const { toggle } = this.props;
    return (
      <FormGroup>

      <Modal isOpen={true} toggle={toggle}>

        <ModalHeader toggle={toggle}>  Corona-Maatregelen in Vlaanderen </ModalHeader>
        <ModalBody>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <p>
                    Behoudens een opstoot van de epidemie gaan de terrassen op 8 mei open.
                    Er mogen tot vier personen aan een tafel zitten. Behoort iedereen tot hetzelfde gezin,
                    zijn grotere tafels toegestaan. Tussen tafelgezelschappen moet er anderhalve meter afstand zijn.
                    Klanten moeten worden bediend aan tafel door personeel met een mondmasker.
                    Wie zich verplaatst moet een mondmasker dragen. De terrassen moeten om 22 uur sluiten.
                  </p>
                  <p>
                    Op 8 mei treedt ook de rest van het buitenplan in werking. In de buitenlucht kunnen evenementen,
                    culturele activiteiten en erediensten met maximaal 50 mensen doorgaan.
                    Er komen testevenementen voor activiteiten binnen. Als de coronacijfers het toelaten,
                    kunnen vanaf juni evenementen met 200 personen plaatsvinden.
                  </p>
                  <p>
                    Vanaf 8 mei kunnen jeugdactiviteiten met 25 personen in de buitenlucht doorgaan.
                    Bij -12-jarigen mogen ook tien kinderen binnen samenkomen. Vanaf 25 juni zijn zowel binnen als buiten
                    groepen van 50 toegelaten. Dat maakt mogelijk dat de zomerkampen doorgaan.
                  </p>
                </Col>
              </Row>
        </ModalBody>


      </Modal>

        {this.mustShowWarning() &&
            <Modal isOpen={true} toggle={toggle}>

              <ModalHeader toggle={toggle}> mededeling </ModalHeader>

              <ModalBody>
                <Form>
                    <Row>
                        {this.state.errorString}
                    </Row>
                </Form>
              </ModalBody>

              <ModalFooter>
                <GreyButton
                onClick={() => { this.setState( {errorString: '' } ) }  }
                >
                    {'ok'}
                </GreyButton>
              </ModalFooter>

            </Modal>
        }

      </FormGroup>
    )
  }
}
