import React from 'react';
import {Input, Row, Col, Label} from 'reactstrap';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteButton from './deleteButton/DeleteButton';
import EditButton from "./editButton/Editbutton"
import styled from 'styled-components';

export default function TableList({tables, handleEdit, handleDelete, updateNumber, handleCreate, name, newname}) {

    const TableButton = styled.p`
    color: #0096A9;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700
    `;

    const tableLines = tables.map( (tableType, index) =>
    <>
      <Row className="mb-05" style={{flex:2,flexDirection:"row",justifyContent:'space-between'}}>
          <Col sm="5" md="5" lg="5" className="d-flex align-items-center">
                <Label for="name">{tableType.name}</Label>
          </Col>
          <Col sm="7" md="7" lg="7" className="d-flex">
          <Input
                 className="input-field obstacle-field"
                 name="number" min={0} max={50}
                 type="number"
                 value={tableType.number}
                 onChange = {
                    e => {
                        const targetValue = e.target.value
                        updateNumber(index, targetValue);
                    }
                 }
             />
              <EditButton
                hasTooltip={true}
                classes={"edit-button"}
                handleClick={() => handleEdit(index)}
              />
              <DeleteButton
                hasTooltip={true}
                handleDelete={()=> handleDelete(tableType)}
                classes={"delete-button"}
              />
          </Col>
        </Row>
    </>
    )

    return (
        <>
          <Tooltip
            title={
              <h6 style={{ color: "white" }}>
                Definiëer hier uw tafels die geplaatst moeten worden.
                Er zijn drie soorten 'tafels': een rechthoekige,
                een ronde
                en een zitbank als rij van stoelen zonder tafel.
              </h6>
            }
            placement="bottom"
          >
            <h4>{name}</h4>
          </Tooltip>
          {tableLines}
          <TableButton
            onClick={handleCreate}>
            Voeg toe
          </TableButton>
        </>
    )
}
